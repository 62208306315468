import React from 'react';
import DependentDropdown from './DependentDropdown';
import Ex2 from './Ex2';
import GroceryFormInput from './GroceryFormInput';

const SelectExamples = () => {
    return (
        <div>
            ex 1.: <DependentDropdown />
            <br />
            <Ex2 />
            <br />
            <GroceryFormInput />
        </div>
    );
};

export default SelectExamples;