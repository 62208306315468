import React from 'react';
import GreetingsComponent from './components/Greetings';


function Greetings() {
    return (
        <div className='card-container'>
            <h3>Greetings</h3>
            <hr />
            <GreetingsComponent  name={'Jonas'}  signedIn={true}/>
        </div>
    );
}

export default Greetings;