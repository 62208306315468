import React from 'react';
import Footer from './Footer';
import './frame.scss';
import Header from './Header';
import Navbar from './Navbar';

const Frame = ({ children }) => {
    return (
        <>
            <div className='bg-circle1'></div>
            <div className='bg-circle2'></div>
            <Header />
            <Navbar />
            <main className='publicMain'>
                { children }
            </main>
            <Footer />
        </>
    );
};

export default Frame;