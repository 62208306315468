import React from 'react';
import { Button } from './Button';

class TalkerComponent extends React.Component {
    handletalk() {
      let speech = '';
      for (let i = 0; i < 1000; i++) {
        speech += 'blah ';
      }
      alert(speech);
    }

    render() {
      return <Button onClick={this.handletalk}/>;
    }
}

export default TalkerComponent;