import React from 'react';

function ListB() {
    // const partsList1 = ['a', 'b', 'c', 'd'];
    const partsList2 = {
        a: 'text a',
        b: 'text b',
        c: 'text c'
    };
    return (
        <ul>
            {/* {partsList1.map((part,id) => <li key={id}>{part}</li>)} */}
            {Object.keys(partsList2).map((text, id) => <li key={id}>{partsList2[text]}</li>)}
        </ul>
    );
}

export default ListB;
