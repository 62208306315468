import React, { useEffect, useState } from 'react';
import './engWords.scss';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';
import AddWord from './AddWord';
import SearchWord from './SearchWord';
import { FireDB } from '../../../../../../server/firebase-config';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

const EngWords = () => {
    const [changeForm, setChangeForm] = useState(false);
    const [words, setWords] = useState(0);

    const toggle = () => {
        setChangeForm(!changeForm);
    };

    const wordsCollectionRef = collection(FireDB, 'gruminEnglishWords');

    useEffect(() => {
        const fetchWordsData = query(wordsCollectionRef, orderBy('date', 'asc'));

        const showWordsArray = onSnapshot(fetchWordsData, (snapshot) => {
            setWords(snapshot.docs.map((doc) => ({ ... doc.data(), id: doc.id})));
        });
        return showWordsArray;
    }, []);
    
    return (
        <RowWithTitle title={`Learn ${words && words.length} Words`}>
           
                {
                    changeForm ? <button className='EngWords-click-btn' onClick={toggle}>Close</button> : <button className='EngWords-click-btn' onClick={toggle}>Add Word</button>
                }
                {
                    changeForm ? 
                    (
                        <AddWord />
                    ) : (
                        <SearchWord />
                    )
                } 
          
        </RowWithTitle>
    );
};

export default EngWords;