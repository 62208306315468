import React from 'react';
import './learnBabel.scss';
import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import Content from '../../../../components/Dashboard/Content';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import RowWithTitle from '../../../../components/Dashboard/Content/RowWithTitle';

const LearnBabel = () => {
    const { user, loading, logoutUser } = useUserContext();

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <Content path='babel'>
                                    <RowWithTitle  title='Learn Babel'>
                                        <div className='learnBabel-container'>
                                            <h3>ECMA 6 : BABEL</h3>
                                            <p>Koldunas Coding</p>
                                            <p>1:10 min https://www.youtube.com/watch?v=zzHoB5F0wrY</p>
                                            <p>BABEL , reikalingas kad senasis Explorer galėtu atvaizduoti svetaine su esma6 versija</p>
                                            <p>Koda rašome ECMA 6 versija ar naujausia versija, o BABEL jį pakeičia į ECMA 5.</p>
                                            <p>BABEL transpailina koda į ECMA 5. Jis padaro kad senesni browseriai viska suprastu.</p>
                                            <p>Pamate žodį BABEL žinome kam jis reikalingas. T.y. kad mano koda paverstu i seno browserio suprantama koda.</p>
                                            
                                            <ol>
                                                <li>npm init // įsirašome BABEL. sukuriamas package.json folderis.</li>
                                                <li>npm install babel-cli // įsirašome visus reikalus kuriu jam reikia. Sukuriamas folderis node_modules ir package-lock.json </li>
                                                <li>sukuriame Babel configuracini faila pradinėje direktorijoje .babelrc</li>
                                                <li>.babelrc ,parašome presetus kaip viska susideti , pvz:
                                                    <pre>
                                                        &#123;
                                                            <br />
                                                            &nbsp;&#34;presets&#34;: [
                                                            <br />
                                                            &nbsp;&nbsp;    &#34;es2015&#34;
                                                                <br />
                                                            &nbsp;]
                                                            <br />
                                                        &#125;
                                                    </pre>
                                                </li>
                                                <li>package.json faile nurodome kokius failus subildinti , prie &#34;scripts&#34; pasirašome koda kuris bildintu mano koda i ecma5 </li>
                                                <li>sukuriame folderi es5</li>
                                                <li>babelio funkcijos kad bildinti ecma6 i directorija ecma5
                                                    <pre>
                                                        &#34;scripts&#34;: &#123;
                                                            <br />
                                                            &nbsp;&nbsp;&#34;test&#34;: &#34;echo \&#34;Error: no test specified\&#34; &#38;&#38; exit 1&#34;,
                                                            <br />
                                                            &nbsp;&nbsp;&#34;build&#34;: &#34;babel es6 -d es5&#34;
                                                            <br />
                                                        &#125;,
                                                    </pre>
                                                </li>
                                                <li>įsirašome babelio trukstama preseta  npm install babel-preset-es2015(jei nurodysiu kad es2016 ,tai reikes įsirašyti babel-preset-es2016 ir tt)</li>
                                                <li>npm run build</li>
                                                <li>folderyje es5 atsirado failas ecma5.js</li>
                                                <li>galime žiūrėti per Exploreri.</li>
                                                
                                            </ol>

                                        </div>
                                        
                                    </RowWithTitle>
                                </Content>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default LearnBabel;