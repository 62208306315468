import React, { useState } from "react";
import { AlbumImagesData } from "./AlbumImagesData";
import './firebaseAlbums.scss';
// import { FireStorage } from "../../../server/firebase-config";
// import { ref } from '@firebase/storage';

function FirebaseAlbums () {
    const [selectedImg, setSelectedImg] = useState(AlbumImagesData[0]);
  

    return(
        <div>
            <h3>youtube: h3webdevtuts</h3>
            <h4>image gallery app react</h4>
            <div className='albums-App'>
                <div className='albums-app-container' >
                    <img src={selectedImg} alt='albums-selected' className='albums-selected' />
                    <div className='albums-imgContainer'>
                        {/* <img src='' alt='dog' className="albums-img"/> */}
                     {
                         AlbumImagesData.map((img, index) => (
                            <img 
                                src={img} 
                                alt='dog' 
                                key={index} 
                                style={{ border: selectedImg === img ? '3px solid blue' : '' }} 
                                onClick={() => setSelectedImg(img)}
                            />
                         ))
                     }
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default FirebaseAlbums;