import React from 'react';

function Example4() {
    const data = [
        {
            number: '1',
            title: 'Varikliai',
            content: [
                {
                    text: 'Dyzelinis',
                    email: ''
                },
                {
                    text:'Benzininis',
                    email: ''
                },
                {
                    text: 'Dujos',
                    email: ''
                }
            ]
        },
        {
            number: '2',
            title: 'Pavarų dėžės',
            content: [
               { 
                    text: 'Automatinė'
                },
                { 
                    text: 'Mechaninė'
                }
            ]
        },
        {
            number: '3',
            title: 'Durys',
            content: [
                { 
                    text: 'Kairė priekis'
                },
                { 
                    text: 'Dešinė priekis'
                }
            ]
        },
        {
            number: '4',
            title: 'Kapotas',
            content: [
                { 
                    text: 'Su Grotelėm'
                },
                { 
                    text: 'Be grotelių'
                }
            ]
        }
    ];
    return (
        <div>
            {data.map((item) => {
                return(
                    <div key={item.id}>
                        <p style={{ backgroundColor: 'red' }}>{item.title}</p>
                        {
                            item.content.map((title) => {
                                return(
                                    <div key={title.id}>
                                        <h5> # {title.text} <input type='checkbox' name={title.text} /></h5>
                                    </div>
                                );
                            })

                        }
                    </div>
                );
            })}
        </div>
    );
}

export default Example4;
