import React from 'react';
import Item from './Item';

function Cars(props) {
    const cars = props.cars;
    const carsList = cars.map((car) => <Item key={car.toString()} carName={car} />);

    return (
        <ul>
            {carsList}
        </ul>
    );
}

export default Cars;
