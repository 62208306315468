import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSLesson2() {
    const test = "vardas" + 2;
    console.log(test);
    console.log(typeof test);

    let changeMe = 'true';
    changeMe = 'false';
    
    return (
        <RowWithTitle title='JS Variables'>
            <div className='js-coursesContainer'>
            <div>
               
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Variable names cannot start with numbers.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Variable names are case sensitive, so myName and myname would be different variables. It is bad practice to create two variables that have the same name using different cases.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Variable names cannot be the same as keywords. </li>
                    <li><MdIcons.MdOutlineTaskAlt/> Variables hold reusable data in a program and associate it with a name.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Variables are stored in memory.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The var keyword is used in pre-ES6 versions of JS.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> let is the preferred way to declare a variable when it can be reassigned, and const is the preferred way to declare a variable with a constant value.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Variables that have not been initialized store the primitive data type undefined</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Mathematical assignment operators make it easy to calculate a new value and assign it to the same variable.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The + operator is used to concatenate strings including string values held in variables</li>
                    <li><MdIcons.MdOutlineTaskAlt/> In ES6, template literals use backticks ` and ${} to interpolate values into a string.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The typeof keyword returns the data type (as a string) of a value.</li>
                </ul>
                <pre  className='courses-code-container'>
                    1. const test = &#39;vardas&#39; + 2;
                    <br />
                    2. console.log(typeof test);
                </pre>
                <div className='courses-show-task'>
                    <ul>
                        <li><MdIcons.MdOutlineQuestionAnswer/> : 1. {test}</li>
                        <li><MdIcons.MdOutlineQuestionAnswer/> : 2. {typeof test}</li>
                    </ul>
                </div>
            </div>

            <div>
                <h3>Variable: let 1</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The let keyword signals that the variable can be reassigned a different value</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    let meal = &#39;Enchiladas&#39;;
                    <br />
                    console.log(meal); // Output: Enchiladas
                    <br />
                    meal = &#39;Burrito&#39;;
                    <br />
                    console.log(meal); // Output: Burrito
                </pre>
            </div>

            <div>
                <h3>Variable: let 2</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Another concept that we should be aware of when using let (and even var) is that we can declare a variable without assigning the variable a value. In such a case, the variable will be automatically initialized with a value of undefined</li>
                    <li><MdIcons.MdOutlineTaskAlt/> If we don&#39;t assign a value to a variable declared using the let keyword, it automatically has a value of undefined.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> We can reassign the value of the variable.</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    let price;
                    <br />
                    console.log(price); // Output: undefined
                    <br />
                    price = 350;
                    <br />
                    console.log(price); // Output: 350
                </pre>
                <hr />
                <pre  className='courses-code-container'>
                    let changeMe = true;
                    <br />
                    changeMe = false;
                    <br />
                    console.log(changeMe);
                </pre>
                <div className='courses-show-task'>
                    <ul>
                        <li><MdIcons.MdOutlineQuestionAnswer/> : {changeMe}</li>
                    </ul>
                </div>
            </div>

            <div>
                <h3>Variable: const</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The const keyword was also introduced in ES6, and is short for the word constant. Just like with var and let you can store any value in a const variable. The way you declare a const variable and assign a value to it follows the same structure as let and var. </li>
                    <li><MdIcons.MdOutlineTaskAlt/> However, a const variable cannot be reassigned because it is constant. If you try to reassign a const variable, you&#39;ll get a TypeError</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Constant variables must be assigned a value when declared. If you try to declare a const variable without a value, you’ll get a SyntaxError. </li>
                    <li><MdIcons.MdOutlineTaskAlt/> If you&#39;re trying to decide between which keyword to use, let or const, think about whether you’ll need to reassign the variable later on. If you do need to reassign the variable use let, otherwise, use const.</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    const myName = &#39;Gilberto&#39;;
                    <br />
                    console.log(myName); // Output: Gilberto
                </pre>
               
            </div>

            </div>
        </RowWithTitle>
        
    );
}

export default JSLesson2;