import React, { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

const initialCardState = {
    category: '',
    title: '',
    author: '',
    authorGithub: '',
    icon: '',
    url: '',
    myGithub: '',
    webExample: '',
    notes: '',
    createdAt: '',
    timestamp: ''
};

const PhpAddForm = ({ showAddForm, setShowAddForm }) => {
    const [ card, setCard ] = useState(initialCardState);
    const [ icon, setIcon ] = useState('');
    const [ error, setError] = useState(false);

    const cardsCollectionRef = collection(FireDB, 'codingTutorials');

    let today = new Date();

    const showDate = (value) => {
        let date;
        let month = value.getMonth() + 1;
        let day = value.getDate();
        let hours = value.getHours();
        let minutes = value.getMinutes();

        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }

        date = `${value.getFullYear()}/${month}/${day} ${hours}:${minutes}`;
        return date;
    };

    let newDate = showDate(today);
    
    const handleInput = (event) => {
        const name =  event.target.name;
        const value =  event.target.value;
        setCard({
            ...card, [ name ] : value
        });
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if ( card.title === '' || card.author === '' || icon === '' || card.myGithub === ''){
            setError('Empty input fields.');
            setTimeout(() => {
                setError(false);
            }, 3000);
        } else {
            const newCard = {
                category: card.category, 
                title: card.title, 
                author: card.author, 
                authorGithub: card.authorGithub, 
                icon: icon, 
                url: card.url, 
                myGithub: card.myGithub,
                webExample: card.webExample,
                notes: card.notes,
                createdAt: newDate,
                timestamp: serverTimestamp()
            };
            await addDoc(cardsCollectionRef, newCard);
            setShowAddForm(!showAddForm);
        }   
    };

    return (
        <form onSubmit={handleSubmit}>
            {error && <p>{error}</p>}
            <input type='text' className='text-input' name='category' value={card.category} placeholder='Category' onChange={handleInput} />
            <input type='text' className='text-input' name='title' value={card.title} placeholder='Title' onChange={handleInput} />
            <input type='text' className='text-input' name='author' value={card.author} placeholder='Author' onChange={handleInput} />
            <input type='text' className='text-input' name='url' value={card.url} placeholder='Url or empty' onChange={handleInput} />
            <input type='text' className='text-input' name='authorGithub' value={card.authorGithub} placeholder='Author github' onChange={handleInput} />
            <input type='text' className='text-input' name='myGithub' value={card.myGithub} placeholder='My github' onChange={handleInput} />
            <input type='text' className='text-input' name='webExample' value={card.webExample} placeholder='Web example' onChange={handleInput} />
            <div className='ratio-input'>
                <input type='radio' checked={icon === 'youtube'} name='icon' value='youtube' id='youtube' onChange={(e) => setIcon(e.target.value)}/>
                <label htmlFor='youtube'>Youtube icon</label>
            </div>
            <div className='ratio-input'>
                <input type='radio' checked={icon === 'web'} name='icon' value='web'  id='web'  onChange={(e) => setIcon(e.target.value)}/>
                <label htmlFor='web'>Web icon</label>
            </div>
            <textarea type='text' name='notes' value={card.notes} placeholder='Notes' onChange={handleInput} rows='4' cols='50'></textarea>
            <button type='submit'>Add tutorial</button>
        </form>
    );
};

export default PhpAddForm;