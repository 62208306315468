import React from 'react';

const Transactions = ({list}) => {
    console.log('testas');
    console.log(list);

        return (
           <div className='CurrencyConverter-list-container'>
               <h5>Trasactions</h5>
               <ul className='CurrencyConverter-list-group'>
                    {list.length ? list.map((item) => (
                        <li key={item.id} className='list-group-item'>
                            <span><strong>{item.name}</strong></span>
                            {': '}
                            <span>{item.converted}</span>
                        </li>
                    )): <div>No Purchases</div>}
               </ul>

           </div>

        );
};

export default Transactions;