import React, {Component} from 'react';
import axios from 'axios';

export default class SecondList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: []
        };
    }
    
    componentDidMount(){
        axios.get('https://jsonplaceholder.typicode.com/posts')
        .then(response =>{
            this.setState({
                posts: response.data
            });
            console.log(response.data);
        });
    }

    render() {
        const {posts} = this.state;
        return (
            <div>
                {posts.map((post, index) => <div key={index}>{post.title}</div>)}
            </div>
        );
    }
}

