import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import { FireDB } from '../../../../server/firebase-config';
import './adminGithub.scss';
import { collection, query, orderBy, onSnapshot, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { BsGithub } from "react-icons/bs";

const AdminGithub = () => {
    const { user, loading, logoutUser } = useUserContext();
    const [githubCards, setGithubCards] = useState([]);
    const [githubTitle, setGithubTitle] = useState('');
    const [githubDescription, setGithubDescription] = useState('');
    const [githubUrl, setGithubUrl] = useState('');

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    const cardsCollectionRef = collection(FireDB, 'gruminGithubCards');

    const showNewDate = new Date();
    const addedPaleteDate = showNewDate.getFullYear()+'/'+showNewDate.getMonth()+'/'+showNewDate.getDate()+'/'+showNewDate.getHours()+':'+showNewDate.getMinutes();


    useEffect(() => {
        const fetchMessagesCollection = query(cardsCollectionRef, orderBy('date', 'asc'));
        const onsub = onSnapshot(fetchMessagesCollection, (snapshot) => 
        setGithubCards(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        );
        return onsub;
    }, []);

    const handleAddGitRepo = async (event) => {
        event.preventDefault();
        const newRepo = { githubTitle, githubDescription, githubUrl, date: addedPaleteDate};
        await addDoc(cardsCollectionRef, newRepo);
        setGithubTitle('');
        setGithubDescription('');
        setGithubUrl('');
    };

    const handleDelete = async (id) =>{
        const cardDoc = doc(FireDB, "gruminGithubCards", id);
        await deleteDoc(cardDoc);


    };

    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <div className='adminGitportfolio-add'>
                                    <h2 className='adminGitportfolio-add-h2'>add new Github repository</h2>
                                    <form onSubmit={handleAddGitRepo} className='adminGitportfolio-add-form'>
                                        <input type='text' id='githubTitle' className='adminGitportfolio-add-input' value={githubTitle} placeholder='Title' onChange={(event) => setGithubTitle(event.target.value)} required/>
                                        <input type='text' id='githubDescription' className='adminGitportfolio-add-input' value={githubDescription} placeholder='Description' onChange={(event) => setGithubDescription(event.target.value)} required/>
                                        <input type='text' id='githubUrl'  className='adminGitportfolio-add-input' value={githubUrl} placeholder='Url' onChange={(event) => setGithubUrl(event.target.value)} required/>
                                        <button className='adminGitportfolio-add-button' type='submit'>Add new github repo</button>
                                    </form>
                                </div>
                                <hr />
                                <h2 className=''>My Github repositories</h2>
                                <hr />
                                <div className='adminGitportfolio-cards'>
                                    {githubCards && githubCards.map((card) => (
                                        <div key={card.id}>
                                            <button type='button' onClick={() => handleDelete(card.id)} className='delete-btn' >Delete repo</button>
                                             <a
                                                href={card.githubUrl}
                                                target='blank'
                                                className='adminGitportfolio-single-card'
                                                
                                            >
                                                <BsGithub className='icon' />
                                                <h3 className='adminGitportfolio-single-card-h3'>
                                                    {card.githubTitle}
                                                    <span>{card.githubDescription}</span>
                                                    <span>{card.date}  </span> 
                                                </h3>
                                            </a>
                                            
                                        </div>  
                                    ))}


                                </div>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default AdminGithub;