import React, { useEffect, useState } from 'react';
import './githubPortfolio.scss';
import { BsGithub } from "react-icons/bs";
import { FireDB } from '../../../../server/firebase-config';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

const GithubPortfolio = () => {
    const [githubCards, setGithubCards] = useState([]);

    const cardsCollectionRef = collection(FireDB, 'gruminGithubCards');

    useEffect(() => {
        const fetchMessagesCollection = query(cardsCollectionRef, orderBy('date', 'asc'));
        const onsub = onSnapshot(fetchMessagesCollection, (snapshot) => 
        setGithubCards(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        );
        return onsub;
    }, []);


    return (
        <div className='githubPortfolio-links'>
            {
                githubCards.map((card) => (
                    <a
                        href={card.githubUrl}
                        target='blank'
                        className='githubPortfolio-links-card'
                        key={card.id}
                    >
                        <BsGithub className='icon' />
                        <h3 className='portfolio-links-h3'>
                            {card.githubTitle}<span>{card.githubDescription}</span>
                        </h3>
                    </a>
                ))
            }
        </div>
    );
};

export default GithubPortfolio;