import React from 'react';
import { useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';

const LTengWords = ({ltWord, engWord, lineColor}) => {
    const [writeWord, setWriteWord] = useState('');
    const [checkResult, setCheckResult] = useState('');
    const [error, setError] = useState(false);

    const handleCheckWord = (word) => {
        if(writeWord === '') {
            setError(true);
        } else if (word === writeWord){
            setCheckResult(<BsCheck2 className='icon'/>);
        } else {
            setCheckResult(<MdClear  className='icon'/>);
           
        }

    };
    return (
        <div style={{ backgroundColor: lineColor, padding: '10px' }}>
            <span>{ltWord}</span> - <input 
                            type='text'  value={writeWord} 
                            onChange={(event) => setWriteWord(event.target.value)}
                        /> 
                        <button 
                            onClick={() => handleCheckWord(engWord)}
                        >
                            check
                        </button> 
                        {
                            error ? 'empty fields' : ''
                        }
                        {checkResult}
        </div>
    );
};

export default LTengWords;