import React from 'react';
import './section.scss';
import SectionTitle from './SectionTitle';

function Section({ sectionId, title, children }) {
    return (
        <section id={sectionId} className='sectionContainer'>
            <SectionTitle  title={title} />
                {children}
        </section>
    );
}

export default Section;