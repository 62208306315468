import React, {useState} from 'react';
import {Data} from './Data';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';

const AccordionSection = styled.div`
    margin: 0;
    display: flex;
    max-width: 800px;
    justify-content:center;
    height:100%;
`;
const Container = styled.div`
    box-shadow:2px 10px 35px 1px rgba(153,153,153,0.3);
`;
const ContainerContent = styled.div`
    width:100%;
`;
const Wrap = styled.div`
    margin-top: 10px;
    background: rgb(12, 12, 48);
    color:#fff;
    display: flex;
    justify-content: space-between;
    align-items:center;
    max-width:800px;
    text-align:center;
    cursor:pointer;

    h1 {
        padding: 0.5rem;
        font-size:2rem;
    }

    span {
        margin-right: 1.5rem;
    }
      
`;
const Dropdown = styled.div`
    background: #1c1c1c;
    color:#00ffb9;
    width:100%;
    height: 100px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    border-bottom: 1px solid #00ffb9;
    border-top:1px solid #00ffb9;

    p{
        font-size: 2rem;
    }
`;

const Accordion = () => {
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if(clicked === index) {
            //if clicked question is already active, then close it
            return setClicked(null);
        }
        setClicked(index);
    };
    return (
        <IconContext.Provider value={{ color: '#00ffb9', size: '25px' }} >
            <AccordionSection>
                <Container>
                    {Data.map((item, index) => {
                        return (
                            <ContainerContent key={index}>
                                <Wrap onClick={() => toggle(index)}>
                                    <h1>{item.question}</h1>
                                    <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                                </Wrap>
                                {clicked === index ? (
                                    <Dropdown>
                                        <p>{item.answer}</p>
                                        <p>{item.answer2}</p>
                                    </Dropdown>

                                ) : null }
                                
                            </ContainerContent>
                        );
                    })}
                </Container>
            </AccordionSection>
        </IconContext.Provider>
    );
};

export default Accordion;
