import React, { useEffect, useState } from 'react';

function getRandomQuote(quotes) {
    return quotes[Math.floor(Math.random() * quotes.length)];
}

const QuotesGenerator = () => {

    const [quotes, setQuotes] = useState([]);
    const [quote, setQuote] = useState(null);

    useEffect(() => {
        fetch("https://type.fit/api/quotes")
          .then((res) => res.json())
          .then((json) => {
            setQuotes(json);
            setQuote(json[0]);
          });
    }, []);

    function getNewQuote() {
        setQuote(getRandomQuote(quotes));
    }

    return (
        <div style={QuotesGeneratorstyle}>
             <h1>Quote Generator</h1>
            <section style={sectionStyle}>
                <button onClick={getNewQuote}>New Quote</button>
                <h3>
                <span>“</span>
                {quote?.text}
                </h3>
                <i>- {quote?.author}</i>
            </section>
        </div>
    );
};

export default QuotesGenerator;

const QuotesGeneratorstyle = {
    maxWidth : '500px',
    margin: '0 auto'
};
const sectionStyle = {
    marginTop: '100px',
    display: 'grid',
    placeItems: 'center',
    border: '2px solid gray',
    padding: '20px 10px',
    backgroundColor: 'green',
};