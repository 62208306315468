import React from 'react';
import { ImagesUrl } from '../../../../../../data/imagesUrl';
import ImageDoor from '../../../../../../images/img5-x.jpg';
import './imagesList.scss';
import Image from './Image';
import { Hero } from './Hero';

const ImagesList = () => {
    return (
        <div>
            <Hero brand={'Monkey'}/>
            <Image src={ImagesUrl.dev.url} alt={ImagesUrl.dev.title}/>
            <hr />
            <img src={ImagesUrl.monkey.url} className={ImagesUrl.monkey.style} alt={ImagesUrl.monkey.title}/>
            <hr />
            <img src={ImagesUrl.ngoo.url} className={ImagesUrl.ngoo.style} alt={ImagesUrl.ngoo.title}/>
            <hr />
            <img src={ImageDoor} alt='' />
        </div>
    );
};

export default ImagesList;