import React, { useState } from 'react';
import './videoPlayer.scss';

const videos = {
    deer: "https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4",
    snail: "https://s3.amazonaws.com/codecademy-content/courses/React/react_video-slow.mp4",
    cat: "https://s3.amazonaws.com/codecademy-content/courses/React/react_video-cute.mp4",
    spider: "https://s3.amazonaws.com/codecademy-content/courses/React/react_video-eek.mp4"
};

const videoNames = Object.keys(videos);

const VideoPlayer = () => {
    const [videoSrc, setVideoSrc] = useState(videos.spider);

    function onSelectVideo(video) {
        const videoSrc = videos[video];
        setVideoSrc(videoSrc);
    }

    return (
        <div className='VideoPlayer-container'>
            <h1>Project 6: Video Player</h1>
            <Menu onSelectVideo={onSelectVideo} videoValues={videoNames} />
            <Video videoSrc={videoSrc} />
        </div>
    );
};

export default VideoPlayer;

function Menu({ onSelectVideo, videoValues }) {
    return (
      <form onClick={(event) => onSelectVideo(event.target.value)}>
        {videoValues.map((value, i) => (
          <div className='video-inputs' key={i}>
            <input key={i} type='radio' name='src' value={value} />
            {value}
          </div>
        ))}
      </form>
    );
}

function Video({ videoSrc }) {
    return (
      <div className='video-box'>
        <video loop controls autostart='true' autoPlay muted src={videoSrc} />
      </div>
    );
}