import React from 'react';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';
import './scrimba.scss';

const ScrimbaSplit = () => {

    //example D
    const secondMsg = 'The KITTENS are SLEEPING!';
    function whisper(str){
        if(str.endsWith('!')){
            return 'shh... ' + str.slice(0, -1).toLowerCase(); 
            //return 'shh... ' + str.slice(0, str.length - 1).toLowerCase(); 
        }
        return 'shh... ' + str.toLowerCase();
    }

    // example C
    const newMsg = 'Practice Coding Practice Coding Practice Coding';
    function nextText(str){
        return str.split(' ').join(' * '); //with space, and sign in betwwen
    }
    //console.log(nextText(newMsg));


    // example B
    const nextMsg = 'Good morning.';
    function nextPanic(str){
        return str.split(' '); //with space
    }
    //console.log(nextPanic(nextMsg));

    // example A
    const msg = 'Hello world';
    function panic(str){
        return str.split('');
    }
    //console.log(panic(msg));


    //example E
    let visitText = 'When you visit Portland you have to go to VooDoo Donuts';
    function altCaps(str) {
        // assemble each character back into a new string
        let newStr = '';
        //loop through the string
        for(let i= 0; i < str.length; i++){
            //uppercase every character with an even index
            if(i % 2 === 0){
                newStr += str[i].toUpperCase();
            } else {
                newStr += str[i];
            }
        }
        return newStr;
    }
    //console.log(altCaps(visitText));

  return (
    <div>
        <RowWithTitle title='JS  looping through sentence '>
        <div className='scrimba-container'>
          <pre>
            const visitText = &#39;When you visit Portland you have to go to VooDoo Donuts&#39;;
            <br />
            function altCaps&#40;str&#41;&#123;
            <br />
            &#47;&#47; assemble each character back into a new string
            <br />
            &nbsp;let newStr = &#39;&#39;;
            <br />
            &nbsp;for&#40;let i= 0; i &#60; str.length&#59; i++&#41;&#123;
            <br />
            &nbsp;&#47;&#47; uppercase every character with an even index
            <br />
            &nbsp;&nbsp;if&#40;i &#37; 2 === 0&#41;&#123;
            <br />
            &nbsp; &nbsp;newStr += str&#91;i&#93;.toUpperCase&#40;&#41;;
            <br />
            &nbsp;&nbsp;&#125; else &#123;
            <br />
            &nbsp;&nbsp;&nbsp;newStr += str&#91;i&#93;;
            <br />
            &nbsp;&nbsp;&#125;
            <br />
            &nbsp;&#125;
            <br />
            &nbsp;return newStr;
            <br />
            &#125; 
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; altCaps&#40; visitText &#41; &#125; 
              <br />
              &#41;
          </h4>
          <p>Browser: {altCaps(visitText)}</p>
        </div>
        </RowWithTitle>
        <RowWithTitle title='JS  .slice() .toLowerCase() '>
        <div className='scrimba-container'>
          <pre>
            const secondMsg = &#39;The KITTENS are SLEEPING!&#39;;
            <br />
            function whisper&#40;str&#41;&#123;
            <br />
            &nbsp;if&#40;str.endsWith&#40;&#39;!&#39;&#41;&#41;&#123;
            <br />
            &nbsp;&nbsp;return &#39;shh... &#39; + str.slice&#40;0, -1&#41;.toLowerCase&#40;&#41;; 
            <br />
            &nbsp;&#125;
            <br />
            &nbsp;return &#39;shh... &#39; + str.toLowerCase&#40;&#41;;
            <br />
            &#125;
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; whisper&#40; secondMsg &#41; &#125; 
              <br />
              &#41;
          </h4>
          <p>Browser: {whisper(secondMsg)}</p>
        </div>
      </RowWithTitle>
        <RowWithTitle title='JS  .split() .join() '>
        <div className='scrimba-container'>
          <pre>
            const newMsg = &#39;Practice Coding Practice Coding Practice Coding&#39;;
            <br />
            function nextText&#40;str&#41;&#123;
            <br />
            &nbsp;return str.split&#40;&#39; &#39;&#41;.join&#40;&#39; * &#39;&#41;; //with space, and sign in betwwen
            <br />
            &#125;

            <br/>
            console.log&#40;nextText&#40;newMsg&#41;&#41;;
            <br />
            Console: Practice * Coding * Practice * Coding * Practice * Coding
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; nextText&#40; newMsg &#41; &#125; 
              <br />
              &#41;
          </h4>
          <p>Browser: {nextText(newMsg)}</p>
        </div>
      </RowWithTitle>
      <RowWithTitle title='JS  .split() every word'>
        <div className='scrimba-container'>
          <pre>
            const nextMsg = &#39;Good morning.&#39;;
            <br />
            function nextPanic&#40;str&#41;&#123;
            <br />
            &nbsp;return str.split&#40;&#39; &#39;&#41;; //with space
            <br />
            &#125;

            <br/>
            console.log&#40;nextPanic&#40;nextMsg&#41;&#41;;
            <br />
            Console: Array&#40;2&#41;[&#39;Good&#39;, &#39;morning.&#39;]
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; nextPanic&#40; nextMsg &#41; &#125; 
              <br />
              &#41;
          </h4>
          <p>Browser: {nextPanic(nextMsg)}</p>
        </div>
      </RowWithTitle>
      <RowWithTitle title='JS .split() every letter'>
        <div className='scrimba-container'>
          <pre>
            const msg = &#39;Hello world&#39;;
            <br />
            function panic&#40;str&#41;&#123;
            <br />
            &nbsp;return str.split&#40;&#39;&#39;&#41;;
            <br />
            &#125;

            <br/>
            console.log&#40;panic&#40;msg&#41;&#41;;
            <br />
            Console: Array&#40;11&#41;[&#39;H&#39;, &#39;e&#39;, &#39;l&#39;, &#39;l&#39;, &#39;o&#39;, &#39; &#39;, &#39;w&#39;, &#39;o&#39;, &#39;r&#39;, &#39;l&#39;, &#39;d&#39;]
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; panic&#40; msg &#41; &#125; 
              <br />
              &#41;
          </h4>
          <p>Browser: {panic(msg)}</p>
        </div>
      </RowWithTitle>

    </div>
  );
};

export default ScrimbaSplit;