import React from 'react';
import './learnLaravel.scss';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import Content from '../../../../components/Dashboard/Content';
import RowWithTitle from '../../../../components/Dashboard/Content/RowWithTitle';
import LaravelStart from './LaravelStart';
import LaravelLinks from './LaravelLinks';
import LaravelVS from './LaravelVS';

const LearnLaravel = () => {
    const { user, loading, logoutUser } = useUserContext();

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }
    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <Content path='laravel'>
                                    <RowWithTitle  title='Learn Laravel'>
                                       <LaravelStart />
                                    </RowWithTitle>
                                    <RowWithTitle  title='Visual Studio + Laravel'>
                                       <LaravelVS />
                                    </RowWithTitle>
                                    <RowWithTitle  title='Laravel links'>
                                       <LaravelLinks />
                                    </RowWithTitle>
                                </Content>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default LearnLaravel;