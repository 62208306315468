import React from 'react';
import './row.scss';

const RowWithTitle = ({ title, children }) => {
    return (
        <div className='content-row-container'>
            <h3 className='content-row-h3'><span>{title}</span></h3>
            {children}
        </div>
    );
};

export default RowWithTitle;