import React from 'react';
import './edukus.scss';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect } from 'react';
import { useState } from 'react';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';
import { FireDB } from '../../../../../../server/firebase-config';
import EdukusAccordion from './EdukusAccordion';
import EdukusAddForm from './EdukusAddForm';

const Edukus = () => {
    const [addForm, setAddForm] = useState(true);
    const [tutorials, setTutorials] = useState(0);

    const edukusCollectionRef = collection(FireDB, 'gruminEdukusEnglish');

    useEffect(() => {
        const fetchTutorialsData = query(edukusCollectionRef, orderBy('date', 'desc'));
        const showTutorialsArray = onSnapshot(fetchTutorialsData, (snapshot) => {
            setTutorials(snapshot.docs.map((doc) => ({ ... doc.data(), id: doc.id})));
        });
        return showTutorialsArray;
    }, []);
       
    const toggle = () => {
        setAddForm(!addForm);
    };

    return (
        <RowWithTitle title='Edukus - English for beginners'>
            <span  style={{ color: 'red', fontSize: '55px' }}>Mantis kietas</span> 
            <div className='edukus-container'>
                <button 
                type='button' 
                onClick={toggle}
                className='edukus-button'
                >
                    { addForm ? 'add new tutorial' : 'back to turorials'}
                </button>

                { 
                    addForm ? 
                    
                        (tutorials && tutorials.map((tutorial, id) => (
                            <div key={id} className='edukus-container-accordion'>
                                <EdukusAccordion 
                                    tutorialTitle={tutorial.tutorialTitle}
                                    videoLink={tutorial.videoLink}
                                    FireDB={FireDB}
                                    id={tutorial.id}
                                />
                            </div>
                        )))
                        : <EdukusAddForm />
                }  

            </div>
        </RowWithTitle>
    );
};

export default Edukus;