export const SliderData = [
    {
        images: 'https://firebasestorage.googleapis.com/v0/b/myprojects-e3994.appspot.com/o/carsImages%2F1590072146-903_big.jpg?alt=media&token=18768d26-7f0c-41f7-9971-a49e73ce80df'
    },
    {
        images: 'https://firebasestorage.googleapis.com/v0/b/myprojects-e3994.appspot.com/o/carsImages%2F1591090197-504_big.jpg?alt=media&token=fee8a219-4d2e-43f6-911a-16bc49e61eb2'
    },
    {
        images: 'https://firebasestorage.googleapis.com/v0/b/myprojects-e3994.appspot.com/o/carsImages%2FgalinisZibintas.jpg?alt=media&token=17fd174b-d349-4fc4-9396-33dc6a481d02'
    }
];
