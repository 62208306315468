import React from 'react';
import TalkerComponent from './TalkerComp';

function ReactTalker() {

    const displayStyle = {
        padding: '5px',
        backgroundColor: 'green',
        borderRadius: '5px',
    };

    const handleClick = (name) => {
        let text = '';
        for(let i = 0; i < 5; i++){
            text += 'labas ' + name + ' ! ';
        }
        alert(text);
    };

    return (
       <div>
            <TalkerComponent />
            <button type='button' onClick={() => handleClick('Jonas')} style={displayStyle}>new click</button>
       </div>      
    );
}

export default ReactTalker;