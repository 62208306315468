import React, { useEffect, useState } from 'react';

function TestB() {
    const [car, setCar] = useState({
        brand: 'audi',
        model: 'q7',
        years: 2022
    });
    const updateCar = () => {
        setCar(previousCar => {
            return { ...previousCar, model : 'a8'};
        });
    };

    const resetCar = () => {
        setCar(previousCar => {
            return { ...previousCar, model: 'q7'};
        });
    };
    const [count, setCount] = useState(0);

    useEffect(() => {
      setTimeout(() => {
        setCount((count) => count + 1);
        console.log('test', count);
      }, 1000);
    }, []);

    return (
        <div>
            <h1>Test B</h1>
            <p>{car.brand} / {car.model} / {car.years}</p>
            <button onClick={updateCar}>change model</button>
            <button onClick={resetCar}>reset</button>
            <h1>I have rendered {count} times!</h1>
        </div>
    );
}

export default TestB;

