import React, { useEffect, useState } from 'react';
import './addWord.scss';
import { FireDB } from '../../../../../../../server/firebase-config';
import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';

const AddWord = () => {
    const [words, setWords] = useState([]);
    const [wordId, setWordId] = useState(0);
    const [wordsLength, setWordsLength] = useState(0);
    const [engWord, setEngWord] = useState('');
    const [ltWord, setLtWord] = useState('');
    const [urlAddress, setUrlAddress] = useState('');

    const wordsCollectionRef = collection(FireDB, 'gruminEnglishWords');

    useEffect(() => {
        // const fetchWordsData = query(wordsCollectionRef, orderBy('timestamp', 'asc'));
        const fetchWordsData = query(wordsCollectionRef, orderBy('date', 'asc'));

        const showWordsArray = onSnapshot(fetchWordsData, (snapshot) => {
            setWords(snapshot.docs.map((doc) => ({ ... doc.data(), id: doc.id})));
        });
        return showWordsArray;
    }, []);

    useEffect(() => {
        if (words.length > 0){
            setWordsLength(words.length);
            const lastWord = words.pop();
            setWordId(parseInt(lastWord.wordId) + 1);
        } else if(words.length === 0){
            const firstWord = 1;
            setWordId(firstWord);
        } else {
            setWordId(0);
        }
    },[words]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const newWord = { wordId, engWord, ltWord, urlAddress, status: 'dislike', date: serverTimestamp()};
        await addDoc(wordsCollectionRef, newWord);
        setEngWord('');
        setLtWord('');
        setUrlAddress('');
    };

    return (
        <form className='addWord-form-container' onSubmit={handleSubmit}>
            <h3>Total Words : {wordsLength}</h3>
            <input 
                type='text' 
                className='addWord-input' 
                name='wordId'
                value={wordId}
                onChange={(event) => setWordId(event.target.value)}
                hidden
            />
            <input 
                type='text' 
                className='addWord-input' 
                placeholder='ENG word' 
                name='engWord'
                value={engWord}
                onChange={(event) => setEngWord(event.target.value)}
            />
            <input 
                type='text' 
                className='addWord-input' 
                placeholder='LT word' 
                name='ltWord'
                value={ltWord}
                onChange={(event) => setLtWord(event.target.value)}
            />
            <input 
                type='text' 
                className='addWord-input' 
                placeholder='Url address' 
                name='urlAddress'
                value={urlAddress}
                onChange={(event) => setUrlAddress(event.target.value)}
            />
            <button type='submit' className='addWord-button'>Add word</button>
        </form>
    );
};

export default AddWord;