import React from 'react';

const LaravelVS = () => {
    return (
        <div className='laravel-vs-container'>
            <p>
                Terminal: <span className='search-command-text'>composer install</span>
            </p>
            <p>
                Terminal: <span className='search-command-text'>composer create-project laravel/laravel api</span> text
            </p>
            <p>
                <span className='keyboard-command-text'>ctrl</span>+ 
                <span className='keyboard-command-text'>shift</span>+ 
                <span className='keyboard-command-text'>p</span> open VS search panel. Write 
                <span className='search-command-text'>artisan</span> shows all artisan commands
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan route:cache</span> text
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan view:cache</span> text
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan cache:clear</span> text
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan make:model Product -m</span> model created
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan make:controller Api/ProductController --api</span> controller created
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan make:controller Api/AuthController</span>
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan make:request LoginRequest</span>
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan make:request SignupRequest</span>
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan migrate</span> created migration tables
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan migrate --seed</span> Run migrations
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan migrate:fresh --seed</span>
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan route:list</span> show routes list
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan serve</span> Start local server on port 8000
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan key:generate --ansi</span> Set the encryption key
            </p>
            <p>
                Terminal: <span className='search-command-text'>php artisan optimize</span>
            </p>
        </div>
    );
};

export default LaravelVS;