import React from 'react';

const Image = (props) => {
    const url = props.src;
    const style = props.className;
    const title = props.alt;
    return (
        <>
            <img src={url} className={style} alt={title} />
        </>
    );
};


export default Image;
