import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { FireDB } from '../../../../../../../server/firebase-config';


const EdukusAddForm = () => {
    const [tutorialTitle, setTutorialTitle] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [emptyFields, setEmptyFields] = useState('');

    const edukusCollectionRef = collection(FireDB, 'gruminEdukusEnglish');

    const handleAddTutorial = async (event) => {
        event.preventDefault();
        if(tutorialTitle === ''){
            setEmptyFields('Empty Fields');
        } else {
            const newTutorial = {tutorialTitle, videoLink, date: serverTimestamp()};
            await addDoc(edukusCollectionRef, newTutorial);
            setTutorialTitle('');
            setVideoLink('');
        }
        setInterval(() => {
            setEmptyFields('');
        }, 3000);
        
    };
    return (
        <>
            <h3>Edukus Add Tutorial</h3>
            <form onSubmit={handleAddTutorial}>
                <p>{emptyFields}</p>
                <input 
                    type='text' 
                    placeholder='Tutorial title' 
                    name='tutorialTitle'
                    value={tutorialTitle}
                    onChange={(event) => setTutorialTitle(event.target.value)} 
                />
                <input 
                    type='text' 
                    placeholder='Youtube video link' 
                    name='videoLink'
                    value={videoLink}
                    onChange={(event) => setVideoLink(event.target.value)} 
                />
                <button type='submit' className='edukus-button'>add tutorial</button>
            </form>
        </>
    );
};

export default EdukusAddForm;