import React from "react";
import ReactExamples from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples";
import ReactUseEffect from "../pages/authorized/AdminDashboard/LearnReact/ReactUseEffect";
import ReactUseState from "../pages/authorized/AdminDashboard/LearnReact/ReactUseState";

export const ReactNavigationData = [
    {
        id: 1,
        title: 'React Examples',
        description: 'examples',
        showComponent: <ReactExamples />
    },
    {
        id: 2,
        title: 'useState Hook',
        description: 'form, single input, multiple input, file input, array, object, initialState',
        showComponent: <ReactUseState />
    },
    {
        id: 3,
        title: 'useEffect Hook',
        description: 'form, single input, multiple input, file input, array, object, initialState',
        showComponent: <ReactUseEffect />
    },
    
];