import React, { useEffect, useState } from 'react';
import { FireDB } from '../../../../../../../server/firebase-config';
import { collection, onSnapshot, query } from 'firebase/firestore';

function GetData() {
    const devCollectionRef = collection(FireDB, 'friends');
    const [ data, setData] = useState([]);
    const [ loader, setLoader ] = useState(true);

   
    
    useEffect(() => {
        function fecthData () {
            const developerData = query(devCollectionRef);
            onSnapshot(developerData, (snapshot) => {
                const items = [];
                snapshot.forEach((doc) =>{
                    items.push(doc.data()); 
                });
                setData(items);
                setLoader(false);
            });
        }
        fecthData();
    }, []);
    
    return (
        <div>
             { loader === false && (data.map((dev, index) =>
              (
                 <p key={index}>{dev.animal}</p>
              )
            ))}
        </div>
    );
}

export default GetData;