import React from 'react';
import { useParams } from 'react-router-dom';
import { Students } from '../../../../../../../data/MOCK_DATA';
import StudentsButton from './components/Button';
import StudentsHeader from './components/Header';
// import TeamImage from './components/Image';
import './studentsList.scss';

const StudentDetails = () => {
    const { id } = useParams();

    const student = Students.find(student => (student.id).toString() === id);
    // const student = Students.find(student => student.id == id);
    // console.log(student.id)
    // console.log(Students[3].name)

    return (
        <div className='student-details'>
            <StudentsHeader color={ student.color }/>
            <StudentsButton redirectPath={'/public/students_list_page'} name={'Back to Students list page'} />
            <h3 className='student-h3'>{student.id}. {student.name} / {student.email}</h3>
            <p>Age: {student.age} , Color: {student.color} , City: {student.city}</p>
            {/* <TeamImage size={'30%'}/> */}
        </div>
    );
};

export default StudentDetails;
