import React from 'react';
import JSArrayMap from './JSArrayMap';

const JSMimoArrayMap = () => {
    return (
        <div>
            <div>
                <JSArrayMap />
            </div>
           
        </div>
    );
};

export default JSMimoArrayMap;