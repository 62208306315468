import React from 'react';
import { Navigate } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';
import { useUserContext } from '../../../context/UserAuthContext';
import { protectedUser } from '../../../server/adminData';

const AdminDashboard = () => {
    const { user, loading, logoutUser } = useUserContext();

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    } 


    // JSX is an object
    const page = (
        <div>
            <p>{user.email}</p>
        </div>
    );

    // console.log("page:" , page.props.children.props.children);
    
    return (
        <>
            {
                loading ?  
                    <p>Loading...</p> 
                        : 
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <div>AdminDashboard
                                   {page.props.children.props.children}
                                </div>
                            </Dashboard>
                       
            }
        </>
        
    );
};

export default AdminDashboard;