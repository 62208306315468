import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import { Navigate } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import './learnPhp.scss';
import Dashboard from '../../../../components/Dashboard';
import Content from '../../../../components/Dashboard/Content';
import RowWithTitle from '../../../../components/Dashboard/Content/RowWithTitle';
import PhpTutorialsList from './PhpTutorialsList';
import PhpAddForm from './PhpAddForm';

const LearnPhp = () => {
    const { user, loading, logoutUser } = useUserContext();

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    const [ list, setList] = useState([]);
    const [ showAddForm, setShowAddForm ] = useState(false);

    useEffect(() => {
        const tutorialsCollectionRef = collection(FireDB, 'codingTutorials');
        const fetchTutorialsData = query(tutorialsCollectionRef, orderBy('timestamp', 'desc'));
        const addCollection = onSnapshot(fetchTutorialsData, (snapshot) => {
            setList(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
        });

        return addCollection;
    },[]);

    // search section
    const [search, setSearch] = useState('');

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const filteredList =  list.filter(card =>
        card.author.toLowerCase().includes(search.toLowerCase()) 
    );

    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <Content path='php'>
                                    <RowWithTitle  title='Learn php'>
                                        <div className='learnPhp-container'>
                                            <form className='search-form'>
                                                <input type='text' placeholder='Search Author..' onChange={handleChange} />
                                            </form>
                                            <div className='add-tutorial-box'>
                                                <button 
                                                    type='button' 
                                                    onClick={() => setShowAddForm(!showAddForm)} 
                                                    className='actionButton'
                                                >
                                                   { showAddForm ? 'Close Form'  : 'Add New Tutorial'} 
                                                </button>
                                                { showAddForm && 
                                                    <PhpAddForm  
                                                        showAddForm={showAddForm}
                                                        setShowAddForm={setShowAddForm}
                                                        /> 
                                                }
                                            </div>
                                            <div className='youtube-list'>
                                                {
                                                    list.length > 0 ?
                                                        filteredList.map((card) => (
                                                            <PhpTutorialsList 
                                                                key={card.id} 
                                                                card={card}
                                                            />
                                                        )) : 'No data.'
                                                }
                                                {/* {
                                                    list.length > 0 ?
                                                    list.map((card) => (
                                                        <PhpTutorialsList 
                                                            key={card.id} 
                                                            card={card}
                                                        />
                                                    )) : 'No data.'
                                                } */}
                                            </div>
                                        </div>
                                    </RowWithTitle>
                                </Content>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default LearnPhp;

