import React, { useState } from 'react';
import './contact.scss';
import EmailImg from '../../../../images/img5-x.jpg';
import { FireDB } from '../../../../server/firebase-config';
import { collection, addDoc } from 'firebase/firestore';

const Contact = () => {
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [msgSubject, setMsgSubject] = useState('');
    const [message, setMessage] = useState('');

    const messagesCollectionRef = collection(FireDB, 'gruminContactForm');

    const showNewDate = new Date();
    const addedPaleteDate = showNewDate.getFullYear()+'/'+showNewDate.getMonth()+'/'+showNewDate.getDate()+'/'+showNewDate.getHours()+':'+showNewDate.getMinutes();

    
    const handleSendEmail = async (event) => {
        event.preventDefault();
        const newMessage = { name: userName, email: userEmail, subject: msgSubject, message, date: addedPaleteDate};
        await addDoc(messagesCollectionRef, newMessage);
        setUserName('');
        setUserEmail('');
        setMsgSubject('');
        setMessage('');
    };

    return (
        <ul className='contact-container'>
            <li className='contact-left-box'>
                <img src={EmailImg} alt='email' className='contact-image'/>
            </li>
            <li className='contact-right-box'>
                <form onSubmit={handleSendEmail} className='contact-form'>
                    <input type='text' id='name' className='contact-form-input' value={userName} placeholder='Name' onChange={(event) => setUserName(event.target.value)} required/>
                    <input type='email' id='email' className='contact-form-input' value={userEmail} placeholder='Email' onChange={(event) => setUserEmail(event.target.value)} required/>
                    <input type='text' id='subject'  className='contact-form-input' value={msgSubject} placeholder='Subject' onChange={(event) => setMsgSubject(event.target.value)} required/>
                    <textarea id='message'  className='contact-form-textarea' value={message} placeholder='Message' onChange={(event) => setMessage(event.target.value)} required></textarea>
                    <button className='contact-form-button' type='submit'>Send</button>
                </form>
            </li>
        </ul>
    );
};

export default Contact;