import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from '.';
import Home from '../pages/public/Home';
import Login from '../pages/public/Login';
import Register from '../pages/public/Register';
import UsersDashboard from '../pages/authorized/UsersDashboard';
import AdminDashboard from '../pages/authorized/AdminDashboard';
import SingleUser from '../pages/authorized/UsersDashboard/SingleUser';
import PageNotFound from '../pages/public/PageNotFound';
import { UserContextProvider } from '../context/UserAuthContext';
import ProtectedRoute from './ProtectedRoute';
import Messages from '../pages/authorized/AdminDashboard/Messages';
import AdminGithub from '../pages/authorized/AdminDashboard/Github';
import English from '../pages/authorized/AdminDashboard/English';
import UsefulLinks from '../pages/authorized/AdminDashboard/UsefulLinks';
import LearnReact from '../pages/authorized/AdminDashboard/LearnReact';
import LearnBabel from '../pages/authorized/AdminDashboard/LearnBabel';
import LearnJS from '../pages/authorized/AdminDashboard/LearnJS';
import ReactStudents from '../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList/StudentsList/ReactStudents';
import StudentDetails from '../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList/StudentsList/Details';
import LearnLaravel from '../pages/authorized/AdminDashboard/LearnLaravel';
import LearnPhp from '../pages/authorized/AdminDashboard/LearnPhp';
import PhpTutorialPage from '../pages/authorized/AdminDashboard/LearnPhp/PhpTutorialPage';


const Router = () => {
    return (
        <UserContextProvider>
            <Routes path={ROUTES.PUBLIC_DEFAULT}>
                <Route index element={<Home />} />
                <Route path={ROUTES.PUBLIC_LOGIN} element={<Login />} />
                <Route path={ROUTES.PUBLIC_REGISTER} element={<Register />} />

                <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD}>
                    <Route index element={
                        <ProtectedRoute>
                            <AdminDashboard />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_JAVASCRIPT} element={
                        <ProtectedRoute>
                           <LearnJS />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_REACT} element={
                        <ProtectedRoute>
                           <LearnReact />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_REACT_STUDENTS} element={
                        <ProtectedRoute>
                          <ReactStudents />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_REACT_STUDENT_ID} element={
                        <ProtectedRoute>
                          <StudentDetails />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_BABEL} element={
                        <ProtectedRoute>
                           <LearnBabel />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_PHP} element={
                        <ProtectedRoute>
                           <LearnPhp />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_PHP_TUTORIAL_ID} element={
                        <ProtectedRoute>
                           <PhpTutorialPage />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_LARAVEL} element={
                        <ProtectedRoute>
                           <LearnLaravel />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_MESSAGES} element={
                        <ProtectedRoute>
                           <Messages />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_GITHUB} element={
                        <ProtectedRoute>
                           <AdminGithub />
                        </ProtectedRoute>
                    }/>

                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_ENGLISH}>
                        <Route index element={
                            <ProtectedRoute>
                                <English />
                            </ProtectedRoute>
                        }/>
                    </Route>
                    <Route path={ROUTES.AUTHORIZED_ADMIN_DASHBOARD_USEFUL_LINKS} element={
                        <ProtectedRoute>
                           <UsefulLinks />
                        </ProtectedRoute>
                    }/>
                   
                </Route>

                <Route path={ROUTES.AUTHORIZED_USER_DASHBOARD}>
                    <Route index element={
                        <ProtectedRoute>
                             <UsersDashboard />
                        </ProtectedRoute>
                    }/>
                    <Route path={ROUTES.AUTHORIZED_USER_ID} element={
                        <ProtectedRoute>
                            <SingleUser />
                        </ProtectedRoute>
                    }/>
                </Route>

                <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
                
            </Routes>
        </UserContextProvider>
        
    );
};

export default Router;