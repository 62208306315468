import React from 'react';
import './carPartsList.scss';
import ListA from './ListA';
import ListB from './ListB';
import ListC from './ListC';


function CarPartsList() {
    return (
        <>
         <div>
            <ListC />
        </div>
        <div>
            <ListB />
        </div>
        <div>
            <ListA />
        </div>
        </>
    );
}

export default CarPartsList;

