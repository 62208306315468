import React from 'react';
import { Link } from 'react-router-dom';
import { Students } from '../../../../../../../data/MOCK_DATA';
import StudentsButton from './components/Button';

const ReactStudents = () => {
    return (
        <div>
            <p>ReactStudents</p>
            <div className='students-list-container'>
            <ul className='ul-container'>
                {
                    Students.map((student) => {
                        return (
                            <li key={student.id} className='students-info-card'>
                                <h3 className='students-name'>{student.id}. {student.name}</h3>
                                <h4 className='students-email'>{student.email}</h4>
                                <Link to={`/admin/react-student/${student.id}`}>test</Link>
                                <StudentsButton backgroundColor={student.color} redirectPath={`/admin/react-student/${student.id}`} name={'Details'} />
                            </li>
                        );
                    })
                } 
            </ul>
        </div>

        </div>
    );
};

export default ReactStudents;