import React, { useState } from 'react';
import TestB from './TestB';

function JustTestA() {
    const [user, setUser] = useState('Tomas');
    
    const students = ['Jonas', 'Petras'];
    // students[1] = 'Karina';
    students.push('Linas');
    students.push('Tadas');


    // console.log('students:', students);
    // students.push['linas'];
    // return students;

    const test = () => {
        const myTest = students[1];
        setUser('pakei2iau i ' + myTest);
    };

    return (
        <div>
           <button onClick={test}>click</button>
           {students}
           <h3>{user}</h3>

           <hr />

           <TestB />
        </div>
    );
}

export default JustTestA;