import React, { useEffect, useState } from 'react';
import './messages.scss';
import Dashboard from '../../../../components/Dashboard';
import { useUserContext } from '../../../../context/UserAuthContext';
import { FireDB } from '../../../../server/firebase-config';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { protectedUser } from '../../../../server/adminData';
import { Navigate } from 'react-router-dom';

const Messages = () => {
    const { user, loading, logoutUser } = useUserContext();
    const [messages, setMessages] = useState([]);

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    useEffect(() => {
       
        const messagesCollectionRef = collection(FireDB, 'gruminContactForm');
        const fetchMessagesCollection = query(messagesCollectionRef, orderBy('date', 'desc'));
        const onsub = onSnapshot(fetchMessagesCollection, (snapshot) => 
        setMessages(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        );
        return onsub;
    }, []);
    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <div className='dashboard-messages-container'>
                                    <h2 className='dashboard-message-h2'>Messages from Contact form</h2>
                                    {messages && messages.map((message) => (
                                        <div key={message.id} className='message-box'>
                                            <p className='messageContent'>Id: {message.id}</p>
                                            <p className='messageContent'>Date: {message.date}</p>
                                            <p className='messageContent'>Subject: {message.subject}</p>
                                            <p className='messageContent'>Name: {message.name}</p>
                                            <p  className='messageContent'>Email: {message.email}</p>
                                            <p>{message.message}</p>
                                        </div>
                                    ))}


                                </div>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default Messages;