import React, { useState } from 'react';
import './checkboxes.scss';
import Example from './examples/ButtonsList';
import CheckComponent from './examples/Example2';
import Accordion from './examples/Example3';
import MyCheckbox from './examples/Example4';

const Checkboxes2 = () => {
    const [dropDown, setDropDown] = useState('apple');
    const [isChecked, setIsChecked] = useState(false);
    const [radio, setRadio] = useState('Audi');
    return (
        <>
            <section className='card-container'>
            <h3>Checkboxes</h3>
            <hr />
            <h2>Dropdown value : {dropDown}</h2>
                <select value={dropDown} onChange={((event) => {setDropDown(event.target.value);})}>
                    <option value='apple'>apple</option>
                    <option value='orange'>orange</option>
                    <option value='banana'>banana</option>
                </select>
            </section>
            <section className='card-container'>
            <h2>CheckBox is: {isChecked ? 'true' : 'false'}</h2>
                car: <input type='checkbox' value={isChecked} onChange={((event) => {setIsChecked(event.target.checked);})}/>
            </section>
            <section className='card-container'>
            <h2>Radio value is : {radio}</h2> 
                <label>Audi</label>
                <input type='radio' checked={radio === 'Audi'} value='Audi' onChange={((event) => {setRadio(event.target.value);})} />  
                <br />
                <label>Ford</label>
                <input type='radio' checked={radio === 'Ford'} value='Ford' onChange={((event) => {setRadio(event.target.value);})} />  
                <br />
                <label>Toyota</label>
                <input type='radio' checked={radio === 'Toyota'} value='Toyota' onChange={((event) => {setRadio(event.target.value);})} />  
            </section>
            <section className='card-container'>
            <h2>Checkbox Display</h2> 
                <CheckComponent />
            </section>
            <section className='card-container'>
            <h2>Mažeikių Autocentras</h2> 
                <Accordion />
            </section>
            <section className='card-container'>
            <h2>Button List</h2> 
                <Example />
            </section>
            <section className='card-container'>
            <h2>My check box list</h2> 
                <MyCheckbox />
            </section>
        </>
    );
};

export default Checkboxes2;
