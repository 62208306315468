import React, { useEffect, useState } from 'react';
import './lessons.scss';
import { EnglishLessonsData } from '../../../../../data/englishLessonsData'; 

const Lessons = () => {
    const [showContent, setShowContent] = useState(0);
    const [data, setData] = useState([]);
  
    const handleClick = (id) => {
        setShowContent(id);
    };

    useEffect(() => {
        setData(EnglishLessonsData);
    },[]);

    return (
        <div>
             <div className='englishLessons-container'>
                {EnglishLessonsData.map((link) => (
                    <button 
                        type='button'
                        key={link.id} 
                        className='englishLessons-li'
                        onClick={() => handleClick(link.id)}
                    >
                        <p>{link.title}</p>
                        {link.description}
                    </button>
                ))}
            </div>
            {
                showContent ? (data[showContent - 1].content)  : <div className='englishLessons-text'>Please select lesson...</div>
            }
           
        </div>
       
    );
};

export default Lessons;