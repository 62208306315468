export const PARTS = [
    {
        categoryID: 1,
        category: 'Engines',
        categoriesType: ['gasoline', 'diesel' , 'gas', 'electricitie']
    },
    {
        categoryID: 2,
        category: 'Gear box',
        content: [
            {
                text: 'Dyzelinis',
                email: ''
            },
            {
                text:'Benzininis',
                email: ''
            },
            {
                text: 'Dujos',
                email: ''
            }
        ]
    },
    {
        categoryID: 3,
        category: 'Vairo kolonėlės',
        content: [
            {
                text: 'Dyzelinis',
                email: ''
            },
            {
                text:'Benzininis',
                email: ''
            },
            {
                text: 'Dujos',
                email: ''
            }
        ]
    },
    {
        categoryID: 4,
        category: 'Durys'
    },
    {
        categoryID: 5,
        category: 'Salonas'
    }
];
