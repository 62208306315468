import React, { useState } from 'react';
import { Checkbox } from './Checkboxes'; 
import { FireDB } from '../../../../../../../server/firebase-config';
import { addDoc, collection } from 'firebase/firestore';
import GetData from './GetData';

function MultipleCheckboxes() {
    const [likings, setLikings] = useState("") ;
    const [preferences, setPreferences] = useState( { 'cats': false, 'dogs': false, 'pigs': false } ) ;

    function togglePreference(animal) { 
        preferences[animal] = !preferences[animal]; 
        // Update animal likings 
        let newLikings = ""; 
        for ( let animal in preferences ) { 
          if ( preferences[animal] ) { 
            newLikings += animal + " "; 
          } 
        } 
        setLikings(newLikings); 
    
    } 

    console.log('setPreferences : ', setPreferences);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const devCollectionRef = collection(FireDB, 'friends');
        try{
          await addDoc(devCollectionRef, preferences);
        } catch (err) {
          alert(err);
                console.log(err);
        }
        // console.log('my test' + myArray)
    
      };
    
    
    return (
        <div style={{ backgroundColor: 'gray' }}>
             <h1> My analysis of you: You like {likings} </h1> 
             <p>not connected to firebase !!!</p>
            <form onSubmit={handleSubmit}>
            <Checkbox onText='You are a cats person' offText='You are not a cats person' togglePreference={togglePreference} animal='cats' /> 
            <Checkbox onText='You like dogs' offText="You don't like dogs" togglePreference={togglePreference} animal='dogs' /> 
            <Checkbox onText='Pigs make you happy' offText="Pigs don't make you happy" togglePreference={togglePreference} animal='pigs' /> 
            <Checkbox onText='You are a cats person' offText='You are not a cats person' togglePreference={togglePreference} animal='wolf' /> 
            <Checkbox onText='You are a cats person' offText='You are not a cats person' togglePreference={togglePreference} animal='tiger' /> 
            <button type='submit'>submit</button>
            </form>
            <hr />
            <GetData />
        </div>
    );
}

export default MultipleCheckboxes;
