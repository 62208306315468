import React, { useState } from 'react';
import {FireStorage} from '../../../../../../../server/firebase-config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Link } from 'react-router-dom';

function ImagesFirebaseFileUpload() {
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);

  const handleChange = e => {
    for(let i = 0; i < e.target.files.length; i++){
      const newImage = e.target.files[i];
      newImage['id'] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  const handleUpload = () => {
    const promises = [];
    images.map((image) => {
      const storageRef = ref(FireStorage, `images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      promises.push(uploadTask);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        }, 
        (error) => {
          console.log(error); 
        }, 
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
            // console.log('File available at', urls);
            setUrls((prevState) => [...prevState, urls]);
          });
        }
      );
    });

    Promise.all(promises)
      .then(() => alert('All images uploaded.'))
      .catch((err) => console.log(err));
  };

  // console.log('images:' , images);
  // console.log('urls:' , urls);

  /*

 <Route path={ROUTE.PUBLIC_IMAGES_FIREBASE} element={<ImagesFirebaseFileUpload />} />
            <Route path={ROUTE.PUBLIC_IMAGES_FIREBASE_code} element={<ImagesUploadFilesCodeExamples />} />

  PUBLIC_IMAGES_FIREBASE: '/public/images_firebase',
    PUBLIC_IMAGES_FIREBASE_code: '/public/images_firebase_code',
     */

  return (
    <div>
         Upload file example
         <Link to='/public/images_firebase_code'> Code example</Link>
      <hr/>
      <progress value={progress} max='100' />
      <br />
      <input type='file' multiple onChange={handleChange} />
      <button onClick={handleUpload}>Upload</button>
      <br />
      {
        urls.map((url, i) => (
          <div key={i}>
            <a href={url} terget='_blank'>
              {url}
            </a>
         </div>
        ))
      }
      <br />
      {
        urls.map((url, i) => (
          <img  
            style={{ width: '200px' }}
            key={i} 
            src={url || 'http://via.placeholder.com/200'} 
            alt='firebase'/>
        ))
      }
    </div>
    
  );
}

export default ImagesFirebaseFileUpload;
