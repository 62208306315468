import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSFunctionExpressions() {
    return (
        <RowWithTitle title='JS Function Expressions'>
            <div className='js-coursesContainer'>
            <div>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Another way to define a function is to use a function expression. To define a function inside an expression, we can use the function keyword. In a function expression, the function name is usually omitted. A function with no name is called an anonymous function. A function expression is often stored in a variable in order to refer to it.</li>
                </ul>
                <span>Consider the following function expression:</span>

                <pre  className='courses-code-container'>
                    const calculateArea = function(width, height) &#123;
                        <br />
                        const area = width * height;
                        <br />
                        return area;
                        <br />
                    &#125;

                </pre>

                <p>To declare a function expression:</p>

                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> 1. Declare a variable to make the variable&#39;s name be the name, or identifier, of your function. Since the release of ES6, it is common practice to use const as the keyword to declare the variable.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> 2. Assign as that variable&#39;s value an anonymous function created by using the function keyword followed by a set of parentheses with possible parameters. Then a set of curly braces that contain the function body.</li>
                   
                </ul>

                <p>To invoke a function expression, write the name of the variable in which the function is stored followed by parentheses enclosing any arguments being passed into the function.</p>

                <pre  className='courses-code-container'>
                    variableName(argument1, argument2)
                </pre>

                <p>Unlike function declarations, function expressions are not hoisted so they cannot be called before they are defined.</p>
                
                <pre  className='courses-code-container'>
                    const plantNeedsWater  = function(day) &#123;
                        <br />
                    if (day === &#39;Wednesday&#39;) &#123;
                        <br />
                        return true;
                        <br />
                    &#125; else &#123;
                        <br />
                        return false;
                        <br />
                        &#125;
                    <br />
                    &#125;
                    <br />

                    plantNeedsWater(&#39;Tuesday&#39;);
                    <br />
                    console.log(plantNeedsWater(&#39;Tuesday&#39;));
                </pre>

            </div>
            </div>
        </RowWithTitle>
    );
}

export default JSFunctionExpressions;