import React, { useEffect, useState } from 'react';
import { FireDB } from '../../../../../../server/firebase-config';

// import { collection } from 'firebase/firestore';
import { addDoc ,arrayUnion,collection, deleteDoc, doc, onSnapshot, query, updateDoc} from "firebase/firestore"; 


function FirestoreArrayEx() {
    const [book, setBook] = useState('');
    const [myBooks, setMyBooks] = useState([]);
    const [newText, setNewText] = useState('');

    // const frankDocRef = doc(FireDB, "users", "frank");
    const booksDocRef = collection(FireDB, "gruminTestBooks");

    const handleSubmit = async (event) => {
      event.preventDefault();
      const newBook = {
        autor: 'grumin',
        book: book,
        stories: []
      };
      await addDoc(booksDocRef, newBook);
    //   console.log('newBook:', newBook);
      setBook('');
    };

    useEffect(() => {
      const fetchData = query(booksDocRef);
      const showArray = onSnapshot(fetchData, (snapshot) => {
        setMyBooks(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
      return showArray;
    }, []);

    // console.log('myBooks:', myBooks);

   
    const handleTestClick = async (id) => {

        const bookRef = doc(FireDB, "gruminTestBooks", id);
        await updateDoc(bookRef, {
            stories: arrayUnion(newText)
            });
            setNewText('');
    };

    //   // Atomically add a new region to the "regions" array field.
    // updateDoc(frankDocRef, 'frank', {
    //   stories: arrayUnion("2greater_virginia")
    // });

    // Atomically remove a region from the "regions" array field.
    //  updateDoc(washingtonRef, {
    //   regions: arrayRemove("east_coast")
    // });

    const handleDelete = async (id) => {
        const bookDoc = doc(FireDB, "gruminTestBooks", id);
        await deleteDoc(bookDoc);
    };
    
    return (
        <div>
          <form onSubmit={handleSubmit}>
            <input type='text' name='myBook' placeholder='Enter book title' value={book} onChange={(event => setBook(event.target.value))}/>
            <button type='submit'>add</button>
          </form>
          <hr />
          {
            myBooks.map((book, index) => {
              return(
                <div 
                    key={book.id} 
                    style={{ 
                        border: '1px solid var(--color-sec)', 
                        margin: '5px' , 
                        padding: '10px', 
                        borderRadius: '5px', 
                        display: 'flex',
                        gap: '50px'
                    }}
                >
                    <div>
                        <p> {book.id}.</p>
                        <p>{book.book}</p>
                        <button onClick={() => handleDelete(book.id)} style={{ color: 'red' }}> delete</button>
                    </div>
                    <div>
                        <input type='text' name={newText} value={newText} placeholder='Enter books collection' onChange={(event) => setNewText(event.target.value)}/>
                        <button  id={index} onClick={() => handleTestClick(book.id)} style={{ color: 'white' , background: 'black' , padding: '3px 10px'}}> + </button>
                
                        {/* <h4> {book.stories}</h4> */}
                        <div>
                            {
                                book.stories.map((item, index) => {
                                return(
                                    <p key={index}>{item}</p>
                                );
                                })
                            }

                        </div>
                    </div>
                </div>
              );
            })
          }
        </div>
    );
}

export default FirestoreArrayEx;