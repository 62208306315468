export const Part1 = [
    {
        title: 'What is JSX?',
        content: 'JSX is a syntax extension for JavaScript. It was written to be used with React. JSX code looks a lot like HTML.',
        showCode: 'const h1 = <h1>Hello world</h1>;',
        showCode2: 'test' ,
      
    },
    {
        title: '',
        content: '',
        showCode: ''
    },
    {
        title: '',
        content: '',
        showCode: ''
    }

];
