import React, { useState } from 'react';
import './register.scss';
import { useUserContext } from '../../../context/UserAuthContext';
import { Link } from 'react-router-dom';
import ParticlesBg from 'particles-bg';

const Register = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const { registerUser } = useUserContext();

    const handleRegister = async (event) => {
        event.preventDefault();
        setError('');
        try {
          await registerUser(email, password);
        } catch (err) {
          // setError(err.message);
        }
    };
    

    return (
        <section className='register-container'>
            <form onSubmit={handleRegister} className='register-form'>
                <ParticlesBg
                    type='cobweb'
                    num={5}
                    color='#52BCFA'
                /> 
                {error && alert(error) }
                <ul className='register-card'>
                    <li>
                        <input placeholder='Email' type='email' className='register-input' onChange={(e) => setEmail(e.target.value)} required autoComplete='true'/>
                    </li>
                    <li>
                        <input placeholder='Password' type='password' className='register-input' onChange={(e) => setPassword(e.target.value)} required autoComplete='true'/>
                    </li>
                    <li>
                        <button type='Submit' className='register-button'>Register</button>
                    </li>
                    <li>
                        <Link to='/login' className='register-link'>Sign In</Link>
                    </li>
                    <li>
                        <Link to='/' className='register-link'>Home page</Link>
                    </li>
                </ul>
            </form>
        </section>
    );
};

export default Register;