import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSArrowFunctions() {
    return (
        <RowWithTitle title='JS Arrow Functions'>
            <div className='js-coursesContainer'>
          <div>
              <ul>
                  <li><MdIcons.MdOutlineTaskAlt/> ES6 introduced arrow function syntax, a shorter way to write functions by using the special “fat arrow” () =&#62; notation.</li>
                  <li><MdIcons.MdOutlineTaskAlt/> Arrow functions remove the need to type out the keyword function every time you need to create a function. Instead, you first include the parameters inside the ( ) and then add an arrow =&#62; that points to the function body surrounded in { } like this:</li>
              </ul>

              <pre  className='courses-code-container'>
                  const rectangleArea = (width, height) =&#62; &#123;
                    <br />
                    let area = width * height;
                    <br />
                    return area;
                    <br />
                  &#125;;
                  <br />
              </pre>
              
          </div>

          <div>
              <h3>Concise Body Arrow Functions</h3>

              <p>JavaScript also provides several ways to refactor arrow function syntax. The most condensed form of the function is known as concise body. We&#39;ll explore a few of these techniques below:</p>

              <ul>
                  <li><MdIcons.MdOutlineTaskAlt/> 1. Functions that take only a single parameter do not need that parameter to be enclosed in parentheses. However, if a function takes zero or multiple parameters, parentheses are required.</li>
                  <li><MdIcons.MdOutlineTaskAlt/> 2. A function body composed of a single-line block does not need curly braces. Without the curly braces, whatever that line evaluates will be automatically returned. The contents of the block should immediately follow the arrow =&#62; and the return keyword can be removed. This is referred to as implicit return.</li>
              </ul>

              <span>So if we have a function:</span>

              <pre  className='courses-code-container'>
                  const squareNum = (num) =&#62; &#123;
                    <br />
                    return num * num;
                    <br />
                  &#125;;
              </pre>

              <span>We can refactor the function to:</span>

              <pre  className='courses-code-container'>
                const squareNum = num =&#62; num * num;
              </pre>

              <span>Notice the following changes:</span>

              <ul>
                  <li><MdIcons.MdOutlineTaskAlt/> The parentheses around num have been removed, since it has a single parameter.</li>
                  <li><MdIcons.MdOutlineTaskAlt/> The curly braces &#123; &#125; have been removed since the function consists of a single-line block.</li>
                  <li><MdIcons.MdOutlineTaskAlt/> The return keyword has been removed since the function consists of a single-line block.</li>
              </ul>
              
          </div>
          </div>
        </RowWithTitle>
    );
}

export default JSArrowFunctions;