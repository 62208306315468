import React from 'react';
import QuizNavBarExample from "./QuizNavBar";
import { questions } from "./dataModel";

function QuizNavBar() {
    return (
        <>
            <QuizNavBarExample questions={questions} />
        </>  
    );
}

export default QuizNavBar;