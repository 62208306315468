import React, { Component } from 'react';

export default class CheckComponent extends Component {
    state = {
        CarPartsName: {
            Engine:false,
            Gearbox:false,
            Doors:false
         }
    };
    checkboxclick = (e) => {
            let {name, checked} = e.target;
            this.setState((e) => {
                let Selectedsport = e.CarPartsName;
                return Selectedsport[name] = checked;
            });
        
    };
    render() {
        let displayParts = Object.keys(this.state.CarPartsName).filter((x) => this.state.CarPartsName[x]);
        let cars = ["audi","bmw","ford"];
        return (
            <div>
                <center>
                
                <input type='checkbox' name='Engine' onChange={this.checkboxclick} />Engine <br />
                <input type='checkbox' name='Gearbox' onChange={this.checkboxclick} />Gearbox<br />
                <input type='checkbox' name='Doors' onChange={this.checkboxclick} />Doors<br />
                <hr />
                {cars.map((carName,index) => {
                    return (
                        <p key={index}>
                            <label>{carName}</label>
                            <input type='checkbox' name={carName} onChange={this.checkboxclick} />
                        </p>
                    );
                })}
                <hr />
                <div style={{ backgroundColor: "black", color: "cyan" }}>
                    {displayParts}
                </div>
                </center>
            </div>
        );
    }
}