import React from 'react';
import { useState } from 'react';
import imageCompression from 'browser-image-compression';

const CompressImage = () => {
    const [originImage, setOriginImage] = useState('');
    const [originImageFile, setOriginImageFile] = useState('');
    const [compressedImage, setCompressedImage] = useState('');
    const [fileName, setFileName] = useState('');

    const handle = (event) => {
        const imageFile = event.target.files[0];
        setOriginImage(imageFile);
        setOriginImageFile(URL.createObjectURL(imageFile));
        setFileName(imageFile.name);
    };

    const handleCompressImage = (e) => {
        e.preventDefault();
        const options = {
          maxSizeMB : 1,
          maxWidthHeight: 500,
          useWebWorker: true
        };
        if(options.maxSizeMB >= originImage/1024){
          alert("Image is too small, can't be compressed");
          return 0;
        }
        let output;
    
        imageCompression(originImage, options).then((x) => {
          output = x;
          const downloadLink = URL.createObjectURL(output);
          setCompressedImage(downloadLink);
          alert("Image is loaded successfully");
        });
    
      };
    

    return (
        <div>
            <h3>Compress and Download Image Using React JS</h3>
            <p>Fullstack Techies</p>
            <div>
            <form>
                <div style={{ backgroundColor: 'red', padding: '10px', width: '200px' }}>
              
                    {
                    originImageFile ? (<img src={originImageFile} style={{ width: '100%' }} alt='' />) : (<img src='http://navparivartan.in/wp-content/uploads/2018/11/placeholder.png' style={{ width: '100%' }} alt='' />)
                    }
               
                </div>
                <div>
                <input type='file' id='input' accept='.png, .jpeg, .jpg' onChange={(event) => handle(event)} />
                <br/>
                <br/>
                {originImageFile && <button onClick={(e) => handleCompressImage(e)}>Compress Image</button> }
                <br/>
                <br/>
                {compressedImage && <button primary>
                    <a href={compressedImage} download={fileName}>
                    {" "}
                    Download Image
                    </a>
                    </button> }
                </div>
                <div style={{ backgroundColor: 'green', padding: '10px', width: '200px' }}>
               
                    {
                    compressedImage ? (<img src={compressedImage} style={{ width: '100%' }} alt='' />) : (<img src='http://grumin.lt/web-images/placeholder.jpg' style={{ width: '100%' }} alt='' />)
                    }
               
                </div>
            </form>
            </div>

        </div>
    );
};

export default CompressImage;
