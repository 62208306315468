import React from 'react';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function LearnJSArrays() {
    return (
        <RowWithTitle title='JS Arrays'>
            <div className='js-coursesContainer'>
            <div>
                <span>
                    Organizing and storing data is a foundational concept of programming.
                    <br />
                    One way we organize data in real life is by making lists. Let’s make one here:
                </span>
                <pre  className='courses-code-container'>
                    let newYearsResolutions = [&#39;Keep a journal&#39;, &#39;Take a falconry class&#39;, &#39;Learn to juggle&#39;];
                    <br />
                    1. Keep a journal 
                    <br />
                    
                    2. Take a falconry class
                    <br />
                    3. Learn to juggle
                    <br />
                </pre>

                <hr />

                <h3>Create an Array</h3>

                <p>One way we can create an array is to use an array literal. An array literal creates an array by wrapping items in square brackets []. Remember from the previous exercise, arrays can store any data type — we can have an array that holds all the same data types or an array that holds different data types.</p>
                
                <p>The array is represented by the square brackets [] and the content inside.</p>
                <p>Each content item inside an array is called an element.</p>
                <p>There are three different elements inside the array.</p> 
                <p>Each element inside the array is a different data type.</p>
                
                <p>Example:</p>
                <pre  className='courses-code-container'>
                    const hobbies = [&#39;&#39;, &#39;&#39;, &#39;&#39;];
                </pre>
            </div>
            <hr />

            <h3>Accessing Elements</h3>

            <p>Each element in an array has a numbered position known as its index. We can access individual items using their index, which is similar to referencing an item in a list based on the item’s position.</p>

            <p>Arrays in JavaScript are zero-indexed, meaning the positions start counting from 0 rather than 1. Therefore, the first item in an array will be at position 0.</p>

            <p>You can also access individual characters in a string using bracket notation and the index.</p>
            <pre  className='courses-code-container'>
           
                const famousSayings = [&#39;Fortune favors the brave.&#39;, &#39;A joke is a very serious thing.&#39;, &#39;Where there is love there is life.&#39;];
              
                <br />
                const listItem   = famousSayings[0];
                <br />

                    console.log(listItem);
                    <br />
                    console.log(famousSayings[2]);
                    <br />
                    console.log(famousSayings[3]);
            </pre>

            <hr />

            <h3>Update Elements</h3>

            <p>In the previous exercise, you learned how to access elements inside an array or a string by using an index. Once you have access to an element in an array, you can update its value.</p>

            <pre  className='courses-code-container'>
                let seasons = [&#39;Winter&#39;, &#39;Spring&#39;, &#39;Summer&#39;, &#39;Fall&#39;];
                
                <br />
                seasons[3] = &#39;Autumn&#39;;
                <br />
                console.log(seasons); //Output: [&#39;Winter&#39;, &#39;Spring&#39;, &#39;Summer&#39;, &#39;Autumn&#39;];
            </pre>
            
            <hr />

            <h3>Arrays with let and const</h3>
            <span>
                You may recall that you can declare variables with both the let and const keywords. Variables declared with let can be reassigned.
                <br />
                Variables declared with the const keyword cannot be reassigned. However, elements in an array declared with const remain mutable. Meaning that we can change the contents of a const array, but cannot reassign a new array or a different value.
                <br />
                The instructions below will illustrate this concept more clearly. Pay close attention to the similarities and differences between the condiments array and the utensils array as you complete the steps.
            </span>
           
            <pre  className='courses-code-container'>
                 let condiments = [&#39;Ketchup&#39;, &#39;Mustard&#39;, &#39;Soy Sauce&#39;, &#39;Sriracha&#39;];
                <br />
                const utensils = [&#39;Fork&#39;, &#39;Knife&#39;, &#39;Chopsticks&#39;, &#39;Spork&#39;];
                <br />
                condiments[0] = &#39;Mayo&#39;;
                <br />
                console.log(condiments);
                <br />
                condiments = [&#39;Mayo&#39;];
                <br />
                console.log(condiments);
                <br />
                utensils[3] = &#39;Spoon&#39;;
                <br />
                console.log(utensils);
            </pre>

            <hr />

            <h3>The .length property</h3>
            <span>
                One of an array’s built-in properties is length and it returns the number of items in the array. We access the .length property just like we do with strings.
                <br />
                Check the example below:
            </span>

            <pre  className='courses-code-container'>
                const newYearsResolutions = [&#39;Keep a journal&#39;, &#39;Take a falconry class&#39;];
                <br />
                console.log(newYearsResolutions.length);// Output: 2
            </pre>
            <p>In the example above, we log newYearsResolutions.length to the console using the following steps:</p>
            <p>We use dot notation, chaining a period with the property name to the array, to access the length property of the newYearsResolutions array.</p>
            <p>Then we log the length of newYearsResolution to the console.</p>
            <p>Since newYearsResolution has two elements, 2 would be logged to the console.</p>
            <p>When we want to know how many elements are in an array, we can access the .length property.</p>

            <div>
            <hr />

            <h3>The .push() Method</h3>
            <span>
                Let’s learn about some built-in JavaScript methods that make working with arrays easier. These methods are specifically called on arrays to make common tasks, like adding and removing elements, more straightforward
                <br />
                One method, .push() allows us to add items to the end of an array. Here is an example of how this is used:
            </span>

            <pre  className='courses-code-container'>
                const itemTracker = [&#39;item 0&#39;, &#39;item 1&#39;, &#39;item 2&#39;];
                <br />
                itemTracker.push(&#39;item 3&#39;,&#39;item 4&#39;);
                <br />
                console.log(itemTracker);// Output: [&#39;item 0&#39;, &#39;item 1&#39;, &#39;item 2&#39;, &#39;item 3&#39;, &#39;item 4&#39;];
            </pre>
            <span>So, how does .push() work?</span>
            <p>We access the push method by using dot notation, connecting push to itemTracker with a period.</p>
            <p>Then we call it like a function. That’s because .push() is a function and one that JavaScript allows us to use right on an array.</p>
            <p>Th.push() can take a single argument or multiple arguments separated by commas. In this case, we’re adding two elements: &#39;item 3&#39; and &#39;item 4&#39; to itemTracker.</p>
            <p>Notice that .push() changes, or mutates, itemTracker. You might also see .push() referred to as a destructive array method since it changes the initial array.</p>
            <p>If you’re looking for a method that will mutate an array by adding elements to it, then .push() is the method for you!</p>
                            
            </div>

            <hr />

            <div>

            <h3>The .pop() Method</h3>
            <span>
                Another array method, .pop(), removes the last item of an array.
            </span>

            <pre  className='courses-code-container'>
                const newItemTracker = [&#39;item 0&#39;, &#39;item 1&#39;, &#39;item 2&#39;];

               
                <br />
                const removed = newItemTracker.pop();
                <br />
                console.log(newItemTracker); // Output: [ &#39;item 0&#39;, &#39;item 1&#39; ];
                <br />
                console.log(removed);// Output: item 2
            </pre>
            <p>In the example above, calling .pop() on the newItemTracker array removed item 2 from the end.</p>
            
            <p>.pop() does not take any arguments, it simply removes the last element of newItemTracker.</p>
            <p>.pop() returns the value of the last element. In the example, we store the returned value in a variable removed to be used for later.</p>
            <p>.pop() is a method that mutates the initial array.</p>
            <p>When you need to mutate an array by removing the last element, use .pop().</p>
                            
            </div>

            <hr />

            <div>

            <h3>More Array Methods</h3>
            <span>
                There are many more array methods than just .push() and .pop(). You can read about all of the array methods that exist on the <u>Mozilla Developer Network (MDN)</u>  array documentation.
            </span>
            <p>.pop() and .push() mutate the array on which they’re called. However, there are times that we don’t want to mutate the original array and we can use non-mutating array methods. Be sure to check MDN to understand the behavior of the method you are using.</p>
            <p>Some arrays methods that are available to JavaScript developers include: .join(), .slice(), .splice(), .shift(), .unshift(), and .concat() amongst many others. Using these built-in methods make it easier to do some common tasks when working with arrays.</p>
            <p>Below, we will explore some methods that we have not learned yet. We will use these methods to edit a grocery list. As you complete the steps, you can consult the MDN documentation to learn what each method does!</p>

            <pre  className='courses-code-container'>
                const groceryList = [&#39;orange juice&#39;, &#39;bananas&#39;, &#39;coffee beans&#39;, &#39;brown rice&#39;, &#39;pasta&#39;, &#39;coconut oil&#39;, &#39;plantains&#39;];

               
                <br />
                groceryList.shift();
                <br />
                console.log(groceryList);
                <br />
                groceryList.unshift(console.log(pastaIndex);popcornconsole.log(pastaIndex););
                <br />
                console.log(groceryList);
                <br />
                console.log(groceryList.slice(1, 4));
                <br />
                console.log(groceryList);
                <br />
                const pastaIndex = groceryList.indexOf(console.log(pastaIndex);pastaconsole.log(pastaIndex););
                <br />
                console.log(pastaIndex);
            </pre>         
            </div>

            <hr />

            <div>

            <h3>Arrays and Functions</h3>
            <span>
                TThroughout the lesson we went over arrays being mutable, or changeable. Well what happens if we try to change an array inside a function? Does the array keep the change after the function call or is it scoped to inside the function?
            </span>
            <p>Take a look at the following example where we call .push() on an array inside a function. Recall, the .push() method mutates, or changes, an array:</p>
          
            <pre  className='courses-code-container'>
                const flowers = [&#39;peony&#39;, &#39;daffodil&#39;, &#39;marigold&#39;];
                <br />
                function addFlower(arr) &#123;
                <br />
                     arr.push(&#39;lily&#39;);
                     <br />
                     &#125;
                <br />
                addFlower(flowers);
                <br />
                console.log(flowers);// Output: [&#39;peony&#39;, &#39;daffodil&#39;, &#39;marigold&#39;, &#39;lily&#39;]
              
            </pre>         
            </div>

            <hr />

            <div>

            <h3>Nested Arrays</h3>
            <span>
            Earlier we mentioned that arrays can store other arrays. When an array contains another array it is known as a nested array. Examine the example below:
            </span>
          
            <pre  className='courses-code-container'>
                const nestedArr = [[1], [2, 3]];
            </pre>    

            <p>To access the nested arrays we can use bracket notation with the index value, just like we did to access any other element:</p>   

            <pre  className='courses-code-container'>
                const nestedArr = [[1], [2, 3]];
                <br />
                console.log(nestedArr[1]);// Output: [2, 3]
            </pre>  

            <p>Notice that nestedArr[1] will grab the element in index 1 which is the array [2, 3]. Then, if we wanted to access the elements within the nested array we can chain, or add on, more bracket notation with index values.</p>  

             <pre  className='courses-code-container'>
                const nestedArr = [[1], [2, 3]]
                <br />
                console.log(nestedArr[1]); // Output: [2, 3]
                <br />
                console.log(nestedArr[1][0]);  // Output: 2
            </pre>  
            <p>In the second console.log() statement, we have two bracket notations chained to nestedArr. We know that nestedArr[1] is the array [2, 3]. Then to grab the first element from that array, we use nestedArr[1][0] and we get the value of 2.</p>            
            <pre  className='courses-code-container'>
                const numberClusters = [[1, 2], [3, 4], [5, 6]];
                <br />
                const target = numberClusters[2][1];
                <br />
                console.log(target);
            </pre> 
            <pre  className='courses-code-container'>
                const  array = [1,2,3];
                <br />
                console.log(array[2]);
                <br />
                array[1] = &#39;bandymas&#39;;
                <br />
                console.log(array);
                <br />
                console.log(array.length);
                <br />
                array.push(&#39;paskutinis&#39;);
                <br />
                console.log(array);
                <br />
                array.unshift(&#39;bananas&#39;);
                <br />
                console.log(array);
                <br />
                array.pop();
                <br />
                console.log(array);
                <br />
                array.shift();
                <br />
                console.log(array);
                <br />
                console.log(array.slice(0,2));
                <br />
                function xFunkcija(add) &#123;
                     <br />
                add.push(&#39;pridedu su funkcija&#39;);
                <br />
                &#125;
                xFunkcija(array);
                <br />
                console.log(array);
                <br />
                function wFunkcija(remove) &#123;
                     <br />
                remove.shift();
                <br />
                &#125;
                 <br />
                wFunkcija(array);
                <br />
                console.log(array);
            </pre> 
            </div>


            </div>
        </RowWithTitle>
    );
}

export default LearnJSArrays;