import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSFunctionsHelper() {
    return (
        <RowWithTitle title='JS Helper Functions'>
            <div className='js-coursesContainer'>
            <div>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> We can also use the return value of a function inside another function. These functions being called within another function are often referred to as helper functions. Since each function is carrying out a specific task, it makes our code easier to read and debug if necessary.</li>
                </ul>
                <span>If we wanted to define a function that converts the temperature from Celsius to Fahrenheit, we could write two functions like:</span>

                <pre  className='courses-code-container'>
                    function multiplyByNineFifths(number) &#123;
                        <br />
                    return number * (9/5);
                    <br />
                    &#125;;
                    <br />
                    
                    function getFahrenheit(celsius) &#123;
                        <br />
                    return multiplyByNineFifths(celsius) + 32;
                    <br />
                    &#125;;
                    <br />
                    
                    getFahrenheit(15); // Returns 59

                </pre>

                <p>In the example above:</p>

                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> getFahrenheit() is called and 15 is passed as an argument.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The code block inside of getFahrenheit() calls multiplyByNineFifths() and passes 15 as an argument.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> multiplyByNineFifths() takes the argument of 15 for the number parameter</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The code block inside of multiplyByNineFifths() function multiplies 15 by (9/5), which evaluates to 27.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> 27 is returned back to the function call in getFahrenheit()</li>
                    <li><MdIcons.MdOutlineTaskAlt/> getFahrenheit() continues to execute. It adds 32 to 27, which evaluates to 59</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Finally, 59 is returned back to the function call getFahrenheit(15)</li>
                </ul>

                <p>We can use functions to section off small bits of logic or tasks, then use them when we need to. Writing helper functions can help take large and difficult tasks and break them into smaller and more manageable tasks.</p>

                <pre  className='courses-code-container'>
                    function monitorCount(rows, columns) &#123;
                         <br />
                    return rows * columns;
                    <br />
                    &#125;
                     <br />

                    function costOfMonitors(rows, columns) &#123;
                         <br />
                    return monitorCount(rows, columns) * 200;
                    <br />
                    &#125;
                     <br />

                    const totalCost = costOfMonitors(5, 4);
                    <br />
                    console.log(totalCost); // display 4000
                </pre>
            </div>
            </div>
        </RowWithTitle>
    );
}

export default JSFunctionsHelper;