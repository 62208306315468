import React from 'react'; 
import './dashboardHeader.scss';
import { Link } from 'react-router-dom';
import { GiCubeforce, GiExitDoor } from 'react-icons/gi';
import { FiMenu, FiSearch } from 'react-icons/fi';


const DashboardHeader = ({ 
    showSidebar, 
    setShowSidebar, 
    user, 
    handleLogout, 
    isAdmin }) => {
    
    const toggle = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <header className='dashboardHeader'>
            <div className='left'>
                <div className='logo'>
                    <GiCubeforce className='icon'/>
                    <Link to='/admin' className='logoLink'>Grumin.lt</Link>
                </div>
                <button type='button' onClick={toggle}><FiMenu /></button>
            </div>
            <div className='right'>
                <form  className='search'>
                    <input type='text' placeholder='Search... '/>
                    <FiSearch className='searchIcon'/>
                </form>
                
                <ul className='userProfile'>
                    <li>{isAdmin ? (`Admin : ${user.email}`) : (`User : ${user.email}`)}</li>
                    <li>
                        <GiExitDoor className='icon' onClick={handleLogout}/>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default DashboardHeader;