import React from 'react';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSScope() {
    return (
        <RowWithTitle title='JS Scope'>
        <div className='js-coursesContainer'>
            <div>
                <span>
                    An important idea in programming is scope. Scope defines where variables can be accessed or referenced. While some variables can be accessed from anywhere within a program, other variables may only be available in a specific context.
                    <br />
                    You can think of scope like the view of the night sky from your window. Everyone who lives on the planet Earth is in the global scope of the stars. The stars are accessible globally. Meanwhile, if you live in a city, you may see the city skyline or the river. The skyline and river are only accessible locally in your city, but you can still see the stars that are available globally.
                    <br />
                    Over the next few exercises, we’ll explore how scope relates to variables and learn best practices for variable declaration.
                </span>
            </div>
            <hr />

            <div>
                <h3>Blocks and Scope</h3>
                <span>
                    We’ve seen blocks used before in functions and if statements. A block is the code found inside a set of curly braces {}. Blocks help us group one or more statements together and serve as an important structural marker for our code.
                    <br />
                    A block of code could be a function, like this:
                </span>
                <pre  className='courses-code-container'>
                    const logSkyColor = () =&#62; &#123;
                        <br />
                        let color = &#39;blue&#39;; 
                        <br />
                        console.log(color); // blue 
                        <br />
                    &#125;
                    <br />
                </pre>
                <p>Observe the block in an if statement:</p>
                <pre  className='courses-code-container'>
                    if (dusk)  &#123;
                         <br />
                        let color = &#39;pink&#39;;
                        <br />
                        console.log(color); // pink
                        <br />
                    &#125;
                    <br />
                </pre>
                <p>Example:</p>
                <pre  className='courses-code-container'>
                    const city = &#39;New York City&#39;;
                    <br />
                    function logCitySkyline()&#123;
                         <br />
                        let skyscraper = &#39;Empire State Building&#39;;
                        <br />
                        return &#39;The stars over the &#39; + skyscraper + &#39; in &#39; + city;
                        <br />
                    &#125;
                    <br />
                    console.log(logCitySkyline());
                    <br />
                </pre>
            </div>

            <hr />

            <div>
                <h3>Global Scope</h3>
                <span>
                    Scope is the context in which our variables are declared. We think about scope in relation to blocks because variables can exist either outside of or within these blocks.
                    <br />
                    In global scope, variables are declared outside of blocks. These variables are called global variables. Because global variables are not bound inside a block, they can be accessed by any code in the program, including code in blocks.
                    <br />
                    Let’s take a look at an example of global scope:
                </span>
                <pre  className='courses-code-container'>
                    const color = &#39;blue&#39;;
                    <br />
                    const returnSkyColor = () =&#62; &#123;
                    <br />
                    return color; // blue 
                    <br />
                    &#125;;
                    <br />
                    console.log(returnSkyColor()); // blue
                    <br />
                </pre>
                <p>Even though the color variable is defined outside of the block, it can be accessed in the function block, giving it global scope.</p>
                <p>In turn, color can be accessed within the returnSkyColor function block.</p>
                <p>Example:</p>
                <pre  className='courses-code-container'>
                    const satellite  = &#39;The Moon&#39;;
                    <br />
                    const galaxy = &#39;The Milky Way&#39;;
                    <br />
                    const stars = &#39;North Star&#39;;
                    <br />
                    function callMyNightSky()&#123;
                         <br />
                    return &#39;Night Sky: &#39; + satellite + &#39;, &#39; + stars + &#39;, and &#39; + galaxy;
                    <br />
                    &#125;
                     <br />
                    console.log(callMyNightSky());
                    <br />
                </pre>
            </div>

            <hr />

            <div>
                <h3>Block Scope</h3>
                <span>
                    The next context we’ll cover is block scope. When a variable is defined inside a block, it is only accessible to the code within the curly braces {}. We say that variable has block scope because it is only accessible to the lines of code within that block.
                    <br />
                    Variables that are declared with block scope are known as local variables because they are only available to the code that is part of the same block.
                    <br />
                    Block scope works like this:
                </span>
                <pre  className='courses-code-container'>
                    const logSkyColor = () =&#62; &#123;
                        <br />
                    let color = &#39;blue&#39;; 
                    <br />
                    console.log(color); // blue 
                    <br />
                    &#125;;
                    <br />
                    
                    logSkyColor(); // blue 
                    <br />
                    console.log(color); // ReferenceError
                    <br />
                </pre>
                <p>We define a function logSkyColor().</p>
                <p>Within the function, the color variable is only available within the curly braces of the function.</p>
                <p>If we try to log the same variable outside the function, it throws a ReferenceError.</p>
                <p>Example:</p> 
                <pre  className='courses-code-container'>
                    function logVisibleLightWaves()&#123;
                         <br />
                    const lightWaves = &#39;Moonlight&#39;;
                    <br />
                    console.log(lightWaves);
                    <br />
                    &#125;
                     <br />
                    console.log(lightWaves);
                    <br />
                </pre>
            </div>

            <hr />

            <div>
                <h3>Scope Pollution</h3>
                <span>
                    It may seem like a great idea to always make your variables accessible, but having too many global variables can cause problems in a program.
                    <br />
                    When you declare global variables, they go to the global namespace. The global namespace allows the variables to be accessible from anywhere in the program. These variables remain there until the program finishes which means our global namespace can fill up really quickly.
                    <br />
                    Scope pollution is when we have too many global variables that exist in the global namespace, or when we reuse variables across different scopes. Scope pollution makes it difficult to keep track of our different variables and sets us up for potential accidents. For example, globally scoped variables can collide with other variables that are more locally scoped, causing unexpected behavior in our code.
                    <br />
                    Let’s look at an example of scope pollution in practice so we know how to avoid it:
                </span>
                <pre  className='courses-code-container'>
                    let num = 50;
                    <br />
                    const logNum = () =&#62; &#123;
                        <br />
                    num = 100; // Take note of this line of code
                    <br />
                    console.log(num);
                    <br />
                    &#125;;
                    <br />
                    logNum(); // Prints 100
                    <br />
                    console.log(num); // Prints 100
                    <br />
                </pre>
                <p>We have a variable num</p>
                <p>Inside the function body of logNum(), we want to declare a new variable but forgot to use the let keyword.</p>
                <p>When we call logNum(), num gets reassigned to 100.</p>
                <p>The reassignment inside logNum() affects the global variable num.</p> 
                <p>Even though the reassignment is allowed and we won’t get an error, if we decided to use num later, we’ll unknowingly use the new value of num.</p>
                <p>While it’s important to know what global scope is, it’s best practice to not define variables in the global scope.</p>
                <p>Example:</p>
                <pre  className='courses-code-container'>
                    const satellite = &#39;The Moon&#39;;
                    <br />
                    const galaxy = &#39;The Milky Way&#39;;
                    <br />
                    let stars = &#39;North Star&#39;;
                    <br />

                    const callMyNightSky = () =&#62; &#123;
                        <br />
                    stars = &#39;Sirius&#39;;
                    <br />
                        return &#39;Night Sky: &#39; + satellite + &#39;, &#39; + stars + &#39;, &#39; + galaxy;
                        <br />
                    &#125;;
                    <br />
                    console.log(callMyNightSky());
                    <br />
                    console.log(stars);
                    <br />
                </pre>
            </div>

            <hr />

            <div>
                <h3>Practice Good Scoping</h3>
                <span>
                    Given the challenges with global variables and scope pollution, we should follow best practices for scoping our variables as tightly as possible using block scope.
                    <br />
                    Tightly scoping your variables will greatly improve your code in several ways:
                    <br />
                    *It will make your code more legible since the blocks will organize your code into discrete sections.
                    <br />
                    *It makes your code more understandable since it clarifies which variables are associated with different parts of the program rather than having to keep track of them line after line!
                    <br />
                    *It’s easier to maintain your code, since your code will be modular.
                    <br />
                    *It will save memory in your code because it will cease to exist after the block finishes running.
                    <br />
                </span>
                <p>Here’s another example of how to use block scope, as defined within an if block:</p>
                <pre  className='courses-code-container'>
                    const logSkyColor = () =&#62;  &#123;
                         <br />
                    const dusk = true;
                    <br />
                    let color = &#39;blue&#39;; 
                    <br />
                    if (dusk) &#123;
                         <br />
                        <br />
                        let color = &#39;pink&#39;;
                        <br />
                        console.log(color); // pink
                         <br />
                         &#125;
                    <br />
                    console.log(color); // blue 
                    <br />
                    &#125;;
                     <br />
                    
                    console.log(color); // ReferenceError
                    <br />
                </pre>
                <p>We create a variable dusk inside the logSkyColor() function.</p>
                
                <p>After the if statement, we define a new code block with the {} braces. Here we assign a new value to the variable color if the if statement is truthy.</p>
                
                <p>Within the if block, the color variable holds the value &#39;pink&#39;, though outside the if block, in the function body, the color variable holds the value &#39;blue&#39;.</p>
                
                <p>While we use block scope, we still pollute our namespace by reusing the same variable name twice. A better practice would be to rename the variable inside the block.</p> 
                
                <p>Block scope is a powerful tool in JavaScript, since it allows us to define variables with precision, and not pollute the global namespace. If a variable does not need to exist outside a block— it shouldn’t!</p>
                
                <p>Example:</p>
                <pre  className='courses-code-container'>
                    const logVisibleLightWaves = () =&#62; &#123;
                        <br />
                    let lightWaves = &#39;Moonlight&#39;;
                    <br />
                        let region = &#39;The Arctic&#39;;// Add if statement here:
                        <br />
                    <br />
                    if (region === &#39;The Arctic&#39;)&#123;
                        <br />
                        let lightWaves = &#39;Northern Lights&#39;;
                        <br />
                        console.log(lightWaves);
                        <br />
                    &#125;
                    <br />
                    console.log(lightWaves);
                    <br />
                    &#125;;
                    <br />
                    logVisibleLightWaves();
                    <br />
                </pre>
            </div>
            </div>
        </RowWithTitle>
    );
}

export default JSScope;