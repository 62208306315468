import React from 'react';
import JSArrowFunctions from './JSArrowFunctions';
import JSFunctionDeclaration from './JSFunctionDeclaration';
import JSFunctionExpressions from './JSFunctionExpressions';
import JSFunctionsDefoultParameters from './JSFunctionsDefoultParameters';
import JSFunctionsHelper from './JSFunctionsHelper';
import JSFunctionParAndArg from './JsFunctionsParAndArg';
import JSFunctionsReturn from './JSFunctionsReturn';

function LearnJSFunctions() {
    return (
        <div>
            <div>
                <JSFunctionDeclaration />
            </div>
            <div>
                <JSFunctionParAndArg />
            </div>
            <div>
                <JSFunctionsDefoultParameters />
            </div>
            <div>
                <JSFunctionsReturn />
            </div>
            <div>
                <JSFunctionsHelper />
            </div>
            <div>
                <JSFunctionExpressions />
            </div>
            <div>
                <JSArrowFunctions />
            </div>
            
        </div>
    );
}

export default LearnJSFunctions;