import React, { useEffect, useState } from 'react';

const BooksList = () => {
    const [books, setBooks] = useState(null);

      // + adding the use
    useEffect(() => {

        // we will use async/await to fetch this data
        async function getData() {
            const response = await fetch("https://www.anapioficeandfire.com/api/books");
            const data = await response.json();

            // store the data into our books variable
            setBooks(data) ;
        }
        getData();

    }, []); // <- you may need to put the setBooks function in this array

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh'
        }}>
            <h1>tutorial from : https://www.better.dev/fetch-data-from-an-api-with-react</h1>
            <h2>Game of Thrones Books</h2>

            {/* display books from the API */}
            {books && (
            <div >

                {/* loop over the books */}
                {books.map((book, index) => (
                <div key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <h2 style={{
                    padding: '10px 20px', color: '#ff0000'
                }}>{book.name}</h2>
                    <h2>{book.authors}</h2>
                    <h2>{book.country}</h2>
                </div>
                ))}

            </div>
            )}
        </div>
    );
    };

export default BooksList;