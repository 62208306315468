import React from 'react';

const CryptoTile = ({data, selected, onClick}) => {
    const { name, rate } = data;

    const handleClick = () => onClick(data);
    return (
        <div className={`CurrencyConverter-card ${selected && 'selected'}`} onClick={handleClick}>
           <div className='CurrencyConverter-card-body'>
                <div>{name}</div>
                <div>{rate}</div>
           </div>
        </div>
    );
};

export default CryptoTile;

