import React from "react";
import Edukus from "../pages/authorized/AdminDashboard/English/Lessons/Edukus";
import EngLessonA from "../pages/authorized/AdminDashboard/English/Lessons/EngLessonA";
import EngLessonB from "../pages/authorized/AdminDashboard/English/Lessons/EngLessonB";
import EngLinks from "../pages/authorized/AdminDashboard/English/Lessons/EngLinks";
import EngSentences from "../pages/authorized/AdminDashboard/English/Lessons/EngSentences";
import EngWords from "../pages/authorized/AdminDashboard/English/Lessons/EngWords";

export const EnglishLessonsData = [
    {
        id: 1,
        title: 'Words',
        description: 'learn words',
        content: <EngWords />
    },
    {
        id: 2,
        url: '',
        title: 'Sentences',
        description: 'learn Sentences',
        content: <EngSentences />
    },
    {
        id:3,
        url: 'lesson-a',
        title: 'Lesson A',
        description: 'Edukus',
        content: <EngLessonA />
    },
    {
        id:4,
        url: 'lesson-b',
        title: 'Lesson B',
        description: 'verbs',
        content: <EngLessonB />
    },
    {
        id:5,
        url: 'links',
        title: 'Links',
        description: 'useful links',
        content: <EngLinks />
    },
    {
        id:6,
        url: 'edukus',
        title: 'Edukus',
        description: 'Learn english',
        content: <Edukus />
    },
];
