import React from 'react';
import { SectionContainer, LogoImg, SectionTitle, Content, ContentPre } from './TutorialsSC';
import './TutorialAStyle.scss';
import logo from '../../../../../../../images/logo.svg';
import { Part1 } from './Part1';

const ExamplesPart1 = () => {
    function makeDoggy(e) {
        // Call this extremely useful function on an <img>.
        // The <img> will become a picture of a doggy.
        e.target.setAttribute('src', 'https://content.codecademy.com/courses/React/react_photo-puppy.jpeg');
        e.target.setAttribute('alt', 'doggy');
    }
    const kitty = (
        <img 
            onClick={makeDoggy}
            src='https://content.codecademy.com/courses/React/react_photo-kitty.jpg' 
            alt='kitty' 
            className='kitty-class'/>
    );

    // Write code here:
    const myBigDiv = <div className='big'>I AM A BIG DIV {2+5} = 2+5</div>;
  
    const AAmyList = (
    <ul>
        <li>pirmas</li>
        <li>antras</li>
        <li>trecias</li>
    </ul>
    );

    const myNewDiv = (
        <div>
          <h1>
            Hello world
          </h1>
        </div>
    );

    const blog = (
        <div>
          <h1>
          <LogoImg src={logo} />
            Welcome to grumins Blog!
            <LogoImg src={logo} className='App-logo'/>
          </h1>
          <article>
            Wow I had the tastiest sandwich today.  I <strong>literally</strong> almost freaked out.
          </article>
        </div>
        
    );

    const NewObjectsArray = [
        {
            id: "1",
            text: "111texttext"
        },
        {
            id: "2",
            text: "222texttext"
        },
        {
            id: "3",
            text: "3333texttext"
        },{
            id: "4",
            text: "444texttext"
        }
    ];

    const myTeam = [
        "center: <li>Benzo Walli</li>",
        "powerForward: <li>Rasha Loa</li>",
        "smallForward: <li>Tayshaun Dasmoto</li>",
        "shootingGuard: <li>Colmar Cumberbatch</li>",
        "pointGuard: <li>Femi Billon</li>"
    ];

    const p1 = <p id='large'>foo</p>;
    const p2 = <p id='small'>bar</p>;

    return (
        <div style={{ textAlign: 'center' }}>
                {Part1.map((item, id) => (
                    <SectionContainer key={id}>
                        <SectionTitle>{item.title}-{id + 1}</SectionTitle >
                        <Content>
                            {item.content}
                        </Content>
                        <ContentPre>
                            {item.showCode}
                        {/* const navBar = &lt;nav&gt; tutorial A &lt;/nav&gt;; */}
                        </ContentPre>
                        <ContentPre>
                            {item.showCode2}
                        {/* const navBar = &lt;nav&gt; tutorial A &lt;/nav&gt;; */}
                        </ContentPre>
                    </SectionContainer>
                ))}
           
            <SectionContainer>
               {myBigDiv}
            </SectionContainer>
            <SectionContainer>
                {kitty}
            </SectionContainer>
            <SectionContainer>
                {AAmyList}
            </SectionContainer>
            <SectionContainer>
                {myNewDiv}
            </SectionContainer>
            <SectionContainer>
                {blog}
            </SectionContainer>
            <SectionContainer>
                {p1} + {p2}
            </SectionContainer>
            <SectionContainer>
                {myTeam.map((item, index) => (
                <p key={index}>{item}</p>  
                ))}
            </SectionContainer>
            <SectionContainer>
                <ul>
                    {NewObjectsArray.map((idx, index) => (
                        <li key={index}><LogoImg src={logo} className='App-logo'/>{idx.id}{idx.text}<LogoImg src={logo} className='App-logo'/></li>
                    ))}
                </ul>
            </SectionContainer>
            <SectionContainer>
                <ul>
                    {myTeam[0]}
                </ul>
            </SectionContainer>
        </div>
    );
};

export default ExamplesPart1;