import React, { useState } from 'react';

export default function QuizNavBarExample({ questions }) {
  const [questionIndex, setQuestionIndex] = useState(0);

  const goBack = () =>
    setQuestionIndex((prevQuestionIndex) => prevQuestionIndex - 1);
  const goToNext = () =>
    setQuestionIndex((prevQuestionIndex) => prevQuestionIndex + 1);

  const onFirstQuestion = questionIndex === 0;
  const onLastQuestion = questionIndex === questions.length - 1;

  return (
    <nav>
      <span>Question #{questionIndex + 1}</span> 
      <div>
        <button onClick={goBack} disabled={onFirstQuestion}>
          Go Back
        </button>
        <button onClick={goToNext} disabled={onLastQuestion}>
          Next Question
        </button>
      </div>
      <p>Question: {questions[questionIndex].prompt}</p>
      <p>Answer: {questions[questionIndex].answer}</p>
      <hr />
      <div> 
        {
          questions.map((question, index) => {
            return(
              <p key={index}>{question.id} . {question.prompt} . Answer: {question.answer}</p>
            );
          })
        
        }
      </div>
    </nav>
  );
}