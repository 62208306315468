export const Data = [
    {
        number: '1',
        title: 'Varikliai',
        content: [
            {
                text: 'Dyzelinis',
                email: ''
            },
            {
                text:'Benzininis',
                email: ''
            },
            {
                text: 'Dujos',
                email: ''
            }
        ]
    },
    {
        number: '2',
        title: 'Pavarų dėžė',
        content: [
           { 
                text: 'Automatinė'
            },
            { 
                text: 'Mechaninė'
            }
        ]
    },
    {
        number: '3',
        title: 'Durys',
        content: [
            { 
                text: 'Kairė priekis'
            },
            { 
                text: 'Dešinė priekis'
            }
        ]
    },
    {
        number: '4',
        title: 'Kapotas',
        content: [
            { 
                text: 'Su Grotelėm'
            },
            { 
                text: 'Be grotelių'
            }
        ]
    }
];