import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSFunctionsReturn() {
    return (
        <RowWithTitle title='JS Return'>
            <div className='js-coursesContainer'>
            <div>
                <h3>Return</h3>

                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> When a function is called, the computer will run through the function’s code and evaluate the result of calling the function. By default that resulting value is undefined</li>
                </ul>

                <pre  className='courses-code-container'>
                    function rectangleArea(width, height) &#123;
                        <br />
                    let area = width * height;
                    <br />
                    &#125;
                    <br />
                    console.log(rectangleArea(5, 7)) // Prints undefined
                </pre>

                <p>In the code example, we defined our function to calculate the area of a width and height parameter. Then rectangleArea() is invoked with the arguments 5 and 7. But when we went to print the results we got undefined. Did we write our function wrong? No! In fact, the function worked fine, and the computer did calculate the area as 35, but we didn&#39;t capture it. So how can we do that? With the keyword return!</p>

                <pre  className='courses-code-container'>
                    function calculateArea(width, height) &#123;
                        <br />
                    const area = width * height;
                    <br />
                    return area;
                    <br />
                    &#125;
                    <br />
                </pre>

                <p>To pass back information from the function call, we use a return statement. To create a return statement, we use the return keyword followed by the value that we wish to return. Like we saw above, if the value is omitted, undefined is returned instead.</p>
                <p>When a return statement is used in a function body, the execution of the function is stopped and the code that follows it will not be executed. Look at the example below:</p>
                
                <pre  className='courses-code-container'>
                    function rectangleArea(width, height) &#123;
                         <br />
                    if (width &#60; 0 || height &#60; 0) &#123;
                        <br />
                        return &#39;You need positive integers to calculate area!&#39;;
                        <br />
                        &#125;
                    <br />
                    return width * height;
                    <br />
                    &#125;

                </pre>

                <p>If an argument for width or height is less than 0, then rectangleArea() will return &#39;You need positive integers to calculate area!&#39;. The second return statement width * height will not run.</p>
                <p>The return keyword is powerful because it allows functions to produce an output. We can then save the output to a variable for later use.</p>
            
                <pre  className='courses-code-container'>
                    function monitorCount(rows, columns) &#123;
                        <br />
                    return rows * columns;
                    <br />
                    &#125;
                    <br />

                    const numOfMonitors = monitorCount(5, 4);
                    <br />
                    console.log(numOfMonitors); // display 20
                </pre>
            </div>
            </div>
        </RowWithTitle>
    );
}

export default JSFunctionsReturn;