import React from 'react';
import './sidebar.scss';
import { Link } from 'react-router-dom';
import { GiExitDoor } from 'react-icons/gi';
import { ADMIN_SIDEBAR_DATA, USERS_SIDEBAR_DATA } from '../../../data/sidebarData';

const DashboardSidebar = ({ isAdmin }) => {
    return (
        <>
            {
                isAdmin ? (
                    <nav className='dashboard-sidebar-navigation'>
                        <ul className='sidebar-ul'>
                            <li className='sidebar-li sidebarUserProfile'>
                                <span>UserEmail</span>
                                <GiExitDoor className='icon'/>
                            </li>
                            {
                                ADMIN_SIDEBAR_DATA.map((section, sectionId) => (
                                    <li key={sectionId}>
                                        <p className='title'>{section.title}</p>
                                        {
                                            section.links.map((link, index) => (
                                                <div className='sidebar-li' key={index}>
                                                    <Link to={link.url} className={link.linkDesc === 'DASHBOARD' ? 'firstsidebarLink' : 'sidebarLink'}>
                                                        {link.icon} {link.linkDesc}
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                            
                                    </li> 
                                ))
                            }
                        </ul>
                    </nav>
                ) : (
                    <nav className='dashboard-sidebar-navigation'>
                        <ul className='sidebar-ul'>
                            <li className='sidebar-li sidebarUserProfile'>
                                <span>UserEmail</span>
                                <GiExitDoor className='icon'/>
                            </li>

                            {
                                USERS_SIDEBAR_DATA.map((section) => (
                                    <li key={section.id}>
                                        <p className='title'>{section.title}</p>
                                        {
                                            section.links.map((link, index) => (
                                                <div className='sidebar-li' key={index}>
                                                    <Link to={link.url} className={link.linkDesc === 'DASHBOARD' ? 'firstsidebarLink' : 'sidebarLink'}>
                                                        {link.icon} {link.linkDesc}
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                            
                                    </li> 
                                ))
                            }
                        </ul>
                    </nav>
                )
            }
        </>
        
    );
};

export default DashboardSidebar;