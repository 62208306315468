import React from 'react';
import './section.scss';

function SectionTitle({ title }) {
    return (
        <div className='sectionTitle'>
            <h2 className='sectionTitle-h2'>{title}</h2>
        </div>
    );
}

export default SectionTitle;