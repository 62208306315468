export const Data = [
    {
        question: 'What do you call a dog magician?',
        answer: 'A labradabra.',
        answer2: 'answer 2.'
    },
    {
        question: 'What do yonext text? ',
        answer: 'A labradabra.'
    },
    {
        question: 'What dxxx ?',
        answer: 'A labradabra.'
    }
];