import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from 'firebase/firestore';
import Dashboard from '../../../../components/Dashboard';
import Content from '../../../../components/Dashboard/Content';
import CardWithTitle from '../../../../components/Dashboard/Content/CardWithTitle';
import { FaDev, FaGitSquare, FaGithub } from 'react-icons/fa';
import { MdNotes, MdOutlineCategory, MdWeb } from 'react-icons/md';

const stepInitialState = {
    description: '',
    command: ''
};

const PhpTutorialPage = () => {
    const { user, loading, logoutUser } = useUserContext();

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    const { id } = useParams();
    const [tutorial, setTutorial] = useState({});
    const [ showStepsInput, setShowStepsInput] = useState(false);
    const [step, setStep ] = useState(stepInitialState);
    const [tutorialSteps, setTutorialSteps] = useState([]);
    const [editForm, setEditForm] = useState(false);
    const [ error, setError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const cardCollectionRef = doc(FireDB, 'codingTutorials', id);
        async function fetchData() {
            const response = await getDoc(cardCollectionRef);
            try{
                setTutorial(response.data());
            } catch(err) {
                alert(err);
            }
        }
        fetchData();
    },[id]);

    useEffect(() => {
        const tutorialStepsCollRef = collection(FireDB, `codingTutorials/${id}/tutorialSteps`);
        const fetchStepsData = query(tutorialStepsCollRef, orderBy('timestamp', 'desc'));
        const addCollection = onSnapshot(fetchStepsData, (snapshot) => {
            setTutorialSteps(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
        });

        return addCollection;
    },[]);

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setStep({
            ...step, [name] : value
        });
    };

    const tutorialUpdateInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setTutorial({
            ...tutorial, [name] : value
        });
    };

    const handleDelete = async (tutorialId) => {
        let confirmAction = confirm("Are you sure to delete this tutorial ?");
        if(confirmAction){
            const tutorialDoc = doc(FireDB, 'codingTutorials', tutorialId);
            await deleteDoc(tutorialDoc);
            navigate('/admin/php');
        } else {
            console.log('Canceled. not deleted.');
        }
    };

    const handleUpdateTutorial = async (tutId, tutcategory, tuttitle, tutauthor, tutauthorGithub, tuticon, tuturl, tutmyGithub, tutwebExample, tutnotes) => {
        if ( tutcategory === '' || tuttitle === '' || tutauthor === '' || tutauthorGithub === '' || tuticon === ''){
            setError('Empty input fields.');
            setTimeout(() => {
                setError(false);
            }, 3000);
        } else {
            const tutorialDoc = doc(FireDB, 'codingTutorials', tutId);
            const updateTutorialDoc = {
                ...tutorial, 
                category: tutcategory, 
                title: tuttitle , 
                author: tutauthor,
                authorGithub: tutauthorGithub,
                icon: tuticon,
                url: tuturl,
                myGithub: tutmyGithub,
                webExample: tutwebExample,
                notes: tutnotes
            };
            await updateDoc(tutorialDoc, updateTutorialDoc);
            setEditForm(false);
        }
    };

    const handleSubmitAddStep = async (event) => {
        event.preventDefault();
        const tutorialStepsCollRef = collection(FireDB, `codingTutorials/${id}/tutorialSteps`);
        const newStep = {description: step.description, command: step.command, timestamp: serverTimestamp() };
        await addDoc(tutorialStepsCollRef, newStep);
        setStep(stepInitialState);
    };

    const handleDeleteStep = async (stepId) => {
        let confirmAction = confirm("Are you sure to delete this step ?");
        if(confirmAction){
            const stepDoc = doc(FireDB, `codingTutorials/${id}/tutorialSteps`, stepId);
            await deleteDoc(stepDoc);
        } else {
            console.log('Canceled. not deleted.');
        }
    };

     return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <Content path='php'>
                                    <Link to='/admin/php' className='link-back'>back</Link>
                                    <div className='learnPhp-container'>
                                        <CardWithTitle 
                                            title={tutorial.title}
                                            cName='phpTutorialPage-container'
                                        >
                                            <ul>
                                                <li className='left'>
                                                   
                                                    <iframe src={tutorial.url} title='YouTube video player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
                                                </li>
                                                <li className='right'>
                                                    <p><MdOutlineCategory /><span className='row-desc'>Category:</span><span>{tutorial.category}</span></p>
                                                    <p><FaDev /><span className='row-desc'>Author:</span><span>{tutorial.author}</span></p>
                                                    <p><FaGitSquare /><span className='row-desc'>Author Github:</span><span>{tutorial.authorGithub}</span></p>
                                                    <p><FaGithub /><span className='row-desc'>My Github:</span><span>{tutorial.myGithub}</span></p>
                                                    <p><MdWeb /><span className='row-desc'>My www:</span><span>{tutorial.webExample}</span></p>
                                                    <p><MdNotes /><span className='row-desc'>Notes:</span></p>
                                                    <p className='notes'>{tutorial.notes}</p>
                                                    <h5>{tutorial.createdAt}</h5>
                                                    <div className='buttons'>
                                                        
                                                        <button type='button' onClick={() => setShowStepsInput(!showStepsInput)} >
                                                            {showStepsInput ? 'Close' : 'Add step'}
                                                        </button>
                                                        <button type='button' onClick={() => setEditForm(!editForm)}>{editForm ? 'Close' : 'Edit'}</button>
                                                        <button type='button' onClick={() => handleDelete(id)}>Delete</button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </CardWithTitle>
                                        {
                                            editForm &&
                                                <CardWithTitle 
                                                    title='Tutorial edit form'
                                                    cName='phpTutorialPage-container'
                                                >
                                                    <form onSubmit={(event) => event.preventDefault()} className='steps-form'>
                                                        {error && <p>{error}</p>}
                                                        <input type='text' placeholder='Category' name='category' value={tutorial.category} onChange={tutorialUpdateInput} required />
                                                        <input type='text' placeholder='Title' name='title' value={tutorial.title} onChange={tutorialUpdateInput} required />
                                                        <input type='text' placeholder='Author' name='author' value={tutorial.author} onChange={tutorialUpdateInput} required/>
                                                        <input type='text' placeholder='Author Github' name='authorGithub' value={tutorial.authorGithub} onChange={tutorialUpdateInput} required/>
                                                        <input type='text' placeholder='Icon' name='icon' value={tutorial.icon} onChange={tutorialUpdateInput} required/>
                                                        <input type='text' placeholder='Url' name='url' value={tutorial.url} onChange={tutorialUpdateInput} />
                                                        <input type='text' placeholder='My Github' name='myGithub' value={tutorial.myGithub} onChange={tutorialUpdateInput} required/>
                                                        <input type='text' placeholder='Web Example' name='webExample' value={tutorial.webExample} onChange={tutorialUpdateInput} required/>
                                                        <input type='text' placeholder='Notes' name='notes' value={tutorial.notes} onChange={tutorialUpdateInput} required/>
                                                        
                                                        <button type='button' onClick={() => handleUpdateTutorial(
                                                            id, 
                                                            tutorial.category, 
                                                            tutorial.title, 
                                                            tutorial.author,
                                                            tutorial.authorGithub,
                                                            tutorial.icon,
                                                            tutorial.url,
                                                            tutorial.myGithub,
                                                            tutorial.webExample,
                                                            tutorial.notes
                                                            )}
                                                        >Update tutorial</button>
                                                    </form>
                                                    
                                                </CardWithTitle>
                                        }
                                       
                                        <CardWithTitle 
                                            title='Step by step'
                                            cName='phpTutorialPage-container'
                                        >
                                            {
                                                showStepsInput &&
                                                    <form onSubmit={handleSubmitAddStep} className='steps-form'>
                                                        <input type='text' placeholder='Description' name='description' value={step.description} onChange={handleInput} required />
                                                        <input type='text' placeholder='Command' name='command' value={step.command} onChange={handleInput} />
                                                        <button type='submit'>Add new step</button>
                                                    </form>
                                            }
                                           {
                                            tutorialSteps.length > 0 ? 
                                                tutorialSteps.map((step) => (
                                                    <div key={step.id} className='steps-list'>
                                                        <div className='content'>
                                                            <span>{step.description}</span>
                                                            <span> {step.command}</span>
                                                        </div>
                                                        <button type='button' onClick={() => handleDeleteStep(step.id)}>delete step</button>
                                                    </div>
                                                )) : 'No steps.'
                                           }
                                        </CardWithTitle>
                                    </div>
                                </Content>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default PhpTutorialPage;