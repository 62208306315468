import React from 'react';

const CardWithTitle = ({ title, cName, children }) => {
    return (
        <div className={cName}>
            <h1>{title}</h1>
            {children}
        </div>
    );
};

export default CardWithTitle;