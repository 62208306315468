import React from 'react';
import './studentsList.scss';
import StudentsHeader from './components/Header';
import TeamImage from './components/Image';
import StudentsButton from './components/Button';

const StudentsList = () => {
    return (
        <div className='studentsHomeContainer'>
            <StudentsHeader color={'green'} />
            <TeamImage size={'80%'}/>
            <StudentsButton redirectPath={'/admin/react-students'} name={'Students List'} />
            <p>Užduotis PC workshop 5: </p>
            <p>Ištraukti iš sąrašo studentų duomenis į list page</p>
            <p>List page padaryti lentelę</p>
            <p>List page kiekvieno studento Details mygtukas turi buti pagal studento color</p>
            <p>Paspaudus Details, turi atsidaryti kitas puslapis ir parodyti studento duomenis, bei headeris pasikeisti i studento color.</p>
        </div>
    );
};

export default StudentsList;