import styled from "styled-components";

export const CryptoRow = styled.div`
    margin:0;
    width:100%;
    background: #11162efa;
    height: 100%;
    position:relative;
    top:20px;
`;
export const CrytoContainer = styled.ul`
    margin:0 auto;
    padding: 0;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    z-index: 20;
    color:#fff;
    list-style-type:none;

    @media screen and (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
`;
export const ConverterBox = styled.li`
    margin-top: 50px;
    padding: 30px;
    border:1px solid #ff0000;
`;
export const ConverterForm = styled.form`
    display:flex;
    flex-direction:column;
`;
export const FormLabel = styled.label`
    margin-top:20px;
    color:#ff0000;
`;
export const FormSelect = styled.select`
    padding:10px;
`;
export const FormInput = styled.input`
    padding: 10px;
    background:#c5c5c5;
    color:#000;
`;
export const FormBtn = styled.button`
    margin-top: 30px;
    background-color:#7e8ab3;
    color:#fff;
    padding:10px 20px;
    border:none;
    border-radius: 5px;
    cursor:pointer;
`;
export const CoinImg = styled.img`
    height: 30px;
    width: 30px;
    margin-right: 10px;
`;

