import React from 'react';
import { PARTS } from './partsData';

function ListA() {
   return (                                                                                                                                                                                                                                                                                                                                 
        <div>
            <ul>
                {PARTS.map((partCategory) => 
                    <li key={partCategory.categoryID}>
                        {partCategory.categoryID}. 
                        {partCategory.category}
                        <br />
                        {partCategory.categoriesType}
                    </li>
                )}
            </ul> 
        </div>
    );
}

export default ListA;
