import React from 'react';
import { Toggle } from './Toggle';
import ColorPicker from './ColorPicker';

function ChangeButtonColor() {
    return (
        <div>
             <Toggle />

             <ColorPicker />
        </div>

    );
}

export default ChangeButtonColor;