import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSLesson5() {
   
    return (
        <RowWithTitle title='JS CONDITIONAL STATEMENTS'>
            <div className='js-coursesContainer'>
            <div>
                <h3>CONDITIONAL STATEMENTS</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> if-else decisions can be modeled in code by creating conditional statements. A conditional statement checks a specific condition(s) and performs a task based on the condition(s).</li>
                    <li><MdIcons.MdOutlineTaskAlt/> if, else if, and else statements</li>
                    <li><MdIcons.MdOutlineTaskAlt/> comparison operators</li>
                    <li><MdIcons.MdOutlineTaskAlt/> logical operators</li>
                    <li><MdIcons.MdOutlineTaskAlt/> truthy vs falsy values</li>
                    <li><MdIcons.MdOutlineTaskAlt/> ternary operators</li>
                    <li><MdIcons.MdOutlineTaskAlt/> switch statement</li>
                </ul>
            </div>
            <hr />
            <div>
                <h3>If Statement</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> if-else decisions can be modeled in code by creating conditional statements. A conditional statement checks a specific condition(s) and performs a task based on the condition(s).</li>
                    <li><MdIcons.MdOutlineTaskAlt/> if, else if, and else statements</li>
                    <li><MdIcons.MdOutlineTaskAlt/> comparison operators</li>
                    <li><MdIcons.MdOutlineTaskAlt/> logical operators</li>
                    <li><MdIcons.MdOutlineTaskAlt/> truthy vs falsy values</li>
                    <li><MdIcons.MdOutlineTaskAlt/> ternary operators</li>
                    <li><MdIcons.MdOutlineTaskAlt/> switch statement</li>
                </ul>
                <pre  className='courses-code-container'>
                if (true)&#123;
                <br />
                console.log (&#39;This message will print!&#39;)
                <br />
                &#125;
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The if keyword followed by a set of parentheses () which is followed by a code block, or block statement, indicated by a set of curly braces {}</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Inside the parentheses (), a condition is provided that evaluates to true or false.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> If the condition evaluates to true, the code inside the curly braces {} runs, or executes.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> If the condition evaluates to false, the block won&#39;t execute.</li>
                </ul>
            </div>
            <hr />
            <div>
                <h3>If...Else Statements</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> An else statement must be paired with an if statement, and together they are referred to as an if...else statement.</li>
                   
                </ul>
                <pre  className='courses-code-container'>
                    if (false)&#123;
                    <br />
                    console.log(&#39;The code in this block will not run.&#39;)
                    <br />
                    &#125; else &#123;
                    <br />
                    console.log(&#39;But the code in this block will!&#39;)
                    <br />
                     &#125;
                
                    &#47;&#47;Prints: But the code in this block will!
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Uses the else keyword following the code block of an if statement.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Has a code block that is wrapped by a set of curly braces {}</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The code inside the else statement code block will execute when the if statement&#39;s condition evaluates to false.</li>
                </ul>
                <div className='courses-show-task'>
                    <MdIcons.MdOutlineQuestionAnswer/> : if...else statements allow us to automate solutions to yes-or-no questions, also known as binary decisions.
                </div>
            </div>
            <hr />
            <div>
                <h3>Comparison Operators</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Here is a list of some handy comparison operators and their syntax:</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Less than: &#60; </li>
                    <li><MdIcons.MdOutlineTaskAlt/> Greater than: &#62;</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Less than or equal to: &#60;=</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Greater than or equal to: &#62;=</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Is equal to: ===</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Is not equal to: !==</li>
                   
                </ul>
                <pre  className='courses-code-container'>
                    10 &#60; 12  &#47;&#47; Evaluates to true
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> It can be helpful to think of comparison statements as questions. When the answer is “yes”, the statement evaluates to true, and when the answer is “no”, the statement evaluates to false. The code above would be asking: is 10 less than 12? Yes! So 10 &#60; 12 evaluates to true.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> We can also use comparison operators on different data types like strings:</li>
                </ul>
                <pre  className='courses-code-container'>
                &#39;apples&#39; === &#39;oranges&#39; &#47;&#47; false
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> In the example above, we&#39;re using the identity operator (===) to check if the string &#39;apples&#39; is the same as the string &#39;oranges&#39;. Since the two strings are not the same, the comparison statement evaluates to false.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> All comparison statements evaluate to either true or false and are made up of:</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Two values that will be compared.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> An operator that separates the values and compares them accordingly (&#62; , &#60;, &#60;= ,&#62;= , === ,!== ).</li>
                </ul>
                <pre  className='courses-code-container'>
                    let hungerLevel = 7;
                    <br />

                    if (hungerLevel &#62; 7)&#123;
                    <br />
                    console.log(&#39;Time to eat!&#39;)
                    <br />
                    &#125; else &#123;
                    <br />
                        console.log(&#39;We can eat later!&#39;)
                    <br />
                    &#125;
                </pre>
            </div>
            <hr />
            <div>
                <h3>Logical Operators</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Working with conditionals means that we will be using booleans, true or false values. In JavaScript, there are operators that work with boolean values known as logical operators. We can use logical operators to add more sophisticated logic to our conditionals. There are three logical operators:</li>
                    <li><MdIcons.MdOutlineTaskAlt/> the and operator (&#38;&#38;)</li>
                    <li><MdIcons.MdOutlineTaskAlt/> the or operator (||)</li>
                    <li><MdIcons.MdOutlineTaskAlt/> the not operator, otherwise known as the bang operator (!)</li>
                    <li><MdIcons.MdOutlineTaskAlt/> When we use the &#38;&#38; operator, we are checking that two things are true</li>
                </ul>
                <pre  className='courses-code-container'>
                    if (stopLight === &#39;green&#39; &#38;&#38; pedestrians === 0) &#123;
                        <br />
                    console.log(&#39;Go!&#39;);
                    <br />
                    &#125; else &#123;
                        <br />
                    console.log(&#39;Stop&#39;);
                    <br />
                    &#125;
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> When using the &#38;&#38; operator, both conditions must evaluate to true for the entire condition to evaluate to true and execute. Otherwise, if either condition is false, the &#38;&#38; condition will evaluate to false and the else block will execute.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> If we only care about either condition being true, we can use the || operator:</li>
                </ul>
                <pre  className='courses-code-container'>
                    if (day === &#39;Saturday&#39; || day === &#39;Sunday&#39;) &#123;
                        <br />
                    console.log(&#39;Enjoy the weekend!&#39;);
                    <br />
                    &#125; else &#123;
                        <br />
                    console.log(&#39;Do some work.&#39;);
                    <br />
                    &#125;
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> When using the || operator, only one of the conditions must evaluate to true for the overall statement to evaluate to true. In the code example above, if either day === &#39;Saturday&#39; or day === &#39;Sunday&#39; evaluates to true the if&#39;s condition will evaluate to true and its code block will execute. If the first condition in an || statement evaluates to true, the second condition won&#39;t even be checked. Only if day === &#39;Saturday&#39; evaluates to false will day === &#39;Sunday&#39; be evaluated. The code in the else statement above will execute only if both comparisons evaluate to false.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The ! not operator reverses, or negates, the value of a boolean:</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Essentially, the ! operator will either take a true value and pass back false, or it will take a false value and pass back true.</li>
                </ul>
                <pre  className='courses-code-container'>
                    let excited = true;
                    <br />
                    console.log(!excited); // Prints false
                    <br />

                    let sleepy = false;
                    <br />
                    console.log(!sleepy); // Prints true
                </pre>
               
            </div>
            <hr />
            <div>
                <h3>Truthy and Falsy</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Sometimes, you&#39;ll want to check if a variable exists and you won&#39;t necessarily want it to equal a specific value — you&#39;ll only check to see if the variable has been assigned a value. Here&#39;s an example:</li>
                   
                </ul>
                <pre  className='courses-code-container'>
                    let myVariable = &#39;I Exist!&#39;;
                    <br />
    
                    if (myVariable)   &#123;
                    <br />
                        console.log(myVariable)
                    <br />
                    &#125; else &#123;
                    <br />
                        console.log(&#39;The variable does not exist.&#39;)
                    <br />
                    &#123;
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The code block in the if statement will run because myVariable has a truthy value; even though the value of myVariable is not explicitly the value true, when used in a boolean or conditional context, it evaluates to true because it has been assigned a non-falsy value.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> So which values are falsy— or evaluate to false when checked as a condition? The list of falsy values includes:</li>
                    <li><MdIcons.MdOutlineTaskAlt/> 0</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Empty strings like &#34;&#34; or &#39;&#39;</li>
                    <li><MdIcons.MdOutlineTaskAlt/> null which represent when there is no value at all</li>
                    <li><MdIcons.MdOutlineTaskAlt/> undefined which represent when a declared variable lacks a value</li>
                    <li><MdIcons.MdOutlineTaskAlt/> NaN, or Not a Number</li>
                </ul>
                <pre  className='courses-code-container'>
                    let numberOfApples = 0;
                    <br />
    
                    if (numberOfApples)   &#123;
                    <br />
                        console.log(&#39;Let us eat apples!&#39;)
                    <br />
                    &#125; else &#123;
                    <br />
                        console.log(&#39;No apples left!&#39;)
                    <br />
                    &#123;
                    &#47;&#47; Prints &#39;No apples left!&#39;
                </pre>
                <ul>
                   
                    <li><MdIcons.MdOutlineTaskAlt/> The condition evaluates to false because the value of the numberOfApples is 0. Since 0 is a falsy value, the code block in the else statement will run.</li>
                </ul>
               
            </div>
            <hr />
            <div>
                <h3>Truthy and Falsy Assignment</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Truthy and falsy evaluations open a world of short-hand possibilities!</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Say you have a website and want to take a user&#39;s username to make a personalized greeting. Sometimes, the user does not have an account, making the username variable falsy. The code below checks if username is defined and assigns a default string if it is not:</li>
                   
                </ul>
                <pre  className='courses-code-container'>
                    let username = &#39;&#39;;
                    <br />
                    let defaultName;
                    <br />
                    if (username)  &#123;
                    <br />
                        defaultName = username;
                    <br />
                    &#125; else &#123;
                    <br />
                        cdefaultName = &#39;Stranger&#39;;
                    <br />
                    &#123;
                    <br />
                    console.log(defaultName); // Prints: Stranger
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> If you combine your knowledge of logical operators you can use a short-hand for the code above. In a boolean condition, JavaScript assigns the truthy value to a variable if you use the || operator in your assignment:</li>
                </ul>
                <pre  className='courses-code-container'>
                    let username = &#39;&#39;;
                    <br />
                    let defaultName = username || &#39;Stranger&#39;;
                    <br />
                    console.log(defaultName); // Prints: Stranger
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Because || or statements check the left-hand condition first, the variable defaultName will be assigned the actual value of username if it is truthy, and it will be assigned the value of  &#39;Stranger&#39; if username is falsy. This concept is also referred to as short-circuit evaluation.</li>
                </ul>
                <pre  className='courses-code-container'>
                     let tool = &#39;marker&#39;;
                    <br />
                    &#47;&#47; Use short circuit evaluation to assign  writingUtensil variable below:
                    <br />
                    let writingUtensil = tool || &#39;pen&#39;;
                    <br />
                    console.log(`The $  &#123; writingUtensil &#125; is mightier than the sword.`);
                </pre>
               
            </div>
            <hr />
            <div>
                <h3>Ternary Operator</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> In the spirit of using short-hand syntax, we can use a ternary operator to simplify an if...else statement. Take a look at the if...else statement example:</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    let isNightTime = true;
                    <br />
                    if (isNightTime) &#123;
                         <br />
                    console.log(&#39;Turn on the lights!&#39;);
                    <br />
                    &#125; else &#123;
                        <br />
                    console.log(&#39;Turn off the lights!&#39;);
                    <br />
                    &#125;
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> We can use a ternary operator to perform the same functionality:</li>
                </ul>
                <pre  className='courses-code-container'>
                    isNightTime ? console.log(&#39;Turn on the lights!&#39;) : console.log(&#39;Turn off the lights!&#39;);
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> In the example above:</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The condition, isNightTime, is provided before the ?</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Two expressions follow the ? and are separated by a colon :</li>
                    <li><MdIcons.MdOutlineTaskAlt/> If the condition evaluates to true, the first expression executes.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> If the condition evaluates to false, the second expression executes.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Like if...else statements, ternary operators can be used for conditions which evaluate to true or false.</li>
                </ul>
            </div>
            <hr />
            <div>
                <h3>Else If Statements</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> We can add more conditions to our if...else with an else if statement. The else if statement allows for more than two possible outcomes. You can add as many else if statements as you&#39;d like, to make more complex conditionals!</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The else if statement always comes after the if statement and before the else statement. The else if statement also takes a condition. Let&#39;s take a look at the syntax:</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    let stopLight = &#39;yellow&#39;;
                    <br />
    
                    if (stopLight === &#39;red&#39;) &#123;
                        <br />
                    console.log(&#39;Stop!&#39;);
                    <br />
                    &#125; else if (stopLight === &#39;yellow&#39;) &#123;
                        <br />
                    console.log(&#39;Slow down.&#39;);
                    <br />
                    &#125; else if (stopLight === &#39;green&#39;) &#123;
                        <br />
                    console.log(&#39;Go!&#39;);
                    <br />
                    &#125; else &#123;
                        <br />
                    console.log(&#39;Caution, unknown!&#39;);
                    <br />
                    &#125;

                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The else if statements allow you to have multiple possible outcomes. if/else if/else statements are read from top to bottom, so the first condition that evaluates to true from the top to bottom is the block that gets executed.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> In the example above, since stopLight === &#39;red&#39; evaluates to false and stopLight === &#39;yellow&#39; evaluates to true, the code inside the first else if statement is executed. The rest of the conditions are not evaluated. If none of the conditions evaluated to true, then the code in the else statement would have executed.</li>
                </ul>
               
            </div>
            </div>
        </RowWithTitle>
        
    );
}

export default JSLesson5;