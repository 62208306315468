import React, { useState } from 'react';

export default function ColorPicker() {
  const [color, setColor] = useState('Tomato');//'Tomato' nustato pirmine bg spalva

 const divStyle = {backgroundColor: color, padding: '20px 0'};

  return (
    <div style={divStyle}>
      <p>The color is {color}</p>
      <button onClick={() => setColor('Aquamarine')}>
        Aquamarine
      </button>
      <button onClick={() => setColor('BlueViolet')}>
        BlueViolet
      </button>
      <button onClick={() => setColor('Chartreuse')}>
        Chartreuse
      </button>
      <button onClick={() => setColor('CornflowerBlue')}>
        CornflowerBlue
      </button>
    </div>
  );
}