import React from 'react';
import Dashboard from '../../../components/Dashboard';
import { useUserContext } from '../../../context/UserAuthContext';

const UsersDashboard = () => {
    const { user, loading, logoutUser } = useUserContext();

    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <h2>Hello !</h2>
                            </Dashboard>
                        )
            }
        </> 
    );
};

export default UsersDashboard;