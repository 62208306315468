import React, { useEffect, useState } from 'react';
import '../usefulLinks.scss';
import { FireDB } from '../../../../../server/firebase-config';
import { collection, onSnapshot, query } from 'firebase/firestore';
import RowWithTitle from '../../../../../components/Dashboard/Content/RowWithTitle';

const LinksCollection = () => {
    const [links, setLinks] = useState([]);
    const [linksLength, setLinksLength] = useState(0);
   

    const linksCollectionRef = collection(FireDB, 'gruminUsefulLinks');

    useEffect(() => {
        const fethcLinksCollection = query(linksCollectionRef);
        const onsub = onSnapshot(fethcLinksCollection, (snapshot) => 
            setLinks(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        );
        return onsub;
    }, []);

    useEffect(() => {
        setLinksLength(links.length);
    }, [links]);


      


    return (
        <>
            <RowWithTitle title='Useful links'>
                <div className='usefulLinks-container'>
                    { linksLength > 0 
                        ?
                            links.map((link) => (
                                <a href={link.url} key={link.id} target='blank' className='usefulLinks-button'>
                                    {link.title}
                                </a>
                            ))
                            : 'No data.'
                    }
                </div>
            </RowWithTitle>
            <RowWithTitle title='Json data links'>
                <div className='linksCollection-json-data'>
                    <a href='https://jsonplaceholder.typicode.com/users' target='blank'>ex: users</a>
               
               
                    <a href='https://www.anapioficeandfire.com/api/books' target='blank'>ex: books</a>
                
                    <a href='https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&order=market_cap_desc&per_page=1000&page=1&sparkline=false' target='blank'>ex: crypto</a>
                </div>
                
            </RowWithTitle>
        </>
        
       
    );
};

export default LinksCollection;