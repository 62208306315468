import React, { useEffect, useState } from 'react';
import '../usefulLinks.scss';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import RowWithTitle from '../../../../../components/Dashboard/Content/RowWithTitle';
import { FireDB } from '../../../../../server/firebase-config';
import  { MdDelete } from 'react-icons/md';

const EditLinks = () => {
    const [links, setLinks] = useState([]);
    const [linksLength, setLinksLength] = useState(0);
   

    const linksCollectionRef = collection(FireDB, 'gruminUsefulLinks');

    useEffect(() => {
        const fethcLinksCollection = query(linksCollectionRef);
        const onsub = onSnapshot(fethcLinksCollection, (snapshot) => 
            setLinks(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        );
        return onsub;
    }, []);

    useEffect(() => {
        setLinksLength(links.length);
    }, [links]);

    const handelDelete = async (id) => {
        const linkDoc = doc(linksCollectionRef, id);
        await deleteDoc(linkDoc);
    };

    return (
        <RowWithTitle title='Useful links'>
            <div className='editLink-container'>
                { linksLength > 0 
                    ?
                        links.map((link) => (
                            <p key={link.id} className='editLink-container-p'> {link.title} <MdDelete  className='editLink-container-delete' onClick={() => handelDelete(link.id)} /></p>
                        ))
                        : 'No data.'
                } 
            </div>
        </RowWithTitle>
    );
};

export default EditLinks;