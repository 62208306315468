import React from 'react';
import './header.scss';
import { FaUserLock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Hero from './Hero';

function Header() {
    return (
        <header id='home' className='homeHeader'>
            <div className='header-link-box'>
                <a href='#contact' className='header-contact-link'>Contact</a>
                <Link to='/login' className='header-contact-login-link'>
                    Log In <FaUserLock />
                </Link>
            </div>
            <Hero />
        </header> 
    );
}

export default Header;