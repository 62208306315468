import React, { useState } from "react";
import { FireStorage } from '../../../../../../../server/firebase-config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function CarImage() {
  const [ image, setImage ] = useState([]);
  const [url, setUrl] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files[0]){
      setImage(event.target.files[0]);
    }
  };
  // console.log('image',image);

  const handleSubmit = () => {
    const imageRef = ref(FireStorage, 'carPartsImages');
    uploadBytes(imageRef, image).then(() => {
      getDownloadURL(imageRef).then((url) => {
        const images = [];
        if (url) {
          images.push(url);
        }
        setUrl(images);
      }).catch((error) => {
        console.log(error.message, 'error  getting the image url');
      });
      setImage(null);
    }).catch((error) => {
      console.log(error.message);
    });

  };


  return(
    <div>
      <img src={url} alt='' style={{ width: '200px', height: '200px' }}/>
      <input  type='file' onChange={handleImageChange} />
      <button onClick={handleSubmit}>Submit</button>
      <hr />
    </div>
  );
}

export default CarImage;