import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from './component/Button';
import './formExamples.scss';



const FormExapmles = () => {
  const [phone, setPhone] = useState('');
  // regex to match numbers between 1 and 10 digits long
  const validPhoneNumber = /^\d{1,10}$/;


  const pageName= 'Form Examples';
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const onChangeUser = (e) => {
    setUser({...user,[e.target.name] : e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(user);
  };


  const handleChange = ({ target })=> {
    const newPhone = target.value;
    const isValid = validPhoneNumber.test(newPhone);
    if (isValid) {
      setPhone(newPhone);
    }
    // just ignore the event, when new value is invalid
   };

   /*
   <Route path={ROUTE.PUBLIC_FORM_EXAMPLES} element={<FormExapmles />} />
            <Route path={ROUTE.PUBLIC_FORM_EXAMPLES_SUBMIT_USER} element={<FormSubmitUser />} />
            
    PUBLIC_FORM_EXAMPLES: '/public/form_examples',
    PUBLIC_FORM_EXAMPLES_SUBMIT_USER: '/public/form_examples_submit_user/:name',
     */

    return (
        <>
        <div className='card-container'>
        <h3 className='form-path-heading'>{pageName}</h3>
          <Link to='/form_examples' className='link-style'>Form Main</Link>
          <Link to='/form_examples_submit_user' className='link-style'>Form Submit User</Link>
          <form onSubmit={onSubmit}>
            <h1>Username is : {user.username}</h1>
            <h1>Password is : {user.password}</h1>
            <input type='text' name='username' value={user.username} onChange={onChangeUser} />
            <input type='password' name='password' value={user.password} onChange={onChangeUser} />
            {/* <button type="submit" redirectpath={`/form_examples_submit_user/${user.username}`}>Submit</button> */}
            <Button redirectPath={`/form_examples_submit_user/${user.username}`} name={'Result page'} />
            
          </form>
        </div>
        <div>
        <div className='phone'>
          <label htmlFor='phone-input'>Phone: {phone}</label>
          <br />
          <input value={phone} onChange={handleChange} id='phone-input' />
        </div>
        </div>
        </>
    );
};

export default FormExapmles;
