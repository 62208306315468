import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSLesson3() {
   
    return (
        <RowWithTitle title='JS Mathematical Assignment Operators'>
            <div className='js-coursesContainer'>
             <div>
                <h3>Mathematical Assignment Operators</h3>
                
                <pre  className='courses-code-container'>
                    let x = 20;
                    <br />
                    x -= 5; // Can be written as x = x - 5
                    <br />
                    console.log(x); // Output: 15
                    <br />
                    
                    let y = 50;
                    <br />
                    y *= 2; // Can be written as y = y * 2
                    <br />
                    console.log(y); // Output: 100
                    <br />
                    
                    let z = 8;
                    <br />
                    z /= 2; // Can be written as z = z / 2
                    <br />
                    console.log(z); // Output: 4
                </pre>
                <hr />

                <pre  className='courses-code-container'>
                    let levelUp = 10;
                    <br />
                    let powerLevel = 9001;
                    <br />
                    let multiplyMe = 32;
                    <br />
                    let quarterMe = 1152;
                    <br />
                
                    levelUp += 5;
                    <br />
                    powerLevel -= 100;
                    <br />
                    multiplyMe *= 11;
                    <br />
                    quarterMe /= 4;
                    <br />

                    console.log(&#39;The value of levelUp:&#39;, levelUp); 
                    <br />
                    console.log(&#39;The value of powerLevel:&#39;, powerLevel); 
                    <br />
                    console.log(&#39;The value of multiplyMe:&#39;, multiplyMe); 
                    <br />
                    console.log(&#39;The value of quarterMe:&#39;, quarterMe);
                </pre>
            
            </div>
            <div>
                <h3>The Increment and Decrement Operator</h3>
                <pre  className='courses-code-container'>
                    let x = 20;
                    <br />
                    x -= 5; // Can be written as x = x - 5
                    <br />
                    console.log(x); // Output: 15
                    <br />
                    
                    let y = 50;
                    <br />
                    y *= 2; // Can be written as y = y * 2
                    <br />
                    console.log(y); // Output: 100
                    <br />
                    
                    let z = 8;
                    <br />
                    z /= 2; // Can be written as z = z / 2
                    <br />
                    console.log(z); // Output: 4
                </pre>
                <hr />
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> Other mathematical assignment operators include the increment operator (++) and decrement operator (--).

                            The increment operator will increase the value of the variable by 1. The decrement operator will decrease the value of the variable by 1</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    let a = 10;
                    <br />
                    a++;
                    <br />
                    console.log(a); // Output: 11
                    <br />
                    let b = 20;
                    <br />
                    b--;
                    <br />
                    console.log(b); // Output: 19
                </pre>
                
            
            </div>

            </div>
        </RowWithTitle>
       
    );
}

export default JSLesson3;