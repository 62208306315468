import React from 'react';
import './button.css';

export class Button extends React.Component {
  render() {
    return (
        <button onClick={this.props.onClick} className='talker-button'>
            Click me!
        </button>
    );
  }
}