import React from 'react';
import './navbarArray.scss';

const NavbarArray = () => {
    const pages = ['Home', 'About', 'Services', 'Contacts'];
    const navLinks = pages.map(page => {
        return (
          <a href={'/public/' + page} className='links' key={page}>
            {page}
          </a>
        );
    });
    
    return (
        <div className='card-container'>
            <h3>Navbar array 1</h3>
            <div className='array1-navigation'>{navLinks}</div>
        </div>
    );
};

export default NavbarArray;