import React, {useState} from 'react';
import './TableStyle.css';
import BuyForm from './BuyForm';
import Transactions from './Transactions';
import CryptoTile from './CryptoTile';

const Table = () => {

    const tiles = [
        { id: 1, name: 'BTC', rate: 3384},
        { id: 2, name: 'ETH', rate: 1352},
        { id: 3, name: 'ADA', rate: 1.98},
    ];

    const [selectedTile, setSelectedState] = useState(tiles[0]);
    const [list, setList] = useState([]);

    const handleSelect = (data) => {
        setSelectedState(data);
    };

    const buildList = (list) => {
        setList(list);
    };

    return(
        <div className='CurrencyConverter-row'>
            <div className='CurrencyConverter-container'>
                <div className='CurrencyConverter-box'>
                    <div className='CurrencyConverter-card'>
                        {tiles.map((coin) => (
                            <CryptoTile 
                                key={coin.id} 
                                data={coin} 
                                onClick={handleSelect}
                                selected={coin.id === selectedTile.id}
                            />
                        ))}
                    </div>
                    <BuyForm data={selectedTile} onPurchase={buildList} />
                </div>
                <div className='CurrencyConverter-box'>
                    <Transactions list={list}/>
                </div>
            </div>
        </div>
        
    );

};

export default Table;
