export const packingListData = [
    {
      name: "Engine",
      price: 1.2
    },
    {
      name: "Gearbox",
      price: 2.0
    },
    {
      name: "Door",
      price: 2.5
    },
    {
        name: "back lampa",
        price: 5.5
    }
  ];