import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSFunctionDeclaration() {
    return (
        <RowWithTitle title='JS Arrow Functions'>
            <div className='js-coursesContainer'>
            <div>
                <h3>Function Declaration</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> In JavaScript, there are many ways to create a function. One way to create a function is by using a function declaration. Just like how a variable declaration binds a value to a variable name, a function declaration binds a function to a name, or an identifier.</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    greetWorld(); // Output: Hello, World!
                    <br />
                    
                    function greetWorld() &#123;
                    <br />
                    console.log(&#39;Hello, World!&#39;)
                    <br />
                    &#125;
                </pre>
                <span>A function declaration consists of:</span>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The function keyword.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> The name of the function, or its identifier, followed by parentheses.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> A function body, or the block of statements required to perform a specific task, enclosed in the function&#39;s curly brackets, { }</li>
                    
                </ul>
                <br />
                <span>A function declaration is a function that is bound to an identifier, or name. In the next exercise we&#39;ll go over how to run the code inside the function body.</span>
                <br />
                <span>We should also be aware of the hoisting feature in JavaScript which allows access to function declarations before they&#39;re defined.</span>
                <br />
                <span>Notice how hoisting allowed greetWorld() to be called before the greetWorld() function was defined! Since hoisting isn&#39;t considered good practice, we simply want you to be aware of this feature.</span>
                <br />
                <span>The code inside a function body runs, or executes, only when the function is called.</span>
            </div>
            </div>
        </RowWithTitle>
        
    );
}

export default JSFunctionDeclaration;