import React from 'react';
import RowWithTitle from '../../../../../components/Dashboard/Content/RowWithTitle';

const ReactUseEffect = () => {
     return (
        <RowWithTitle title='React useEffect'>
            <div>ReactUseEffect</div>
        </RowWithTitle>
    
  );
};

export default ReactUseEffect;