import React from "react";
import LearnJavascript from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript";
import LearnJSArrays from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript/JSArrays";
import LearnJSFunctions from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript/JSFunctions";
import JSLoops from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript/JSLoops";
import JSArrayFilter from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript/JSMimoArrayFilter";
import JSMimoArrayMap from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript/JSMimoArrayMap";
import JSScope from "../pages/authorized/AdminDashboard/LearnJS/Courses/CodeAcademy/LearnJavascript/JSScope";
import ScrimbaUpperCase from "../pages/authorized/AdminDashboard/LearnJS/Courses/Scrimba/UpperCase";
import ScrimbaSplit from "../pages/authorized/AdminDashboard/LearnJS/Courses/Scrimba/Split";


export const JsNavigationData = [
    {
        id: 1,
        title: 'JavaScript. Loops',
        text: 'Codeacademy',
        showComponent: <JSLoops />
    },
    {
        id: 2,
        title: 'JavaScript. Arrays',
        text: 'Codeacademy',
        showComponent: <LearnJSArrays />
    },
    {
        id: 3,
        title: 'JavaScript. Scope',
        text: 'Codeacademy',
        showComponent: <JSScope />
    },
    {
        id: 4,
        title: 'JavaScript. Functions',
        text: 'Codeacademy',
        showComponent: <LearnJSFunctions />
    },
    {
        id: 5,
        title: 'JavaScript. JS ',
        text: 'Codeacademy',
        showComponent: <LearnJavascript />
    },
    {
        id: 6,
        title: 'JavaScript. ArrayName.map() ',
        text: 'Mimo, w3school, .map()',
        showComponent: <JSMimoArrayMap />
    },
    {
        id: 7,
        title: 'JavaScript. ArrayName.filter() ',
        text: 'Mimo, w3school, .filter()',
        showComponent: <JSArrayFilter />
    },
    {
        id: 8,
        title: 'JavaScript. .toUpperCase() ',
        text: 'scrimba.com, .toUpperCase()',
        showComponent: <ScrimbaUpperCase />
    },
    {
        id: 9,
        title: 'JavaScript. .split() .join() ',
        text: 'scrimba.com, .split() .join() .toLowerCase() .slice() .endsWith()',
        showComponent: <ScrimbaSplit />
    },
];