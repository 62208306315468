import React from 'react';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

const JSArrayMap = () => {
    return (
        <RowWithTitle title='JS Array .map()'>
            <div className='js-coursesContainer'>The Array.map() method creates a new array from the results of calling a function for every element.When called on an array, allow us 
                to modify each value  and store it in a new array.
                <span>
                    map() creates a new array from calling a function for every array element.
                    <br />
                    map() calls a function once for each element in an array.
                    <br />
                    map() does not execute the function for empty elements.
                    <br />
                    map() does not change the original array.
                    <br />
                </span>
                <pre  className='courses-code-container'>
                    const numbers = [4, 9, 16, 25];
                    <br />
                    document.getElementById(&#39;demo&#39;).innerHTML = numbers.map(Math.sqrt);
                    <br />
                    Result: 2,3,4,5
                </pre>
                <br />
                <pre  className='courses-code-container'>
                    const persons = [
                        <br />
                        &#123;firstname &#58; &#39;Malcom&#39;, lastname&#58; &#39;Reynolds&#39;&#125;,
                        <br />
                        &#123;firstname &#58; &#39;Kaylee&#39;, lastname&#58; &#39;Frye&#39;&#125;,
                        <br />
                        &#123;firstname &#58; &#39;Jayne&#39;, lastname&#58; &#39;Cobb&#39;&#125;
                        <br />
                    ];
                    <br />
                    document.getElementById(&#39;demo&#39;).innerHTML = persons.map(getFullName);
                    <br />
                    <br />
                        function getFullName(item) &#123;
                        <br />
                            return [item.firstname,item.lastname].join(&#39; &#39;);
                        <br />
                        &#125;
                    <br />
                    <br />
                    Result: Malcom Reynolds,Kaylee Frye,Jayne Cobb
                </pre>
                <br />
                <pre  className='courses-code-container'>
                    const states = [&#39;ak&#39;,&#39;md&#39;, &#39;va&#39;, &#39;ca&#39;, &#39;or&#39;];
                    <br />
                    const uppercaseStates = states.map((element) =&#62; element.toUpperCase());
                    <br />
                    <br />
                    console.log(uppercaseStates)
                    <br />
                    <br />
                    Result: [&#39;AK&#39;,&#39;MD&#39;, &#39;VA&#39;, &#39;CA&#39;, &#39;OR&#39;]
                </pre>
                <br />
                <pre  className='courses-code-container'>
                    const names = [&#39;lisa&#39;,&#39;jim&#39;, &#39;hannah&#39;, &#39;frank&#39;];
                    <br />
                    const nameLength = names.map(function(element) &#123; return element.length&#125;);
                    <br />
                    <br />
                    console.log(nameLength)
                    <br />
                    <br />
                    Result: [4,3,6,5]
                </pre>
                <br />
                <pre  className='courses-code-container'>
                    const drivers = [&#39;LeClerc&#39;,&#39;Sainz&#39;, &#39;Hamilton&#39;];
                    <br />
                    const places = drivers.map(function(element, index) &#123; return index + 1 + &#34;. &#34; + element;&#125; );
                    <br />
                    <br />
                    console.log(places)
                    <br />
                    <br />
                    Result: [&#39;1. LeClerc&#39;, &#39;2. Sainz&#39;, &#39;3. Hamilton&#39;]
                </pre>

            </div>
        </RowWithTitle>
    );
};

export default JSArrayMap;