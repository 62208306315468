import React, { useState } from "react";
import "./navbar.scss";
import { navbarData } from "../../../data/navbarData";

function Navbar() {
    const [activeLink, setActiveLink] = useState(0);

    const handleClick = (id) => {
        setActiveLink(id);
    };


    return (
        <div className='navigation'>
          {
            navbarData.map((link, index) => (
              <a 
                key={index}
                href={link.navUrl} 
                className={activeLink  === index ? 'active-navLink' : 'navLink'}
                onClick={() => handleClick(index)} 
              >
                <span className='icon'>{link.navIcon}</span>
              </a>
            ))
          }
        </div>
    );
}

export default Navbar;