import React from 'react';

const LaravelLinks = () => {
    return (
        <div className='laravel-links-container'>
            <p>
                <a href='https://laravel.com/' target='_blank' rel='noreferrer'>Laravel.com</a>
            </p>
            <p>
                <a href='https://laravel.com/docs/9.x/starter-kits#laravel-breeze' target='_blank' rel='noreferrer'>Laravel Breeze</a>
            </p>
            <p>
                Laravel-react project on Youtube
                <a href='https://www.youtube.com/watch?v=qJq9ZMB2Was' target='_blank' rel='noreferrer'>The Codeholic</a>
            </p>
            <p>
                An elegant wrapper around Webpack
                <a href='https://laravel-mix.com/' target='_blank' rel='noreferrer'>Laravel-mix</a>
            </p>
            <p>
                Laravel + React tutorials on Youtube
                <a href='https://www.youtube.com/@InformaticaDP' target='_blank' rel='noreferrer'>InformaticaDP</a>
                1/2 - Laravel 9 + React , 
                2/2 - Laravel 9 + React
            </p>
            <p>
                <a href='https://laravel.com/docs/9.x/sanctum#main-content' target='_blank' rel='noreferrer'>Laravel Sanctum package</a>
            </p>
            <p>
                <a href='https://getcomposer.org/download/' target='_blank' rel='noreferrer'>Download Composer</a>
            </p>
        </div>
      
    );
};

export default LaravelLinks;