import React from 'react';
import { Link } from 'react-router-dom';
import { ImYoutube2 } from "react-icons/im";
import { MdSchool } from 'react-icons/md';

const PhpTutorialsList = ({ card }) => {
    return (
        <Link to={`/admin/php/${card.id}`} className='card' key={card.id}>
            <div className='left'>
                {
                    card.icon === 'youtube' ? 
                        <ImYoutube2 className='youtube-icon'/> 
                        : <MdSchool className='youtube-icon'/>
                }
            </div>
            <div className='right'>
                <h1>{card.title}</h1>
                <div className='author-date'>
                    <h3>{card.author}</h3>
                    <span>{card.createdAt}</span>
                </div>
            </div>
        </Link>
    );
};

export default PhpTutorialsList;