export const ROUTES = {
    PUBLIC_DEFAULT: '/',
    PUBLIC_LOGIN: 'login',
    PUBLIC_REGISTER: 'register',

    AUTHORIZED_ADMIN_DASHBOARD: 'admin',
    AUTHORIZED_ADMIN_DASHBOARD_MESSAGES: 'messages',
    AUTHORIZED_ADMIN_DASHBOARD_GITHUB: 'github-portfolio',
    AUTHORIZED_ADMIN_DASHBOARD_ENGLISH: 'english',
    AUTHORIZED_ADMIN_DASHBOARD_JAVASCRIPT: 'javascript',
    AUTHORIZED_ADMIN_DASHBOARD_REACT: 'react',
    AUTHORIZED_ADMIN_DASHBOARD_REACT_STUDENTS: 'react-students',
    AUTHORIZED_ADMIN_DASHBOARD_REACT_STUDENT_ID: 'react-student/:id',

    AUTHORIZED_ADMIN_DASHBOARD_BABEL: 'babel',

    AUTHORIZED_ADMIN_DASHBOARD_PHP: 'php',
    AUTHORIZED_ADMIN_DASHBOARD_PHP_TUTORIAL_ID: 'php/:id',

    AUTHORIZED_ADMIN_DASHBOARD_LARAVEL: 'laravel',

    AUTHORIZED_ADMIN_DASHBOARD_USEFUL_LINKS: 'useful-links',
    
    AUTHORIZED_USER_DASHBOARD: 'users',
    AUTHORIZED_USER_ID: ':userId',

    PAGE_NOT_FOUND: '*',
};