import React, { useState } from 'react';
import '../usefulLinks.scss';
import { addDoc, collection } from 'firebase/firestore';
import { FireDB } from '../../../../../server/firebase-config';

const initialState = {
    title: '',
    url: '',
    category: ''
};

const AddLink = () => {
    const [usefulLink, setUsefulLInk] = useState(initialState);
    // const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');


    const handleChange = (event) => {
        const value = event.target.value;
        setUsefulLInk({
            ...usefulLink, [event.target.name] : value
        });
    };

    const linksCollectionRef = collection(FireDB, 'gruminUsefulLinks');

    const handleAddLink = async (event) => {
        event.preventDefault();
        if (usefulLink.title === '' && usefulLink.url === '' && usefulLink.category === ''){
            setError('Empty all input fields !');
        }else if(usefulLink.title === ''){
            setError('Empty title input field !');
        } else if(usefulLink.url === ''){
            setError('Empty url input field !');
        }else if(usefulLink.category === ''){
            setError('Empty category input field !');
        } else {
            const newLink = { title: usefulLink.title, url: usefulLink.url, category: usefulLink.category};
            try{
                await addDoc(linksCollectionRef, newLink);
                setError('');
                setMessage('Link added successfully !');
                setUsefulLInk(initialState);
                
            } catch(err){
                setUsefulLInk(initialState);
                setMessage(err.message);
            }finally{
                setTimeout(() => {
                    setMessage('');
                    setError('');
                }, 2000);
            }
        }
    };


    return (
        <>
            
            <form onSubmit={handleAddLink} className='addLink-form-container' >
               {message && <p className='addLink-form-message'>{message}</p>}
               {error && <p className='addLink-form-error'>{error}</p>}
                <input type='text' name='title' value={usefulLink.title} className='addLink-input' placeholder='Title' onChange={handleChange} />
                <input type='text' name='url' value={usefulLink.url} className='addLink-input' placeholder='Link url' onChange={handleChange} />
                <input type='text' name='category' value={usefulLink.category} className='addLink-input' placeholder='Category' onChange={handleChange} />
                <button type='submit' className='addLink-button'>Add link</button>
            </form>
        </>
             
        
    );
};

export default AddLink;