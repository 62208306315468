export const Students = [
{"id":1,"name":"Ruthie","last_name":"Kingcott","email":"rkingcott0@ebay.com","gender":"Male","age":86,"color":"Aquamarine","city":"Saint Paul"},
{"id":2,"name":"Ardenia","last_name":"Keppe","email":"akeppe1@360.cn","gender":"Non-binary","age":21,"color":"Yellow","city":"Teresina"},
{"id":3,"name":"Jacques","last_name":"Kearle","email":"jkearle2@de.vu","gender":"Polygender","age":57,"color":"Crimson","city":"Ticrapo"},
{"id":4,"name":"Helaine","last_name":"Egre","email":"hegre3@wsj.com","gender":"Genderfluid","age":55,"color":"Aquamarine","city":"Quốc Oai"},
{"id":5,"name":"Viviana","last_name":"Amoss","email":"vamoss4@netvibes.com","gender":"Genderqueer","age":66,"color":"Indigo","city":"Ukhta"},
{"id":6,"name":"Lauralee","last_name":"Oaten","email":"loaten5@si.edu","gender":"Agender","age":34,"color":"Orange","city":"Amirabad"},
{"id":7,"name":"Sheree","last_name":"Celez","email":"scelez6@moonfruit.com","gender":"Genderfluid","age":15,"color":"Turquoise","city":"Cambuí"},
{"id":8,"name":"Guillaume","last_name":"Tabbitt","email":"gtabbitt7@is.gd","gender":"Non-binary","age":10,"color":"Aquamarine","city":"Ribeiro"},
{"id":9,"name":"Gregoor","last_name":"Roswarne","email":"groswarne8@yahoo.com","gender":"Bigender","age":47,"color":"Crimson","city":"Mariental"},
{"id":10,"name":"Viviana","last_name":"De la Eglise","email":"vdelaeglise9@opera.com","gender":"Agender","age":49,"color":"Fuscia","city":"Magong"},
{"id":11,"name":"Betta","last_name":"Perrinchief","email":"bperrinchiefa@dailymotion.com","gender":"Bigender","age":46,"color":"Puce","city":"Diveyevo"},
{"id":12,"name":"Kerwinn","last_name":"Papaccio","email":"kpapacciob@icq.com","gender":"Agender","age":77,"color":"Violet","city":"Suresnes"},
{"id":13,"name":"Giacopo","last_name":"Bricklebank","email":"gbricklebankc@ucoz.ru","gender":"Genderqueer","age":44,"color":"Aquamarine","city":"President Roxas"},
{"id":14,"name":"Amaleta","last_name":"Meddows","email":"ameddowsd@foxnews.com","gender":"Polygender","age":10,"color":"Mauv","city":"Peremyshl’"},
{"id":15,"name":"Eran","last_name":"Blanchet","email":"eblanchete@skyrock.com","gender":"Agender","age":24,"color":"Goldenrod","city":"Lamawalang"},
{"id":16,"name":"Danni","last_name":"Greydon","email":"dgreydonf@spiegel.de","gender":"Female","age":84,"color":"Pink","city":"Paterson"},
{"id":17,"name":"Wendy","last_name":"Saylor","email":"wsaylorg@japanpost.jp","gender":"Non-binary","age":22,"color":"Crimson","city":"Gang"},
{"id":18,"name":"Saunders","last_name":"Roff","email":"sroffh@tiny.cc","gender":"Male","age":88,"color":"Blue","city":"Ad Dimnah"},
{"id":19,"name":"Delcine","last_name":"Daniello","email":"ddanielloi@friendfeed.com","gender":"Male","age":37,"color":"Indigo","city":"Tsyelyakhany"},
{"id":20,"name":"Gan","last_name":"Kmicicki","email":"gkmicickij@fda.gov","gender":"Polygender","age":23,"color":"Blue","city":"Mamu"},
{"id":21,"name":"Vanessa","last_name":"Peabody","email":"vpeabodyk@tripod.com","gender":"Polygender","age":75,"color":"Maroon","city":"Media Luna"},
{"id":22,"name":"Harley","last_name":"Laurencot","email":"hlaurencotl@apache.org","gender":"Genderqueer","age":17,"color":"Puce","city":"San Antonio"},
{"id":23,"name":"Fulton","last_name":"McNamee","email":"fmcnameem@networkadvertising.org","gender":"Genderqueer","age":58,"color":"Maroon","city":"Biscucuy"},
{"id":24,"name":"Claiborne","last_name":"Roscow","email":"croscown@dell.com","gender":"Agender","age":100,"color":"Turquoise","city":"Sävedalen"},
{"id":25,"name":"Abbott","last_name":"Salliss","email":"asallisso@odnoklassniki.ru","gender":"Bigender","age":51,"color":"Goldenrod","city":"Orvault"},
{"id":26,"name":"Hermione","last_name":"Cawthery","email":"hcawtheryp@amazon.de","gender":"Female","age":57,"color":"Teal","city":"Culacling"},
{"id":27,"name":"Johnathon","last_name":"Sherlock","email":"jsherlockq@examiner.com","gender":"Non-binary","age":16,"color":"Pink","city":"Yuanjiazhuang"},
{"id":28,"name":"Nariko","last_name":"Grundell","email":"ngrundellr@wikispaces.com","gender":"Male","age":23,"color":"Yellow","city":"Yankou"},
{"id":29,"name":"Belia","last_name":"Worg","email":"bworgs@huffingtonpost.com","gender":"Non-binary","age":72,"color":"Teal","city":"Krasnomayskiy"},
{"id":30,"name":"Cary","last_name":"Grinval","email":"cgrinvalt@indiatimes.com","gender":"Polygender","age":6,"color":"Fuscia","city":"Guanting"},
{"id":31,"name":"Jo-anne","last_name":"Butland","email":"jbutlandu@wix.com","gender":"Male","age":10,"color":"Pink","city":"Bolinao"},
{"id":32,"name":"Jeri","last_name":"Lukasik","email":"jlukasikv@etsy.com","gender":"Bigender","age":10,"color":"Teal","city":"Rixinhe"},
{"id":33,"name":"Lynn","last_name":"Mateuszczyk","email":"lmateuszczykw@wordpress.com","gender":"Male","age":40,"color":"Red","city":"Fengping"},
{"id":34,"name":"Esdras","last_name":"Volkers","email":"evolkersx@fda.gov","gender":"Non-binary","age":69,"color":"Pink","city":"Campo Maior"},
{"id":35,"name":"Magdaia","last_name":"Shemmin","email":"mshemminy@fc2.com","gender":"Genderfluid","age":96,"color":"Blue","city":"Miguel Hidalgo"},
{"id":36,"name":"Case","last_name":"Dolan","email":"cdolanz@4shared.com","gender":"Genderqueer","age":19,"color":"Orange","city":"Ol’ginskaya"},
{"id":37,"name":"Jo","last_name":"Westmacott","email":"jwestmacott10@mysql.com","gender":"Non-binary","age":86,"color":"Pink","city":"Mazeikiai"},
{"id":38,"name":"Keely","last_name":"Radage","email":"kradage11@comsenz.com","gender":"Genderfluid","age":97,"color":"Indigo","city":"Drachten"},
{"id":39,"name":"Diena","last_name":"Oganesian","email":"doganesian12@virginia.edu","gender":"Agender","age":67,"color":"Blue","city":"Walnut Grove"},
{"id":40,"name":"Lorena","last_name":"Pridding","email":"lpridding13@cloudflare.com","gender":"Non-binary","age":90,"color":"Indigo","city":"Sumbergedong"},
{"id":41,"name":"Broddy","last_name":"Fallowes","email":"bfallowes14@examiner.com","gender":"Agender","age":56,"color":"Turquoise","city":"Pavlivka"},
{"id":42,"name":"Harriett","last_name":"Claiton","email":"hclaiton15@php.net","gender":"Bigender","age":21,"color":"Blue","city":"Yongfeng"},
{"id":43,"name":"Patrice","last_name":"Godmer","email":"pgodmer16@comcast.net","gender":"Bigender","age":57,"color":"Fuscia","city":"Paris La Défense"},
{"id":44,"name":"Orly","last_name":"Drei","email":"odrei17@foxnews.com","gender":"Genderfluid","age":47,"color":"Blue","city":"New Haven"},
{"id":45,"name":"Iago","last_name":"Shurey","email":"ishurey18@thetimes.co.uk","gender":"Polygender","age":89,"color":"Orange","city":"Nizhniy Tsasuchey"},
{"id":46,"name":"Jaclyn","last_name":"Lightning","email":"jlightning19@51.la","gender":"Non-binary","age":72,"color":"Pink","city":"Paris 15"},
{"id":47,"name":"August","last_name":"Gentile","email":"agentile1a@dagondesign.com","gender":"Female","age":54,"color":"Goldenrod","city":"Dapeng"},
{"id":48,"name":"Crissie","last_name":"Rojas","email":"crojas1b@ucsd.edu","gender":"Bigender","age":70,"color":"Turquoise","city":"Vale Covo"},
{"id":49,"name":"Kathryn","last_name":"Tutt","email":"ktutt1c@marketwatch.com","gender":"Male","age":87,"color":"Orange","city":"Lasiana"},
{"id":50,"name":"Valentina","last_name":"Triplow","email":"vtriplow1d@google.it","gender":"Non-binary","age":74,"color":"Maroon","city":"Boca Raton"}
];