import React, { useEffect, useState } from 'react';
import { JsNavigationData } from '../../../../../data/JsNavigationData';
import '../learnJs.scss';

function JSCourses() {
    const [data ,setData] = useState([]);
    const [showTutorials , setShowTutorials] = useState(0);

    const toggle = (id) => {
        setShowTutorials(id);
    };

    useEffect(() => {
        setData(JsNavigationData);
    }, []);


    return (
        <div className='js-navigation-container'>
             <div className='js-navigation-cards-box'>
                {
                    JsNavigationData.map((card) => (
                        <div 
                            key={card.id} 
                            className='js-navigation-card'
                            onClick={() => toggle(card.id)}
                        >
                            <h3 className='js-navigation-card-h3'>{card.title}</h3>
                            <span className='js-navigation-card-span'>{card.text}</span>
                        </div>
                        
                    ))
                }
            </div>

            {
                showTutorials ? (data[showTutorials - 1].showComponent)  : <p className='js-navigation-p'>Please select tutorial...</p>
            }
           
        </div>
         
    );
}

export default JSCourses;