import React from 'react';
import './hero.scss';
import  { GiPaperPlane, GiCubeforce } from 'react-icons/gi';
import ParticleBackground from '../../../ParticleBg';

function Hero() {
    return (
        <div className='homeHero-container'>
            <div className='homeHero-top-box'>
                <h2 className='home-hero-h2'>
                    <GiCubeforce className='home-hero-h2-icon'/>Grumin
                </h2>
                <h3 className='home-hero-h3'>A Designer and Developer Making the Web a Better Place.</h3>
            </div>
            <div className='homeHero-logo'>
                <GiPaperPlane className='homeHero-logo-icon'/>
                <div className='circle-particle-container'>
                    <ParticleBackground />
                </div>
            </div>
        </div>
    );
}

export default Hero;