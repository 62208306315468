import React from 'react';
import JSLesson1 from './JSLesson1';
import JSLesson2 from './JSLesson2';
import JSLesson3 from './JSLesson3';
import JSLesson4 from './JSLesson4';
import JSLesson5 from './JSLesson5';

function LearnJavascript() {
    return (
        <div>
            <div>
                <JSLesson1 />
            </div>
            <div>
                <JSLesson2 />
            </div>
            <div>
                <JSLesson3 />
            </div>
            <div>
                <JSLesson4 />
            </div>
            <div>
                <JSLesson5 />
            </div>
        </div>
    );
}

export default LearnJavascript;