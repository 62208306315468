import React from 'react';
import { AttentionGrabber } from './AttentionGrabber';
import Clock from './Clock';
import ExamplesPart1 from './ExamplesPart1';
import { GuineaPigs } from './GuineaPigs';
import { styles } from './styles';

const divStyle = {
    background: styles.background,
    minHeight: '100px'
};

function UdemyTutorials() {
    return (
       <>
        <Clock />
            <div style={divStyle}>
                UdemyTutorials
            </div>
            <AttentionGrabber />
            <GuineaPigs />
            <ExamplesPart1 />
       </> 
    );
}

export default UdemyTutorials;