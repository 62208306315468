import React, { useState } from 'react';
import {FireDB} from '../../../../../../../server/firebase-config';
import { addDoc, collection } from 'firebase/firestore';
import { packingListData } from './packingListData';

function CheckboxPackingList() {
  const [allNames, setAllNames] = useState([]);
  const [checkedState, setCheckedState] = useState(
    new Array(packingListData.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
  );
  // console.log(updatedCheckedState)
  setCheckedState(updatedCheckedState);

  let myArray = [];
  

  const parts = updatedCheckedState.reduce(
      (name, currentState, index) => {
        if (currentState === true) {
          myArray.push(packingListData[index].name);
          return myArray;
        }
        return 'please check box grumin';
      },
      ''
    );
    setAllNames(parts);
  };

///////////////////////////////////
const handleOnSubmit = async (e, myArray) => {
  e.preventDefault();
  const devCollectionRef = collection(FireDB, 'newtext');
  console.log(myArray);
  console.log(allNames);
  const newDataObj = {allNames} ;
        try{
             await addDoc(devCollectionRef, newDataObj);
        } catch(err) {
            alert(err);
            console.log(err);
        }
    console.log('text send  ok');

};

    return (
        <div style={{ backgroundColor: 'var(--color-pri-rgba)' }}>
            <div>
      <div className='App'>
      <h1>Car parts: </h1>
        <form onSubmit={handleOnSubmit}>
                <ul className='toppings-list'>
                    {packingListData.map(({ name }, index) => {
                    return (
                        <li key={index}>
                          <div className='toppings-list-item'>
                            <div className='left-section'>
                              <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                              <input
                                  type='checkbox'
                                  id={`custom-checkbox-${index}`}
                                  name={name}
                                  value={name}
                                  checked={checkedState[index]}
                                  onChange={() => handleOnChange(index)}
                              />
                            </div>
                          </div>
                        </li>
                    );
                    })}
                </ul>
              <button type='submit'>sent</button>
          </form>
        </div>
    </div>
           
        </div>
    );
}

export default CheckboxPackingList;
