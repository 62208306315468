import React from 'react';
import '../studentsList.scss';

const StudentsHeader = ({ color }) => {
    return (
        <header style={{ backgroundColor: color}} className='students-header'>
            Students Header
        </header>
    );
};

export default StudentsHeader;
