import React, { useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import './login.scss';
import ParticlesBg from 'particles-bg';
import { useUserContext } from '../../../context/UserAuthContext';
import { protectedUser } from '../../../server/adminData';


const Login = () => {
    const emailRef = useRef();
    const psdRef = useRef();
    const { user, signInUser, error } = useUserContext();
  
    const handleLogin = (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        const password = psdRef.current.value;
        if (email && password) signInUser(email, password);
    };

    return (
        <section className='login-container'>
            {error && <p style={{ backgroundColor: 'red' }}>{error}</p>}
            {user ? (user.email === protectedUser ? <Navigate to='/admin' /> : <Navigate to='/users' />) : (
                <form onSubmit={handleLogin}  className='login-form'>
                    <ParticlesBg
                        type='cobweb'
                        num={5}
                        color='#52BCFA'
                    /> 
                    <ul className='login-card'>
                        <li>
                            <input placeholder='Email' type='email' ref={emailRef} className='login-input' required autoComplete='true'/>
                        </li>
                        <li>
                            <input placeholder='Password' type='password' ref={psdRef} className='login-input' required autoComplete='true'/>
                        </li>
                        <li>
                            <button type='submit' className='login-button'>Log In</button>
                        </li>
                        <li>
                            <Link to='/register' className='login-link'>Create an account</Link>
                        </li>
                        <li>
                            <Link to='/' className='login-link'>Home page</Link>
                        </li>
                    </ul>
                </form>
             )}
        </section>
    );
};

export default Login;