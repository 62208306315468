import React from "react";
import { 
   FaHtml5, 
   FaCss3Alt, 
   FaPhp, 
   FaFigma, 
   FaWordpressSimple, 
   FaReact,
   FaSass
} from 'react-icons/fa';
import { 
   SiJavascript, 
   SiBootstrap, 
   SiJquery, 
   SiTypescript, 
   SiEslint, 
   SiFirebase, 
   SiLaravel,
   SiPrestashop
} from 'react-icons/si';
import { AiOutlineConsoleSql } from 'react-icons/ai';

export const EducationData = [
      {
         id: 5,
         icons: [
         {
            codeIcon: <SiLaravel />
         },
         {
            codeIcon: <SiPrestashop />
         },
         {
            codeIcon: <FaReact />
         },
        
         ] ,
         title: 'Practice Task',
         date: '2022 ',
      },
    {
       id: 4,
       icons: [
         {
            codeIcon: <FaReact />
         },
         {
            codeIcon: <SiFirebase />
         },
         {
            codeIcon: <SiTypescript />
         },
         {
            codeIcon: <SiEslint />
         },
         {
            codeIcon: <SiJavascript />
         },
       ] ,
       title: 'Present Connection',
       date: '2021-2022 ',
    },
    {
       id: 3,
       icons: [
         {
            codeIcon: <FaHtml5 />
         },
         {
            codeIcon: <FaCss3Alt />
         },
         {
            codeIcon: <FaFigma />
         },
         {
            codeIcon: <SiBootstrap />
         },
         {
            codeIcon: <SiJavascript />
         },
         {
            codeIcon: <SiJquery />
         },
         {
            codeIcon: <FaPhp />
         },
         {
            codeIcon: <AiOutlineConsoleSql />
         },
         {
            codeIcon: <FaWordpressSimple />
         },

       ] ,
       title: 'Vilnius Coding School',
       date: '2021 & 2022',
    },
    {
       id: 2,
       icons: [
         {
            codeIcon: <FaSass />
         },
         {
            codeIcon: <SiJavascript />
         }
       ] ,
       title: 'codecademy.com',
       date: '2021',
    },
    {
       id: 1,
       icons: [
         {
            codeIcon: <FaHtml5 />
         },
         {
            codeIcon: <FaCss3Alt />
         },
         {
            codeIcon: <FaPhp />
         },
      ] ,
       title: 'w3school.com',
       date: '2020',
    },
    
];