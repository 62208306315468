import React from 'react';
import CarImage from './CarImage';
import CarImagesCrud from './CarImagesCrud';
import FirebaseAlbums from './FirebaseAlbums';
import ImagesFirebaseFileUpload from './ImagesFirebase';
import ImageSlider from './ImageSlider';
import ImageCarousel from './ImageCarousel';

const ImagesExamples = () => {
    return (
        <div style={{ backgroundColor: 'gray' }}>
            <ImageCarousel />
            <p>Add foto , and update this foto</p>
            <CarImage />
            <p>Image Slider</p>
            <ImageSlider />
            <p>Firebase Albums</p>
            <FirebaseAlbums />
            <p>Images Firebase</p>
            <ImagesFirebaseFileUpload />
            <p>Car Images Crud</p>
            <CarImagesCrud />
        </div>
    );
};

export default ImagesExamples;