import React, { useEffect, useState } from 'react';
// import './searchWord.scss';
import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { AiOutlineSound, AiOutlineDelete } from 'react-icons/ai';
import { FireDB } from '../../../../../../server/firebase-config';

const SearchSentence = () => {
    const [sentences, setSentences] = useState([]);
    const [search, setSearch] = useState('');
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if(clicked === index) {
            //if clicked question is already active, then close it
            return setClicked(null);
        }
        setClicked(index);
    };
    const sentencesCollectionRef = collection(FireDB, 'gruminEnglishSentences');

    useEffect(() => {
       
        const fetchSentencesCollection = query(sentencesCollectionRef, orderBy('date', 'desc'));
        const onsub = onSnapshot(fetchSentencesCollection, (snapshot) => 
            setSentences(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        );
        return onsub;
    }, []);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const filteredSentences = sentences.filter((sentence) => 
        sentence.engSentence.toLowerCase().includes(search.toLowerCase()) 
    );

    const handleDelete = async (id) => {
        const sentencesDoc = doc(sentencesCollectionRef, id);
        await deleteDoc(sentencesDoc);
    };

    return (
        <>
            <form className='searchWord-form-container'>
                <input type='text' placeholder='Enter word ...' className='searchWord-input' onChange={handleSearch} />
            </form>
            {
                       // [...filteredWords].reverse().map((word, index) => {
                    filteredSentences.map((sentence, index) => {
                        return(
                            <div key={index}  onClick={() => toggle(index)} className='words-style-box'>
                                <div className='words-style-div'>
                                   {clicked === index ? <FiMinus /> : <FiPlus />}
                                    <span className='words-style-span'>{sentence.sentenceId}.</span>
                                    <h3 className='words-style-h3'>{sentence.engSentence}</h3>
                                </div>
                                {clicked === index ? (
                                    <div className='word-LT'>
                                        <p>{sentence.ltSentence}</p>
                                        <a href={sentence.urlAddress} className='word-LT-sound'  target='_blank' rel='noreferrer'><AiOutlineSound /></a>
                                        <button  className='word-delete-button' onClick={() => handleDelete(sentence.id)}><AiOutlineDelete /></button>
                                    </div>
                                ) : null }
                                
                            </div>
                        );
                    })
                }
        </>
    );
};

export default SearchSentence;