import React from 'react';
import { useNavigate } from 'react-router-dom';

const StudentsButton = ({ redirectPath, name, backgroundColor }) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(redirectPath, { replace: true });
        // console.log('redirectPath :', redirectPath);
    };

    return (
        <button style={{ backgroundColor }} onClick={ onClick }>
            {name}
        </button>
    );
};

export default StudentsButton;
