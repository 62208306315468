import React, { useState } from 'react';
import './reactExamples.scss';
import RowWithTitle from '../../../../../components/Dashboard/Content/RowWithTitle';
import { ReactExamplesData } from '../../../../../data/reactExamplesData';

const ReactExamples = () => {
    const [clicked, setClicked] = useState(false);

    const toggle = (id) => {
        if(clicked === id){
            //if clicked question is already active, then close it
            return setClicked(null);
        }
        setClicked(id);

    };
    
    return (
        <>
            <RowWithTitle title='React Examples'>
                <div className='reactExamples-accordion-container' >
                    {
                        ReactExamplesData.map((item) => {
                            return(
                                <div key={item.id} className='reactExamples-accordion'>
                                    <div onClick={() => toggle(item.id)} className='reactExamples-accordion-wrap'>
                                        <h3>{item.title}</h3>
                                        <span>{clicked === item.id ? '-' : '+'}</span>
                                    </div>
                                        {
                                            clicked === item.id ? (
                                                <div className='reactExamples-accordion-dropdown'>
                                                    <p className='reactExamples-accordion-dropdown-p'>{item.description}</p>
                                                  {
                                                    item.content.map((component, index) => {
                                                        return(
                                                            <div key={index}>
                                                                {component.example}
                                                            </div>
                                                        );
                                                    })
                                                }
                                                </div>
                                            ) : null
                                        }
                                </div>
                            );
                        })
                    }
                </div>
            </RowWithTitle>
            <RowWithTitle title='Scrimba tutorial about React from simple html'>
                <p>Very good lessons</p>
                https://scrimba.com/learn/learnreact/first-react-coc0845dcb1a26cb0769a2fea\
                https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/<br />
                https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/
            </RowWithTitle>
            <RowWithTitle title='Images on my server'>
                <p>Placeholder: http://grumin.lt/web-images/placeholder.jpg</p>
                <p>Mountain: http://grumin.lt/web-images/img-1.jpg</p>
                <p>Water: http://grumin.lt/web-images/img-2.jpg</p>
                <p>See: http://grumin.lt/web-images/img-3.jpg</p>
                <p>Stadion: http://grumin.lt/web-images/img-4.jpg</p>
                <p>Car: http://grumin.lt/web-images/img-5.jpg</p>
                <p>Street: http://grumin.lt/web-images/img-6.jpg</p>
                <p>Evning: http://grumin.lt/web-images/img-7.jpg</p>
                <p>Sahara: http://grumin.lt/web-images/img-8.jpg</p>
                <p>Waterfall: http://grumin.lt/web-images/img-9.jpg</p>
                <p>CarServis: http://grumin.lt/web-images/IMG-c659e75fa42d7e34aca741d7e6bcd1b9-V.jpg</p>
                
            </RowWithTitle>
        </>
       
    );
};

export default ReactExamples;