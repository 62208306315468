import React from 'react';
import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import Content from '../../../../components/Dashboard/Content';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import ReactNavigation from './ReactNavigation';

const LearnReact = () => {
    const { user, loading, logoutUser } = useUserContext();

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <Content path='react'>
                                    <ReactNavigation />
                                </Content>
                            </Dashboard>
                        )
            }
        </>
    );
};

export default LearnReact;