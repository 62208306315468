import React from 'react';
// import Card from '../../../components/Card';
// import MySlider from './components/MySlider';
import Slider from './components/Slider';
import { SliderData } from './components/SliderData';


function ImageSlider() {
   
    return (
        <div style={{ backgroundColor: 'blue' }}>
            <h4>Brian Design</h4>
            <h5>Build a React Image Slider Carousel from Scratch Tutorial</h5>
            <Slider slides={SliderData} />

            {/* <MySlider /> */}
        </div>
    );
}

export default ImageSlider;