import React, { useEffect, useState } from 'react';
import Cars from './Cars';
import { ItemsData } from './ItemsData';

function Ex2() {
    const [clicked, setClicked] = useState(false);
    const [car, setCar] = useState('cars');
    const [model, setModel] = useState('');
    // const [CarIndex, setCarIndex] = useState(null);
    const [test, setTest] = useState('apple');
    const [fruits, setFruits] =useState('');
  
    const toggle = (index) => {
        if(clicked === index) {
            return(
                setClicked(null)
            );
        }
        setClicked(index);
    };
   
    console.log(test);

    const handleChange = (title) => {
        setTest('orange');
        setFruits(title);
    };

    useEffect(() => {
        handleChange(fruits);
    }, [fruits]);

 
    return (
        <>
            <div>
            <ul>
               
                {/* <li>car: {test} <button onClick={handleChange}>change</button></li> */}
                <li>fruit: {fruits} </li>
                <li> 
                    <h2>Dropdown car : {car}</h2>
                    <select value={car} onChange={((event) => setCar(event.target.value))}>
                        {
                            CarsData.map((car,CarIndex) => {
                                return(
                                   
                                        <option value= {car.title} key={CarIndex} onClick={() => toggle(CarIndex)}>
                                            {car.title}
                                              {/* {clicked ? handleChange(car.title) : 'err'} */}
                                        </option>
                                      
                                       
                                            
                                );
                            })
                        }
                    </select>      
                </li>  
                <li>
                
                </li>    
            </ul>


            {/* <select value={car} onChange={((event) => setCar(event.target.value))}>
                {CarsData.map((car, index) => {
                    return (
                        <>
                        <option value= {car.title} key={index} onClick={() => toggle(index)}>
                          {car.title}
                        </option>
                        {clicked === index ? (
                            <div>
                                {car.content.map((model) => (
                                    <div key={model}>
                                        <p >
                                            {model.text}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ) : null }
                       
                        </>
                        
                    )
                })}
            </select> */}
           
            <h2>Dropdown model : {model}</h2>
            <select value={model} onChange={((event) => setModel(event.target.value))}>
                <option value='a6'>a6</option>
                <option value='q7'>q7</option>
                <option value='a1'>a1</option>
            </select>

            </div>
            <div>
                
           

                {Data.map((item, index) => {
                    return (
                        <li key={index} >
                            <div>
                               
                                <div 
                                    onClick={() => toggle(index)} 
                                    key={index}
                                >
                                    <span>{item.number}</span>
                                    <h1>{item.title}</h1>
                                </div>
                            </div>
                                {clicked === index ? (
                                    <div>
                                        {item.content.map((ans) => (
                                            <div key={ans.toString()}>
                                                <p >
                                                    {ans.text}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null }
                        </li>
                    );
                })}
               
            
            </div>
            <div>
            <Cars cars={ItemsData} />
            </div>
        </>
    );
}

export default Ex2;

export const Data = [
    {
        number: '1',
        title: 'Varikliai',
        content: [
            {
                text: 'Dyzelinis',
                email: ''
            },
            {
                text:'Benzininis',
                email: ''
            },
            {
                text: 'Dujos',
                email: ''
            }
        ]
    },
    {
        number: '2',
        title: 'Pavarų dėžė',
        content: [
           { 
                text: 'Automatinė'
            },
            { 
                text: 'Mechaninė'
            }
        ]
    },
    {
        number: '3',
        title: 'Durys',
        content: [
            { 
                text: 'Kairė priekis'
            },
            { 
                text: 'Dešinė priekis'
            }
        ]
    },
    {
        number: '4',
        title: 'Kapotas',
        content: [
            { 
                text: 'Su Grotelėm'
            },
            { 
                text: 'Be grotelių'
            }
        ]
    }
];

export const CarsData = [
    {
        number: '1',
        title: 'Audi',
        content: [
            {
                text: 'A1'
            },
            {
                text:'A4'
            },
            {
                text: 'Q7'
            }
        ]
    },
    {
        number: '2',
        title: 'Bmw',
        content: [
           { 
                text: 'x3'
            },
            { 
                text: 'x7'
            }
        ]
    },
    {
        number: '3',
        title: 'Opel',
        content: [
            { 
                text: 'Omega'
            },
            { 
                text: 'Insignia'
            }
        ]
    },
    {
        number: '4',
        title: 'Vw',
        content: [
            { 
                text: 'Passat'
            },
            { 
                text: 'Caddy'
            },
            { 
                text: 'Touran'
            }
        ]
    }
];

