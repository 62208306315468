import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';


 /* nuo simboliu kiekio deliojasi ir qr kodo dydis /> */
const  text = 'Labas , mokinuotis react.';

const QrCode = () => {
    const [src, setSrc] = useState('');

    useEffect(() => {
        QRCode.toDataURL(text).then((data) => {
            setSrc(data);
        });

        // or
        //QRCode.toDataURL(text).then((setSrc));
      
    }, []);

    return (
        <div>
            <h3>Qr code</h3>
            <img src={src} alt='qr codas' />
        </div>
    );
};

export default QrCode;