export const questions = [
    {
      id: 123,
      prompt: "What two things can you never eat for breakfast?",
      answer: "Lunch and dinner"
    },
    {
      id: 124,
      prompt: "What word is spelled incorrectly in every single dictionary?",
      answer: "Incorrectly"
    },
    {
      id: 125,
      prompt:
        "What do you answer every day, even though it never asks you a question?",
      answer: "Your phone"
    },
    {
      id: 126,
      prompt:
        "What starts with “e” and ends with “e” but only has one letter in it?",
      answer: "An envelope."
    },
    {
      id: 127,
      prompt: "What has a face and two hands, but no arms or legs?",
      answer: "A clock"
    }
  ];
  
  // Shout out to [Thought Catalog](https://thoughtcatalog.com/january-nelson/2018/03/65-riddles-for-kids-guaranteed-to-stump-you-too/) for these important questions :)