import React from 'react';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';
import './scrimba.scss';

const ScrimbaUpperCase = () => {

  //example B
  const nextMsg = 'Good morning!';
  function text(str){
    return str.toUpperCase();
  }

  //first example
  const msg = 'Hello world';


  return (
    <div>
      <RowWithTitle title='JS function .toUpperCase'>
        <div className='scrimba-container'>
          <pre>
            const nextMsg = &#39;Good morning!&#39;;
            <br />
            function text&#40;str&#41;&#123;
            <br />
            &nbsp;return str.toUpperCase();
            <br />
            &#125; 
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; text(nextMsg) &#125; 
              <br />
              &#41;
          </h4>
          <p>{text(nextMsg)}</p>
        </div>
      </RowWithTitle>
      <RowWithTitle title='JS .toUpperCase()'>
        <div className='scrimba-container'>
          <pre>
            const msg = &#39;Hello world&#39;;
          </pre>
          <h4>
              return &#40; 
              <br />
              &nbsp; &#123; msg.toUpperCase&#40; &#41; &#125; 
              <br />
              &#41;
          </h4>
          <p>{msg.toUpperCase()}</p>
        </div>
      </RowWithTitle>

    </div>
  );
};

export default ScrimbaUpperCase;

