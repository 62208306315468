import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { BsGithub, BsEye, BsArrowDownCircle} from "react-icons/bs";
import { SiCoursera } from "react-icons/si";

export const navbarData = [
    {
        navId: 1,
        navUrl: '#home',
        navIcon:  <AiOutlineHome />,
    },
    {
        navId: 2,
        navUrl: '#gitportfolio',
        navIcon:  <BsGithub />,
    },
    {
        navId: 3,
        navUrl: '#education',
        navIcon:  <BsEye />,
    },
    {
        navId: 4,
        navUrl: '#contact',
        navIcon:   <SiCoursera />,
    },
    {
        navId: 5,
        navUrl: '#footer',
        navIcon:  <BsArrowDownCircle />,
    },
];