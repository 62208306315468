import React, { useState } from 'react';
// import { SliderData } from './SliderData';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import '../imageSlider.scss';

function Slider({ slides }) {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1 );
  };
 

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1 );
  };

  // console.log(current);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  return (
    <div>
      <div className='slider'>
        <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
        <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
        
        {
          slides.map((foto, index) => {
            return (
              <div className={index === current ? 'slide active' : 'slide'} key={index} >
                {index === current && (<img src={foto.images} alt='travel image' className='slider-image' />)}
              </div>
            );
        
          })
        }

      </div>
        
    </div>
  );
}

export default Slider;