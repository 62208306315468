import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './reactPagination.scss';

const ReactPagination = () => {
    const [crypto, setCrypto] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&order=market_cap_desc&per_page=1000&page=1&sparkline=false');
            const data = await response.json();
            setCrypto(data.slice(0, 100000));
        };
        fetchData();
    }, []);

    const cryptoPerPage = 10; // how many Crypto per page
    const pagesVisited = pageNumber * cryptoPerPage; //

    const displayCrypto = crypto
        .slice(pagesVisited, pagesVisited + cryptoPerPage)
        .map((crypto) => {
        return(
            <div key={crypto.id}>
                <span style={{ display: 'block', width: '200px' }}>{crypto.name}</span> price: {crypto.current_price}
                <hr />
            </div>
        ); 
    });

    const pageCount = Math.ceil(crypto.length / cryptoPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);

    };

    return (
        <div className='ReactPagination-container'>
            <p>PedroTech : ReactJS Pagination Tutorial using React Hooks</p>
            <p>npm install react-paginate</p>
            <p>import ReactPaginate from &#39;react-paginate&#39;;</p>

            <div className='ReactPagination-crypto-box'>
                {displayCrypto}
                <ReactPaginate 
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={'reactpaginationBttns'}
                    previousLinkClassName={'reactpreviousBttn'}
                    nextLinkClassName={'reactnextBttn'}
                    disabledClassName={'reactpaginationDisabled'}
                    activeClassName={'reactpaginationActive'}
                />
            </div>
        </div>
    );
};

export default ReactPagination;