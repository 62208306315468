import React from 'react';
import CarPartsList from './CarPartsList';
import CheckboxPackingList from './CheckboxPackingList';
import CheckBoxPrice from './CheckBoxPrice';
import Checkboxes2 from './MoreCheckboxes';
import MultipleCheckboxes from './MultipleCheckboxes';
import SubmitCheckbox from './SubmitCheckbox';

const Checkboxes = () => {
    return (
        <div>
            <p>Submit form with select and checbox</p>
            <SubmitCheckbox /> 
            <p>Checkbox Packing List</p>
            <CheckboxPackingList />
            <p>Multiple checkboxes</p>
            <MultipleCheckboxes />
            <p>CheckBox Count Price</p>
            <CheckBoxPrice />
            <p>More examples</p>
            <Checkboxes2 />
            <p>carPartsList</p>
            <CarPartsList />
        </div>
    );
};

export default Checkboxes;