import React from 'react';
import { useNavigate } from 'react-router-dom';

const Button = ({ redirectPath, name }) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(redirectPath, { replace: true});
    };
    return (
        <button onClick={onClick}>
            {name}
        </button>
    );
};

export default Button;
