import React, { useEffect, useState } from 'react';
import './clock.scss';

const Clock = () => {
    const [clock, setClock] = useState();

    function addZero(i) {
        if (i < 10) {i = "0" + i;}
        return i;
    }

    const date = new Date();
    const hours = addZero(date.getHours());
    const minutes = addZero(date.getMinutes());
    const seconds = addZero(date.getSeconds());
    const currentTime = `${hours}:${minutes}:${seconds}`;
 
    useEffect(() => {
        setTimeout(() => {
                setClock(currentTime);
        }, 1000);
    });

    return (
        <section className='clock-section'>
            <p>Current time: {clock}</p>
        </section>
    );
};

export default Clock;
