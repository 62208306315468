import React from 'react';
import './engLessonB.scss';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';
import { EnglishLessonIrregularVerbs } from '../../../../../../data/englishLessonIrregularVerbs';

const EngLessonB = () => {
    return (
        <>
        <RowWithTitle title='Irregular verbs'>
            <ul className='EngLessonB-Irregular-verbs-container'>
                <li >
                    <span>Infinitive</span>
                    <span>Past simple</span>
                    <span>Past participle</span>
                </li>
                {
                    EnglishLessonIrregularVerbs.map((item => (
                        <li key={item.id}>
                            <p>{item.infinitiveWord} <span>{item.infinitivePronunciation}</span></p>
                            <p>{item.pastSimpleWord} <span>{item.pastSimplePronunciation}</span></p>
                            <p>{item.pastParticipleWord} <span>{item.pastParticiplePronunciation}</span></p>
                        </li>
                    )))
                }
            </ul>

            
        </RowWithTitle>
        </>
    );
};

export default EngLessonB;