import React, { useState } from 'react';
// import Button from './components/Button';
import './grocery-list.scss';

const GroceryFormInput = () => {
    const [user,setUser] = useState({ name: ''});
    // console.log(user.name);

    const handleInput = (event) => {
        const inputValue = event.target.value;
        setUser({
            name: inputValue
        });
    };
    /*
    {
        HEADING: 'Grossery  List',
        PATH: '/public/grocery_list',
        DESCRIPTION: 'form , input, useParams, useNavigate, utton component, redirectPath'
    },

     // PUBLIC_GROCERY_LIST: '/public/grocery_list',
    // PUBLIC_GROCERY_LIST_PROFILE_PAGE: '/public/grocery_list_profile_page/:name',

       <Route path={ROUTE.PUBLIC_GROCERY_LIST} element={<GroceryFormInput />} />
            <Route path={ROUTE.PUBLIC_GROCERY_LIST_PROFILE_PAGE} element={<ProfilePage />} />
    */

    return (
        <div className='grocery-list-container'>
            <h3>GroceryForm</h3>
            <hr />
            <h2>Hello, {user.name}</h2>
            <input type='text' placeholder='Enter your name' onChange={handleInput} value={user.name}/>
            <p>Your entry is : {user.name}</p>
            {/* <Button redirectPath={`/public/grocery_list_profile_page/${user.name}`} buttonName={'Profile'} /> */}
        </div>
    );
};

export default GroceryFormInput;
