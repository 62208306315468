import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "@firebase/firestore";
import { getDatabase } from 'firebase/database';
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD1ER0I93Zky1D5uXuvcCjgzYibnBQrZYA",
  authDomain: "grumin-lt-app.firebaseapp.com",
  projectId: "grumin-lt-app",
  storageBucket: "grumin-lt-app.appspot.com",
  messagingSenderId: "347525778724",
  appId: "1:347525778724:web:77a086efae2c9c1eb6b7b1",
  databaseURL: "https://grumin-lt-app-default-rtdb.europe-west1.firebasedatabase.app/"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const FireDB = getFirestore(app);
const FireRTDB = getDatabase(app);
const FireStorage = getStorage(app);
export { auth, FireDB, FireRTDB, FireStorage};