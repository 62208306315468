import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CryptoRow, CrytoContainer, ConverterBox, ConverterForm, FormLabel, FormInput, FormBtn, FormSelect } from './CryptoConverterStyle';
import Coin from './Coin';

const CryptoConverter = () => {
    const [coins, setCoins] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        axios
        .get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&order=market_cap_desc&per_page=1000&page=1&sparkline=false')
        .then(res => {
            setCoins(res.data);
        }).catch(error => console.log(error));
    }, []);

    const handleChange = e => {
        setSearch(e.target.value);
    };

    const filteredCoins = coins.filter(coin =>
        coin.name.toLowerCase().includes(search.toLowerCase())
    );

    return (

        <CryptoRow>
            <CrytoContainer>
                <ConverterBox>
                    <ConverterForm>
                        <FormLabel>Cryto list</FormLabel>
                            {coins && (
                                <FormSelect name='coin' id='coin'>
                                    {coins.map((coins, index) => (
                                        <option key={index} value={coins.symbol}>{coins.symbol} : {coins.current_price} € </option>
                                    ))}
                                </FormSelect>
                            )}
                        <FormLabel>
                            1 € = Coin
                            {coins && (
                                    <ul>
                                        {coins.map((coins, index) => (
                                            <li key={index}>{coins.symbol} : {1 / coins.current_price} € </li>
                                        ))}
                                    </ul>
                                )}
                        </FormLabel>
                        <FormInput />
                        <FormBtn>Submit</FormBtn>
                    </ConverterForm>
                </ConverterBox>
                <ConverterBox>
                    <div className='coin-App'>
                        <div className= 'coin-search'>
                            <h1 className='coin-text'>Kripto valiutų paieška</h1>
                            <form>
                                <input type='text' placeholder='Search' className='coin-input' onChange={handleChange} />
                            </form>
                        </div>
                        {filteredCoins.map(coin => {
                            return(
                            <Coin 
                            key={coin.id}
                            name={coin.name} 
                            image={coin.image} 
                            symbol={coin.symbol}
                            marketcap={coin.market_cap}
                            price={coin.current_price}
                            priceChange={coin.price_change_percentage_24h}
                            volume={coin.total_volume}
                            />
                            );
                        })}
                    </div>
                </ConverterBox>
            </CrytoContainer>
        </CryptoRow>
    );
};

export default CryptoConverter;
