import React from 'react';
import ParticlesBg from 'particles-bg';
import './particleBg.scss';


function ParticleBackground() {
    return (
        <div className='particle-container'>
            <ParticlesBg 
                type='cobweb'
                num={15}
                color='#52BCFA'
            /> 
        </div>
    );
}

export default ParticleBackground;