import React from 'react';

class UsersArray extends React.Component {
    // Constructor 
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            DataisLoaded: false
        };
    }
   
    // ComponentDidMount is used to
    // execute the code 
    componentDidMount() {
        fetch(
            "https://jsonplaceholder.typicode.com/users")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                });
            });
    }
    render() {
        const { DataisLoaded, items } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
   
        return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            width: '100%'
        }}>
            <h1 style={{  width: '100%' }}> Fetch data from an api in react </h1> 
            <h2 style={{  width: '100%' }}> https:// jsonplaceholder.typicode.com/users </h2> 
            {
                items.map((item) => ( 
                <ol key = { item.id } >
                    User_Name: { item.username }, 
                    Full_Name: { item.name }, 
                    User_Email: { item.email },
                    User_Address: { item.address.geo.lat } 
                </ol>
                ))
            }
        </div>
    );
}
}
   
export default UsersArray;