import React from 'react';

export function Hero (props) {
  return (
      <>
      <div className='hero'>
        <h2>
         {props.brand}
        </h2>
    </div>
      </>
  );
  
}
