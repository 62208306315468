import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSLesson4() {
    let myPet = 'armadillo';

    // my age
    const myAge = 23;
    // early Years
    let earlyYears  = 2;
    earlyYears *= 10.5;
    // later Years
    let laterYears = myAge / 2;
    //multiply 
    laterYears *- 4;
    console.log(earlyYears + ' ' + laterYears );
    //Add  
    const myAgeInDogYears = earlyYears + laterYears;
    //string method
    const myName = 'Grumin'.toLowerCase();
    console.log(`My name is ${myName}. I am ${myAge} years old in human years which is ${myAgeInDogYears} years old in dog years.`);
    
    let text = `My name is ${myName}. I am ${myAge} years old in human years which is ${myAgeInDogYears} years old in dog years.`;
    return (
        <RowWithTitle title='JS String Concatenation with Variables'>
            <div className='js-coursesContainer'>
            <div>
                <h3>String Concatenation with Variables</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The + operator can be used to combine two string values even if those values are being stored in variables</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    let myPet = &#39;armadillo&#39;;
                    <br />
                    console.log(&#39;I own a pet &#39; + myPet + &#39;.&#39;); 
                    <br />
                    Output: &#39;I own a pet armadillo.&#39;
                </pre>
                <div className='courses-show-task'>
                    <MdIcons.MdOutlineQuestionAnswer/> : {myPet}
                    <br />
                    <MdIcons.MdOutlineQuestionAnswer/> : {'I own a pet ' + myPet + '.'}
                </div>
            </div>
            <hr />
            <div>
                <h3>String Interpolation</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> In the ES6 version of JavaScript, we can insert, or interpolate, variables into strings using template literals. Check out the following example where a template literal is used to log strings together</li>
                    <li><MdIcons.MdOutlineTaskAlt/> a template literal is wrapped by backticks ` (this key is usually located on the top of your keyboard, left of the 1 key).</li>
                    <li><MdIcons.MdOutlineTaskAlt/> Inside the template literal, you&#39;ll see a placeholder, ${myPet}. The value of myPet is inserted into the template literal.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> When we interpolate `I own a pet ${myPet}.`, the output we print is the string: &#39;I own a pet armadillo.&#39;</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    const myPet = &#39;armadillo&#39;;
                    <br />
                    console.log(`I own a pet ${myPet}.`);
                    <br />
                    Output: I own a pet armadillo.
                </pre>
                <div className='courses-show-task'>
                    <MdIcons.MdOutlineQuestionAnswer/> : {myPet}
                    <br />
                    <MdIcons.MdOutlineQuestionAnswer/> : {'I own a pet ' + myPet + '.'}
                </div>
            </div>
            <div>
                <h3>typeof operator</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> The typeof operator checks the value to its right and returns, or passes back, a string of the data type.</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    const unknown1 = &#39;foo&#39;;
                    <br />
                    console.log(typeof unknown1); // Output: string
                    <br />
                    
                    const unknown2 = 10;
                    <br />
                    console.log(typeof unknown2); // Output: number
                    <br />
                    
                    const unknown3 = true; 
                    <br />
                    console.log(typeof unknown3); // Output: boolean
                </pre>
                <hr />
                <pre  className='courses-code-container'>
                    let newVariable = &#39;Playing around with typeof.&#39;;
                    <br />

                    console.log(typeof newVariable);
                    <br />
                    newVariable = 1;
                    <br />
                    console.log(typeof newVariable);
                </pre>
                <pre  className='courses-code-container'>
               
                    const myAge = 23;  // my age
                    <br />
                    
                    let earlyYears  = 2;// early Years
                    <br />
                    earlyYears *= 10.5;
                    <br />
                   
                    let laterYears = myAge / 2; // later Years
                    <br />
                   
                    laterYears *- 4; //multiply 
                    <br />
                    console.log(earlyYears + &#39; &#39; + laterYears );
                    <br />
                   
                    const myAgeInDogYears = earlyYears + laterYears; //Add  
                    <br />
                   
                    const myName = &#39;Grumin&#39;.toLowerCase(); //string method
                    <br />
                    console.log(`My name is ${myName}. I am ${myAge} years old in human years which is ${myAgeInDogYears} years old in dog years.`)
                   
                </pre>
                <div className='courses-show-task'>
                    <MdIcons.MdOutlineQuestionAnswer/> : {text}
                </div>
               
            </div>

            </div>
        </RowWithTitle>
        
    );
}

export default JSLesson4;