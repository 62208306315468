import React from 'react';
import './content.scss';

const Content = ({ children, path }) => {
    return (
        <div className='dashboardContent-Container'>
            <h4 className='dashboardContent-path'>.. / {path}</h4>
            <div className='dashboardContent'>
            {children}
            </div>
        </div>
    );
};

export default Content;