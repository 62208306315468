import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
import { BsMouse, BsEnvelope } from "react-icons/bs";
import { RiCopyrightLine } from "react-icons/ri";
import { FiLinkedin, FiGithub } from 'react-icons/fi';
import { FaUserLock, FaSolarPanel } from 'react-icons/fa';

function Footer() {
    const date = new Date().getFullYear();
    return (
        <div id='footer' className='footer-container'>
            <div className='footer-btn-to-top'>
                <a href='#home'>
                    <BsMouse /> - scroll up -
                </a>
            </div>
            <div className='social-links'>
                <span >Grumin <RiCopyrightLine className='' /> {date}</span>
                <div>
                    <a href='https://www.linkedin.com/in/mindaugas-grusas/' target='blank'>
                        <FiLinkedin className='social' />
                    </a>
                    <a href='mailto: grumin.el.pastas@gmail.com' target='blank'>
                    <BsEnvelope className='social' />
                    </a>
                    <a href='https://github.com/grum1n' target='blank'>
                    <FiGithub className='social' />
                    </a>
                    <Link to='/login' >
                        <FaUserLock className='social' />
                    </Link>
                    <a href='https://transventasolar.grumin.lt' target='blank'>
                    <FaSolarPanel className='social' />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;