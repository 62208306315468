import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowsSize';
import './dashboard.scss';
import DashboardHeader from './Header';
import DashboardSidebar from './Sidebar';
import { protectedUser }  from '../../server/adminData';

const Dashboard = ({ children, user, logoutUser }) => {
    const [showSidebar, setShowSidebar] = useState(true);
    const [mobile, setMobile] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const { width } = useWindowSize();

    const navigate = useNavigate();

    useEffect(() => {
        if (user.email === protectedUser){
            return setIsAdmin(true);
        } else{
            return setIsAdmin(false);
        }
    },[user]);

    useEffect(() => {
        if(width < 750){
            setShowSidebar(false);
            setMobile(true);
        } else{
            setShowSidebar(true);
            setMobile(false);
        }
    }, [width]);

    const handleLogout = async () => {
        try {
            await logoutUser();
            navigate('/login');
        } catch (error) {
            alert(error);
        }
    };
    
    return (
        <div className='dashboard'>
            <div className='bg-circle1'></div>
            <div className='bg-circle2'></div>
            <DashboardHeader  
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                user={user}
                handleLogout={handleLogout}
                isAdmin={isAdmin}
            />
            <div className='content'>
                {
                    showSidebar && <DashboardSidebar isAdmin={isAdmin}/>
                }

                <main className={mobile ? 'mainContainerFullwidth' : (showSidebar ? 'mainContainer ' : 'mainContainerFullwidth' )}>
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Dashboard;