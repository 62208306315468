import React from "react";
import { 
    MdDashboard, 
    MdOutlineGroup, 
    MdLink, 
    // MdSettings, 
    // MdOutlineSettingsSystemDaydream, 
    MdLanguage ,
    MdOutlineEmail
} from 'react-icons/md';

import { 
    FaReact, 
    // FaPhp,
    // FaWordpressSimple 
} from 'react-icons/fa';
import { 
    // SiTypescript, 
    // SiLaravel, 
    // SiPrestashop, 
    SiJavascript,
    SiBabel,
    SiLaravel,
    SiPhp
} from 'react-icons/si';
import { BsGithub } from "react-icons/bs";

export const ADMIN_SIDEBAR_DATA = [
    {
        id: 1,
        title: '',
        links: [
            {
                url: '/admin',
                icon: <MdDashboard className='icon' />,
                linkDesc: 'DASHBOARD',
            }
        ],
    },
    {
        id: 2,
        title: 'LEARN CODING',
        links: [
            {
                url: '/admin/javascript',
                icon: <SiJavascript className='icon' />,
                linkDesc: 'JavaScript',
            },
            {
                url: '/admin/react',
                icon: <FaReact className='icon' />,
                linkDesc: 'React',
            },
            {
                url: '/admin/babel',
                icon: <SiBabel className='icon' />,
                linkDesc: 'Babel',
            },
             {
                url: '/admin/php',
                icon: <SiPhp className='icon' />,
                linkDesc: 'PHP',
            },
             {
                url: '/admin/laravel',
                icon: <SiLaravel className='icon' />,
                linkDesc: 'Laravel',
            },

            // {
            //     url: '/admin',
            //     icon: <SiTypescript className='icon' />,
            //     linkDesc: 'TypeScript',
            // },
           
            // {
            //     url: '/admin',
            //     icon: <SiPrestashop className='icon' />,
            //     linkDesc: 'PrestaShop',
            // },
            // {
            //     url: '/admin',
            //     icon: <FaWordpressSimple className='icon' />,
            //     linkDesc: 'Wordpress',
            // },
        ],
    },
    {
        id: 3,
        title: 'LEARN LANGUAGE',
        links: [
            {
                url: '/admin/english',
                icon: <MdLanguage className='icon' />,
                linkDesc: 'English',
            }
        ],
    },
    {
        id: 3,
        title: 'LIST',
        links: [
            {
                url: '/admin',
                icon: <MdOutlineGroup className='icon' />,
                linkDesc: 'Users',
            }
        ],
    },
    {
        id: 4,
        title: 'GITHUB',
        links: [
            {
                url: '/admin/github-portfolio',
                icon: <BsGithub className='icon' />,
                linkDesc: 'Repositories',
            }
        ],
    },
    {
        id: 5,
        title: 'MESSAGES',
        links: [
            {
                url: '/admin/messages',
                icon: <MdOutlineEmail className='icon' />,
                linkDesc: 'From Contact Form',
            }
        ],
    },
    {
        id: 6,
        title: 'LINKS',
        links: [
            {
                url: '/admin/useful-links',
                icon: <MdLink className='icon' />,
                linkDesc: 'Useful links',
            }
        ],
    },
    // {
    //     id: 7,
    //     title: 'SERVICES',
    //     links: [
    //         {
    //             url: '/admin',
    //             icon: <MdSettings className='icon' />,
    //             linkDesc: 'Settings',
    //         },
    //         {
    //             url: '/admin',
    //             icon: <MdOutlineSettingsSystemDaydream className='icon' />,
    //             linkDesc: 'System Health',
    //         },
    //     ],
    // },
    
];

export const USERS_SIDEBAR_DATA = [
    {
        id: 1,
        title: '',
        links: [
            {
                url: '/users',
                icon: <MdDashboard className='icon' />,
                linkDesc: 'DASHBOARD',
            },
        ],
    },
    {
        id: 2,
        title: 'LINKS',
        links: [
            {
                url: '/users',
                icon: <MdLink className='icon' />,
                linkDesc: 'Test link',
            },
        ],
    }
];