import React from 'react';

function Item(props) {
    return (
        <li>
            {props.carName}
        </li>
    );
}

export default Item;
