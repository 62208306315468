import React from 'react';
import TeamImg from '../../../../../../../../images/team.jpg';

const TeamImage = ({ size }) => {
    return (
        <>
         <img src={TeamImg} style={{ width: size }} alt='team'/>
        </>
    );
};

export default TeamImage;
