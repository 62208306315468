import React from 'react';
import Table from './Table';

const CurrencyConverter = () => {
    return (
        <>
            <Table />
        </>
    );
};

export default CurrencyConverter;
