import React, { useState } from 'react';
// import AddCar from './AddCar';
import { FireStorage } from '../../../../../../../server/firebase-config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import CarsList from './CarsList';

function CarImagesCrud() {
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);

  const handleChange = e => {
    for(let i = 0; i < e.target.files.length; i++){
      const newImage = e.target.files[i];
      newImage['id'] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  const handleUpload = () => {
    const promises = [];
    images.map((image) => {
      const storageRef = ref(FireStorage, `imagesXXX/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      promises.push(uploadTask);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        }, 
        (error) => {
          console.log(error); 
        }, 
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
            // console.log('File available at', urls);
            setUrls((prevState) => [...prevState, urls]);
          });
        }
      );
    });

    Promise.all(promises)
      .then(() => alert('All images uploaded.'))
      .catch((err) => console.log(err));
  };

  // console.log('images:' , images);
  // console.log('urls:' , urls);

  return (
    <div style={{ backgroundColor: 'var(--color-pri-rgba)' }}>
      <progress value={progress} max='100' />
      <br />
      <input type='file' multiple onChange={handleChange} />
      <button onClick={handleUpload}>Upload</button>
      <br />
      {
        urls.map((url, i) => (
          <div key={i}>
            <a href={url} target='blank'>
              {url}
            </a>
         </div>
        ))
      }
      <br />
      {
        urls.map((url, i) => (
          <img  
            style={{ width: '200px' }}
            key={i} 
            src={url || 'http://via.placeholder.com/200'} 
            alt='firebase'/>
        ))
      }
      {/* <AddCar  carImagesUrl={urls} /> */}
      <hr />
      {/* <CarsList /> */}
    </div>
  );
}

export default CarImagesCrud;