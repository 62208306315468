import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import Chevron from '../../../../LearnReact/ReactExamples/Accordion2/Chevron';
import './edukusAccordion.scss';
import LTengWords from './LTeng';

const EdukusAccordion = (props) => {
    const [setActive, setActiveState] = useState('');
    const [setHeight, setHeightState] = useState('0px');
    const [setRotate, setRotateState] = useState('0px');
    // const [contentToggle, setContentToggle] = useState(true);
    // const [test, setTest] = useState('');

    const [words, setWords] = useState([]);

    const [ltWord, setLtWord] = useState('');
    const [engWord, setEngWord] = useState('');

    const [showAddForm, setShowAddForm] = useState(false);
    const [ltENG, setLTENG] = useState(false);
    const [engLT, setENGLT] = useState(false);
    const [lineColor, setLineColor] = useState('green');

  
 

    const contentHeight = useRef(null);

    const collectionRef = collection(props.FireDB, `gruminEdukusEnglish/${props.id}/words`);

    useEffect(()=> {
        const fethWordsData = query(collectionRef, orderBy('timestamp', 'desc'));
        const onsub = onSnapshot(fethWordsData, (snapshot) => {
            setWords(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data()})));
        });
        return onsub;
    },[]);

    const toggleAccordion = () => {
        setActiveState(setActive === '' ? 'active' : '');
        setHeightState(setActive === 'active' ? '0px' : `${contentHeight.current.scrollHeight}px`);
        setRotateState(setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
    };

    const handleChange = (event) => {
        if( event.target.name !== 'ENGword'){
            setLtWord(event.target.value);
        } else {
            setEngWord(event.target.value);
        }
    };


    const AddNewWord = async (event) => {
        event.preventDefault();
        const words = {ltWord, engWord, lineColor, timestamp: serverTimestamp()};
        await addDoc(collectionRef, words);
        setLtWord('');
        setEngWord('');
    };

    const handleShowForm = (text) => {
        if (text === 'addWord'){
            setShowAddForm(true);
            setLTENG(false);
            setENGLT(false);
        } else if (text === 'engLT') {
            setShowAddForm(false);
            setLTENG(false);
            setENGLT(true);
        } else {
            setShowAddForm(false); 
            setENGLT(false);
            setLTENG(true);
        }

    };


    return (
        <div className='accordion__section'>
            <button className={`accordionX ${setActive}`} onClick={toggleAccordion}>
                <p className='accordion__title'>{props.tutorialTitle}</p>
                <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
            </button>
            <div ref={contentHeight} style={{ maxHeight: `${setHeight}` }} className='accordion__content'>
                <iframe src={props.videoLink} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
                
                <ul>
                    <li>
                        <button onClick={() => handleShowForm('ltENG')}>LT - ENG</button>
                    </li>
                    <li>
                        <button onClick={() => handleShowForm('engLT')}>ENG - LT</button>
                    </li>
                    <li>
                        <button onClick={() => handleShowForm('addWord')}>Add word</button>
                    </li>
                </ul>
                {
                    showAddForm && 
                    <div>
                        <form className='wordForm'  onSubmit={AddNewWord}>
                            <input type='text' name='LTword' placeholder='LT word' value={ltWord} onChange={handleChange}/>
                            <input type='text' name='ENGword' placeholder='ENG word' value={engWord}  onChange={handleChange}/>
                            
                            <button type='submit' >add words</button>
                        </form> 
                        {
                            words.length > 0 ?
                                words.map((word) => (
                                    <div key={word.id}>
                                        {word.ltWord} -{word.engWord}
                                    </div>
                                )) : 'No data.'
                        } 
                    </div>      
                }
                {
                    ltENG && 
                        words.length > 0 ?
                            words.map((word) => (
                                <div key={word.id}>
                                    <LTengWords 
                                        wordId={word.id}
                                        ltWord={word.ltWord}
                                        engWord={word.engWord}
                                        lineColor={word.lineColor}
                                        setLineColor={setLineColor}
                                    />
                                </div>
                            )) : 'No data.'
                }

                {
                    engLT && 
                        words.length > 0 ?
                            words.map((word) => (
                                <div key={word.id} style={{ height: '100%' }}>
                                    {word.engWord} - {word.ltWord}
                                </div>
                            )) : 'No data.'
                }
                     
            </div>
        </div>
    );
};

export default EdukusAccordion;