
// Meta klidas 

// import React from 'react';

// export default class GreetingsComponent extends React.Component {
//     render() {
//         if (this.props.signedIn === false) {
//           return <h1>GO AWAY</h1>;
//         } else {
//           return <h1>Hi there, {this.props.name}!</h1>;
//         }
//     }
// }

import React from 'react';

const GreetingsComponent = (props) => {
    const name = props.name;
    return (
        <div>
            Hi,  {name}
        </div>
    );
};

export default GreetingsComponent;
