import React from 'react';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';

const EngLessonA = () => {
    return (
        <>
            <RowWithTitle title='Learn - possesion'>
                <ul style={{ listStyleType: 'none'}}>
                    <li>
                        My / singular 1 - mano
                    </li>
                    <li>
                        Your / singular 1 - tavo
                    </li>
                    <li>
                        His / singular 1
                    </li>
                    <li>
                        Her / singular 1
                    </li>
                    <li>
                        Its / singular 1
                    </li>
                    <li>
                        Our / plural 2+
                    </li>
                    <li>
                        Your / plural 2+
                    </li>
                    <li>
                        Their / plural 2+
                    </li>
                </ul>
            </RowWithTitle>
            <RowWithTitle title='Learn - to be'>
                <ul style={{ listStyleType: 'none'}}>
                    <li>
                        I am  / I`m 
                    </li>
                    <li>
                        You are / You`re
                    </li>
                    <li>
                        He is / He`s
                    </li>
                    <li>
                        She is / She`s
                    </li>
                    <li>
                        It is / It`s
                    </li>
                    <li>
                        We are / We`re
                    </li>
                    <li>
                        You are / You`re
                    </li>
                    <li>
                        They are / They`re
                    </li>
                </ul>
            </RowWithTitle>
            <RowWithTitle title='Learn English with Edukus 2'>
                <ul style={{ listStyleType: 'none'}}>
                    <li>https://www.youtube.com/watch?v=ZDzfiqsUVA0</li>
                    <li>I do - Aš darau</li>
                    <li>I am doing - Aš darau (dabar)</li>
                    <li>Am I doing ? - Ar aš darau ?</li>
                    <li>You are doing - tu darai(dabar)</li>
                    <li>It is reining - lietus lyja.</li>
                    <li>It is - tai yra</li>
                    <li>This - tai (šalia-šitas)</li>
                    <li>That - tai (toliau-tas/anas)</li>
                    <li>This is my car (šalia) - Tai yra mano mašina (šalia)</li>
                    <li>That is your car - Ten tavo mašina.(toliau)</li>
                    <li>Is that your car? - Ar ten tavo mašina ?</li>
                    <li>Is that my car ? - Ar ten mano mašina ?</li>
                    <li>Is this your car ? - Ar šita tavo mašina ?</li>
                    <li>These - šitie</li>
                    <li>These people are - šitie žmonės yra</li>
                    <li>These people are my friends - Šitie žmonės yra mano draugai</li>
                    <li>Those - tie (daugiskaita)</li>
                    <li>Those people - tie žmonės</li>
                    <li>Those people are not my friends - tie žmonės nėra mano draugai</li>
                    <li>Are these people your friends? - ar tie žmonės tavo draugai ?</li>
                    <li>This/ that / it is my car - Tai mano mašina</li>
                </ul>
            </RowWithTitle>
            <RowWithTitle title='Learn English with Edukus 1'>
                <ul style={{ listStyleType: 'none'}}>
                    <li>https://www.youtube.com/watch?v=pRDFPpnII4k&t=55s</li>
                    <li>to be - būti</li>
                    <li>or - ar</li>
                    <li>to be or not to be // būti ar nebūti</li>
                    <li>I am - aš esu</li>
                    <li>happy - laimingas</li>
                    <li>I am happy - aš esu laimingas</li>
                    <li>I am - yra teiginys</li>
                    <li>am I ? - ar aš ? // yra klausimas, susikeičia vietomis</li>
                    <li>Am I happy ? - Ar aš esu laimingas ? </li>
                    <li>very - labai</li>
                    <li>I am very happy - aš esu labai laimingas</li>
                    <li>Am I very happy ? -  ar aš esu laimingas?</li>
                </ul>
                <hr />
                <ul style={{ listStyleType: 'none'}}>
                    <li>You - tu, Jūs // nėra mandagios kreipimo formos</li>
                    <li>You are - tu esi // teiginys</li>
                    <li>You are very happy - tu esi labai laimingas</li>
                    <li>are you ? - ar tu esi ? // klausimas , susikeiia vietomis.</li>
                    <li>are you very happy ? - ar tu esi labai laimingas? </li>
                    <li>smart - protingas, sumanus</li>
                    <li>You are very smart - tu esi labai protingas</li>
                    <li>Are you very smart ?  - ar tu esi labai protingas?</li>
                    <li>he / she / it  is - jis / ji / tai yra // it naudijamas negyviems daiktams</li>
                    <li>he is very smart - jis yra labai protingas</li>
                    <li>she is very smart - ji yra labai protinga</li>
                    <li>I am not happy - aš nesu laimingas. // not rašomas po veiksmažodžio</li>
                    <li>You are not happy - tu nesi laimingas.</li>
                    <li>He is not happy - jis nėra laimingas</li>
                    <li>It is - tai yra</li>
                    <li>Here - čia</li>
                    <li>It is here - tai yra čia</li>
                    <li>It is not here - tai nėra čia.</li>
                    <li>Is it here ? - ar tai čia ?</li>
                    <li>She is here - ji yra čia.</li>
                    <li>She is not here - Ji nėra čia, (jos nėra čia)</li>
                    <li>Is it here ? - ar tai čia ?</li>
                    <li>He is not here - jis nėra čia</li>
                    <li>Is he here ? - ar jis (yra) čia ?</li>
                    <li>But  - bet</li>
                    <li>She is not here, but i am here - Jos nėra čia , bet aą esu čia</li>
                    <li>I am not here , but she is here - manęs nėra čia (aš nesu čia), bet ji yra čia</li>
                    <li>there - ten</li>
                    <li>are you here? - ar tu (esi) ten ?</li>
                    <li>you are not here - tu nesi ten</li>
                    <li>I am not here but he is - aš nesu ten , bet jis yra.</li>
                    <li>She is not here, but she is there - ji nėra čia , bet ji yra ten</li>
                    <li>She is happy here - ji yra laiminga ten</li>
                    <li>She is not happy there - </li>
                    <li>She is smart, but she is not happy - ji yra protinga , bet ji nėra laiminga</li>
                    <li></li>
                  
                </ul>
            </RowWithTitle>
        </>
        
        
    );
};

export default EngLessonA;