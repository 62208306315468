import React from 'react';

const LaravelStart = () => {
    return (
        <div className='laravel-start-container'>
            <p>Laravel simple project can load through FTP to Hostinger. Paste files to public_html. Works OK.</p>
            <p>PowerShell: vim .env  // komanda per powershell ieina i faila ir paspaudus i galima redaguoti, po to esc uzdaro redagavima , ir :wq issaugo faila ir uzdaro.</p>
            <p>laravel-react-hostinger-app pavyzdys</p>
            <p>ucarparts.net online.</p>
            <p>reikia projekta ikelti i github</p>
            <p>failus ikelti per ssh</p>
            <p>instaliuoti composer naujausia</p>
            <p>per powershell prisijungimas paprastas.</p>
            <p>kai ikelti per powershell git repozitorija reikia github username, ir pwd: bus token</p>
            <p>sukurti api folderi per ssh powershell</p>
            <p>react turi tureti .env faila kuris nurodo api linka</p>
            <p>2:28 diegimas i hostigeri</p>
            <p>https://www.youtube.com/watch?v=qJq9ZMB2Was</p>
        </div>
    );
};

export default LaravelStart;