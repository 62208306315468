import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSFunctionParAndArg() {
    return (
        <RowWithTitle title='JS Parameters and Arguments'>
            <div className='js-coursesContainer'>
            <div>
              
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/>  some functions can take inputs and use the inputs to perform a task. When declaring a function, we can specify its parameters. Parameters allow functions to accept input(s) and perform a task using the input(s)</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    function calculateArea(width, height) &#123;
                    <br />
                    console.log(&#39;width *  height&#39;)
                    <br />
                    &#125;
                </pre>
                <span>In the diagram above, calculateArea(), computes the area of a rectangle, based on two inputs, width and height. The parameters are specified between the parenthesis as width and height, and inside the function body, they act just like regular variables. width and height act as placeholders for values that will be multiplied together</span>
                <span>When calling a function that has parameters, we specify the values in the parentheses that follow the function name. The values that are passed to the function when it is called are called arguments. Arguments can be passed to the function as values or variables.</span>
                <pre  className='courses-code-container'>
                   calculateArea(10, 6);
                </pre>
                <span>The variables rectWidth and rectHeight are initialized with the values for the height and width of a rectangle before being used in the function call.</span>
                <pre  className='courses-code-container'>
                    const rectWidth = 10;
                    const rectHeight = 6;
                    <br />
                    calculateArea(rectWidth, rectHeight);
                </pre>
                <pre  className='courses-code-container'>
                    function sayThanks(name) &#123;
                        <br />
                    console.log(&#39;Thank you for your purchase &#39;+ name + &#39;! We appreciate your business.&#39;);
                        <br />
                    &#125;
                         <br />
                    sayThanks(&#39;Cole&#39;);
                </pre>
            </div>
            </div>
        </RowWithTitle>
        
    );
}

export default JSFunctionParAndArg;