import React, { useState } from 'react';

function TestB() {
    const [units,setUnits] = useState('');
    const [partWeight, setPartWeight] = useState('');
    const [ result, setResult] = useState(0);

    const [price, setPrice] = useState('');
    const [newPrice, setNewPrice] = useState('');

    const [kurs, setKurs] = useState('');
    const [newSekKurs,setNewSekKurs] = useState(0);

    const [partPrice, setPartPrice] = useState(0);
    const [partPriceEur, setPartPriceEur] = useState(0);

    const [newUnits, setNewUnits] = useState('');
    const [newPartWeight, setNewPartWeight] = useState(0);

    const [text, setText] = useState(result);

    const handleCount = () => {
        let newNumber = parseInt(units);
        let newWeight = parseInt(partWeight);
        let newTotalPrice = parseInt(price);
        let newKurs = parseFloat(kurs);
        let countKurs = newTotalPrice / newKurs;
        let findPartPrice = newTotalPrice / newNumber;
        let findPartPriceEur = countKurs / newNumber;
        if(newNumber > 0 && newWeight > 0 && newTotalPrice > 0 && newKurs > 0){
            let newResult = newNumber * newWeight;
            setNewUnits(newNumber);
            setNewPartWeight(newWeight);
            setResult(newResult);
            setNewPrice(newTotalPrice);
            setPartPrice(findPartPrice);
            setNewSekKurs(countKurs.toFixed(3));
            setPartPriceEur(findPartPriceEur.toFixed(3));
            setUnits('');
            setPartWeight('');
            setText(newResult);
            setPrice('');
        } else {
            setText('UZPILDYKITE VISUS LAIKELIUS !');
        }

        
    };
    const handleReset = () => {
        setUnits('');
        setPartWeight('');
        setResult(0);
        setNewUnits(0);
        setNewPartWeight(0);
        setPrice(0);
        setPartPrice(0);
    };
  

    return (
        <div>
            <form>
                <label>vnt</label>
                <br />
                <input type='number' value={units} placeholder='Enter number' onChange={(event) => setUnits(event.target.value)} required/>
                <br />
                <br />
                <label>svoris</label>
                <br />
                <input type='number' value={partWeight} placeholder='Enter weight' onChange={(event) => setPartWeight(event.target.value)} required/>
                <br />
                <br />
                <label>Total price sek</label>
                <br />
                <input type='number' value={price} placeholder='Enter price' onChange={(event) => setPrice(event.target.value)} required/>
                <br />
                <br />
                <label>Euro / sek kurs</label>
                <br />
                <input type='number' value={kurs} placeholder='Enter kurs' onChange={(event) => setKurs(event.target.value)} required/>
                <br />
                <br />
                <button onClick={handleCount}>Add and count</button>
                <button onClick={handleReset}>Reset</button>

            </form>
           
           <h1>result: {text} kg, keitimo kursas {kurs}</h1>
           <ul>
                <li>
                    <ul style={{ display: 'flex', border: '1px solid red' }}>
                        <li style={{ margin: '5px 10px',width: '50px' }}>title</li>
                        <li style={{ margin: '5px 10px',width: '50px' ,borderLeft: '1px solid red' ,textAlign:'right' }}>vnt</li>
                        <li style={{ margin: '5px 10px',width: '70px' ,borderLeft: '1px solid red'  }}>svoris kg</li>
                        <li style={{ margin: '5px 10px',width: '50px'  ,borderLeft: '1px solid red'}}>svoris Total kg</li>
                        <li style={{ margin: '5px 10px',width: '50px' ,borderLeft: '1px solid red'  }}>Total Price sek</li>
                        <li style={{ margin: '5px 10px',width: '50px' ,borderLeft: '1px solid red'  }}>Part Price sek</li>
                        <li style={{ margin: '5px 10px',width: '50px' ,borderLeft: '1px solid red'  }}>Total Price EUR</li>
                        <li style={{ margin: '5px 10px',width: '50px' ,borderLeft: '1px solid red'  }}>Part Price EUR</li>
                    </ul>
                </li>
                <li>
                    <ul style={{ display: 'flex' }}>
                        <li style={{ margin: '5px 10px',width: '50px'  }}>title</li>
                        <li style={{ margin: '5px 10px',width: '50px' ,borderLeft: '1px solid red'  }}>{newUnits || 0}</li>
                        <li style={{ margin: '5px 10px',width: '70px' ,borderLeft: '1px solid red'  }}>{newPartWeight || 0}</li>
                        <li style={{ margin: '5px 10px' ,width: '50px' ,borderLeft: '1px solid red'}}>{result}</li>
                        <li style={{ margin: '5px 10px' ,width: '50px' ,borderLeft: '1px solid red' }}>{newPrice || 0}</li>
                        <li style={{ margin: '5px 10px' ,width: '50px' ,borderLeft: '1px solid red' }}>{partPrice || 0}</li>
                        <li style={{ margin: '5px 10px' ,width: '50px' ,borderLeft: '1px solid red' }}>{newSekKurs || 0}</li>
                        <li style={{ margin: '5px 10px' ,width: '50px' ,borderLeft: '1px solid red' }}>{partPriceEur || 0}</li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}

export default TestB;