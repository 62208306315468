import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';
import { FireDB } from '../../../../../../server/firebase-config';
import AddSentence from './AddSentence';
import SearchSentence from './SearchSentence';

const EngSentences = () => {
    const [sentence, setSentence] = useState(0);
    const [changeForm, setChangeForm] = useState(false);

    const toggle = () => {
        setChangeForm(!changeForm);
    };

    const sentenceCollectionRe = collection(FireDB, 'gruminEnglishSentences');

    useEffect(() => {
        const fetchSentencesData = query(sentenceCollectionRe, orderBy('date', 'asc'));

        const showSentencesArray = onSnapshot(fetchSentencesData, (snapshot) => {
            setSentence(snapshot.docs.map((doc) => ({ ... doc.data(), id: doc.id})));
        });
        return showSentencesArray;
    }, []);

    return (
        <RowWithTitle title={`Learn ${sentence && sentence.length} English Sentences`}>
           
                {
                    changeForm ? <button className='EngWords-click-btn' onClick={toggle}>Close</button> : <button className='EngWords-click-btn' onClick={toggle}>Add Sentences</button>
                }
                {
                    changeForm ? 
                    (
                        <AddSentence />
                    ) : (
                        <SearchSentence />
                    )
                } 
          
        </RowWithTitle>
    );
};

export default EngSentences;