import React from 'react';
import './education.scss';
import { EducationData } from '../../../../data/educationData';

const Education = () => {
    return (
        <div className='education-container'>
            {
                EducationData.map((card) => (
                    <div className='education-card' key={card.id}>
                        <div className='education-card-icons'>
                            {card.icons.map((item, index) => (
                                <span className='icon' key={index}>{item.codeIcon}</span>
                            ))}
                        </div>
                        <div className='education-card-info'>
                            <h3 className='title'>{card.title}</h3>
                            <div className='education-date'>{card.date}</div>
                        </div>
                    </div>
                ))
            }  
        </div>
    );
};

export default Education;