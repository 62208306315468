import React, { useState } from 'react';
import './reactUseState.scss';
import RowWithTitle from '../../../../../components/Dashboard/Content/RowWithTitle';

const initialState = {
    name: '',
    school: '',
    city: '',
};

const ReactUseState = () => {
    const [color, setColor] = useState('red');
    // Single Input - A
    const [firstInput, setFirstInput] = useState('');

    // Single Input - B
    const [secondInput, setSecondInput] = useState('');

    const changeSecondInput = (event) => {
        setSecondInput(event.target.value);
    };

    // Multiple Input  - A
    const [person, setPerson] = useState({
        name: '',
        age: '',
    });

    const changeInput = (event) => {
        const value = event.target.value;
        setPerson({
            ...person, [event.target.name] : value
        });
    };
    // Multiple Input  - B with initial state
    const [student, setStudent] = useState(initialState);

    const changeStudentInput = (event) => {
        const value = event.target.value;
        setStudent({
            ...student, [event.target.name] : value
        });
    };

    // input single file
    const [image, setImage] = useState([]);

    const handleImageChange = (event) => {
        if (event.target.files[0]){
          setImage(event.target.files[0]);
        }    
    };
   

     return (
        <>
            <RowWithTitle title='React useState Hook'>
                <div className='reactUseState-container'>
                   <p>The React useState Hook allows us to track state in a function component.</p>
                   <p>State generally refers to data or properties that need to be tracking in an application.</p>
                   <p>To use the useState Hook, we first need to import it into our component.</p>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                        </span>
                   </div>
                   <p>We initialize our state by calling useState in our function component.</p>
                   <p>useState accepts an initial state and returns two values:</p>
                   <ul>
                    <li>The current state.</li>
                    <li>A function that updates the state.</li>
                   </ul>
                   <p>Initialize state at the top of the function component.</p>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br/>
                            <br/>
                            function FavoriteColor() &#123;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [color, setColor] = useState(&#39;&#39;);
                            <br/>
                            <br/>
                            &#125;
                        </span>
                   </div>
                   <p>Notice that again, we are destructuring the returned values from useState.</p>
                   <p>The first value, color, is our current state.</p>
                   <p>The second value, setColor, is the function that is used to update our state.</p>
                   <p>Lastly, we set the initial state to an empty string: useState(&#39;&#39;)</p>
                   <h2>Read State</h2>
                   <p>We can now include our state anywhere in our component.</p>
                   <p>Use the state variable in the rendered component.</p>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br/>
                            <br/>
                            function FavoriteColor() &#123;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [color, setColor] = useState(&#39;red&#39;);
                            <br/>
                            <br/>
                            &nbsp;&nbsp; return (
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; My favorite color is &#123;color&#125;!
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;/&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; )
                            <br/>
                            <br/>
                            &#125;
                            <br/>
                            <br/>
                            export default FavoriteColor;
                        </span>
                   </div>
                   <h2>Update State</h2>
                   <p>To update our state, we use our state updater function.</p>
                   <button type='button' onClick={() => setColor('blue')} style={{ backgroundColor: color }}>Update to blue</button>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br/>
                            <br/>
                            function FavoriteColor() &#123;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [color, setColor] = useState(&#39;red&#39;);
                            <br/>
                            <br/>
                            &nbsp;&nbsp; return (
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; My favorite color is &#123;color&#125;!
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &#60;button type=&#39;button&#39; onClick=&#123;() =&#62; setColor(&#39;blue&#39;)&#125; style=&#123;&#123; backgroundColor: color &#125;&#125; &#62;Update to blue&#60;/button&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;/&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; )
                            <br/>
                            <br/>
                            &#125;
                            <br/>
                            <br/>
                            export default FavoriteColor;
                        </span>
                   </div>
                   <h2>What Can State Hold</h2>
                   <p>The useState Hook can be used to keep track of strings, numbers, booleans, arrays, objects, and any combination of these!</p>
                   <p>We could create multiple state Hooks to track individual values.</p>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br/>
                            <br/>
                            function Car() &#123;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [brand, setBrand] = useState(&#39;Ford&#39;);
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [model, setModel] = useState(&#39;Mustang&#39;);
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [year, setYear] = useState(&#39;1964&#39;);
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [color, setColor] = useState(&#39;red&#39;);
                            <br/>
                            <br/>
                            &nbsp;&nbsp; return (
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; My car is &#123;brand&#125;.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &#60;p&#62;It is a &#123;color&#125; &#123;model&#125; from &#123;year&#125;.&#60;/p&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;/&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; )
                            <br/>
                            <br/>
                            &#125;
                            <br/>
                            <br/>
                            export default Car;
                        </span>
                   </div>
                   <p>Or, we can just use one state and include an object instead!</p>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br/>
                            <br/>
                            function Car() &#123;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [car, setCar] = useState(&#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;brand: &#39;Ford&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;model: &#39;Mustang&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;year: &#39;1964&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;color: &#39;red&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;);
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; My car is &#123;car.brand&#125;.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &#60;p&#62;It is a &#123;car.color&#125; &#123;car.model&#125; from &#123;car.year&#125;.&#60;/p&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;/&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; )
                            <br/>
                            <br/>
                            &#125;
                            <br/>
                            <br/>
                            export default Car;
                        </span>
                   </div>
                   <h2>Updating Objects and Arrays in State</h2>
                   <p>When state is updated, the entire state gets overwritten.</p>
                   <p>What if we only want to update the color of our car?</p>
                   <p>If we only called setCar(&#123;color: &#39;blue&#39;&#125;), this would remove the brand, model, and year from our state.</p>
                   <p>We can use the JavaScript spread operator to help us.</p>
                   <div className='reactUseState-code-box'>
                        <span>
                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br/>
                            <br/>
                            function Car() &#123;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; const [car, setCar] = useState(&#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;brand: &#39;Ford&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;model: &#39;Mustang&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;year: &#39;1964&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;color: &#39;red&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;);
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;const updateColor = () =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;setCar(previousState =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return &#123; ...previousState, color: &#39;blue&#39; &#125;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&#125;);
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; My car is &#123;car.brand&#125;.
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &#60;p&#62;It is a &#123;car.color&#125; &#123;car.model&#125; from &#123;car.year&#125;.&#60;/p&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &#60;button type=&#39;button&#39; onClick=&#123;updateColor&#125;&#62; Blue &#60;/button&#62;
                            
                            <br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&#60;/&#62;
                            <br/>
                            <br/>
                            &nbsp;&nbsp; )
                            <br/>
                            <br/>
                            &#125;
                            <br/>
                            <br/>
                            export default Car;
                        </span>
                   </div>

                </div>
            </RowWithTitle>
            <RowWithTitle title='useState single input A'>
                <div className='reactUseState-container'>
                    <input type='text' name='' placeholder='Enter text' className='' value={firstInput}  onChange={(event) => setFirstInput(event.target.value)}/>
                    <p>Text : {firstInput}</p>
                    <div className='reactUseState-code-box'>
                        <span>

                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br />
                            <br />
                            const ExampleComponent = () =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; const [firstInput, setFirstInput] = useState(&#39;&#39;);
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;&#39; placeholder=&#39;Enter text&#39; className=&#39;&#39; value=&#123;firstInput&#125;  onChange=&#123;(event) =&#62; setFirstInput(event.target.value)&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;p&#62;Text : &#123;firstInput&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; );
                            <br /> 
                            <br /> 
                            &#125;;
                            <br /> 
                            <br /> 
                            export default ExampleComponent;
                            
                        </span>
                    </div>
                </div>
                
            </RowWithTitle>
            <RowWithTitle title='useState single input B'>
                <div className='reactUseState-container'>
                    <input type='text' name='' placeholder='Enter text' className='' value={secondInput}  onChange={changeSecondInput}/>
                    <p>Text : {secondInput}</p>
                    <div className='reactUseState-code-box'>
                        <span>

                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br />
                            <br />
                            const ExampleComponent = () =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; const [secondInput, setSecondInput] = useState(&#39;&#39;);
                            <br />
                            <br />
                            &nbsp;&nbsp; const changeSecondInput = (event) =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setPerson(event.target.value;)
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;;
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;&#39; placeholder=&#39;Enter text&#39; className=&#39;&#39; value=&#123;secondInput&#125;  onChange=&#123;changeSecondInput&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;p&#62;Text : &#123;secondInput&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; );
                            <br /> 
                            <br /> 
                            &#125;;
                            <br /> 
                            <br /> 
                            export default ExampleComponent;
                            
                        </span>
                    </div>
                </div>
                
            </RowWithTitle>
            <RowWithTitle title='useState multiple inputs A'>
                <div className='reactUseState-container'>
                    <input type='text' name='name' placeholder='Enter name' className='' value={person.name}  onChange={changeInput}/>
                    <input type='text' name='age' placeholder='enter text' className='' value={person.age}  onChange={changeInput}/>
                    <p>Name : {person.name}</p>
                    <p>Age : {person.age}</p>
                    <div className='reactUseState-code-box'>
                        <span>

                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br />
                            <br />
                            const ExampleComponent = () =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; const [person, setPerson] = useState(&#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: &#39;&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;age: &#39;&#39;,
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;);
                            <br />
                            <br />
                            &nbsp;&nbsp; const changeInput = (event) =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const value = event.target.value;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setPerson(&#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...person, [event.target.name] : value
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;);
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;;
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;name&#39; placeholder=&#39;Enter name&#39; className=&#39;&#39; value=&#123;person.name&#125;  onChange=&#123;changeInput&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;age&#39; placeholder=&#39;Enter age&#39; className=&#39;&#39; value=&#123;person.age&#125;  onChange=&#123;changeInput&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;p&#62;Name : &#123;person.name&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; &#60;p&#62;Age : &#123;person.age&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; );
                            <br /> 
                            <br /> 
                            &#125;;
                            <br /> 
                            <br /> 
                            export default ExampleComponent;
                            
                        </span>
                    </div>
                </div>
            </RowWithTitle>
            <RowWithTitle title='useState multiple inputs B - initialState'>
                <div className='reactUseState-container'>
                    <input type='text' name='name' placeholder='Enter name' className='' value={student.name}  onChange={changeStudentInput}/>
                    <input type='text' name='school' placeholder='Enter school' className='' value={student.school}  onChange={changeStudentInput}/>
                    <input type='text' name='city' placeholder='Enter city' className='' value={student.city}  onChange={changeStudentInput}/>
                    <p>Name : {student.name}</p>
                    <p>School : {student.school}</p>
                    <p>City : {student.city}</p>
                    <div className='reactUseState-code-box'>
                        <span>

                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br />
                            <br />
                            const initialState = &#123;
                            <br />
                            <br />
                                &nbsp;&nbsp;name: &#39;&#39;,
                            <br />
                            <br />
                                &nbsp;&nbsp;school: &#39;&#39;,
                            <br />
                            <br />
                                &nbsp;&nbsp;city: &#39;&#39;,
                            <br />
                            <br />
                            &#125;;
                            <br />
                            <br />
                            const ExampleComponent = () =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; const [student, setStudent] = useState(initialState);
                            <br />
                            <br />
                            &nbsp;&nbsp; const changeStudentInput = (event) =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const value = event.target.value;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;setStudent(&#123;
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...student, [event.target.name] : value
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;);
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&#125;;
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;name&#39; placeholder=&#39;Enter name&#39; className=&#39;&#39; value=&#123;student.name&#125;  onChange=&#123;changeStudentInput&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;school&#39; placeholder=&#39;Enter age&#39; className=&#39;&#39; value=&#123;student.school&#125;  onChange=&#123;changeStudentInput&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;text&#39; name=&#39;city&#39; placeholder=&#39;Enter city&#39; className=&#39;&#39; value=&#123;student.city&#125;  onChange=&#123;changeStudentInput&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;p&#62;Name : &#123;person.name&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; &#60;p&#62;School : &#123;person.school&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; &#60;p&#62;City : &#123;person.city&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; );
                            <br /> 
                            <br /> 
                            &#125;;
                            <br /> 
                            <br /> 
                            export default ExampleComponent;
                            
                        </span>
                    </div>
                </div>
            </RowWithTitle>
            <RowWithTitle title='useState input single file'>
                <div className='reactUseState-container'>
                   
                    <input type='file' onChange={handleImageChange}/>
                    <p>Image : {image.name}</p>
                    <div className='reactUseState-code-box'>
                        <span>

                            import React, &#123; useState &#125; from &#39;react&#39;;
                            <br />
                            <br />
                            const ExampleComponent = () =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; const [image, setImage] = useState([]);
                            <br />
                            <br />
                            &nbsp;&nbsp; const handleImageChange = (event) =&#62; &#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; &nbsp;&nbsp; if (event.target.files[0])&#123;
                            <br />
                            <br />
                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; setImage(event.target.files[0]);
                            <br />
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&#125;   
                            <br />
                            <br /> 
                            &nbsp;&nbsp;&#125;;
                            <br />
                            <br />
                            &nbsp;&nbsp; return (
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;input type=&#39;file&#39; onChange=&#123;handleImageChange&#125; &#47;&#62;
                            <br />
                            <br />
                            &nbsp;&nbsp; &#60;p&#62;Image : &#123;image.name&#125;&#60;/p&#62;
                            <br /> 
                            <br /> 
                            &nbsp;&nbsp; );
                            <br /> 
                            <br /> 
                            &#125;;
                            <br /> 
                            <br /> 
                            export default ExampleComponent;
                            
                        </span>
                    </div>
                </div>
                
            </RowWithTitle>
        </>
  );
};

export default ReactUseState;