import React from "react";
import Accordion from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/Accordion/Accordion";
import Accordion2 from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/Accordion2/Accordion2";
import BooksList from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList";
import AddNewPost from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList/AddNewPost";
import SecondList from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList/SecondList";
import UsersArray from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList/UsersArray";
import ChangeButtonColor from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/ButtonColor";
import CCTAccordion from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/CCTAccordion/Accordion";
import CounterComp from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/Counter";
import CryptoConverter from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/CryptoConverter";
import CurrencyConverter from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/CurrencyConverter";
import FirestoreArrayEx from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/FirestoreArray";
import QrCode from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/QrCode";
import QuizNavBar from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/QuizNavBar";
import ReactPagination from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/ReactPagination";
import ReactTalker from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/Talker";
import UdemyTutorials from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/UdemyTutorials";
import AutomagnatasTable from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/AutomagnatasTable";
import JustTestA from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/JustTestA";
import StudentsList from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BooksList/StudentsList";
import ImagesList from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/ImagesList";
import SelectExamples from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/SelectExamples";
import NavbarArray from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/NavbarArray";
import Checkboxes from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/Checkboxes";
import Greetings from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/Greetings";
import FormExapmles from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/FormExamples";
import RtdbCrud from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/RtdbCrud";
import ImagesExamples from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/ImagesExamples";
import CompressImage from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/CompressImage";
import AccordionFAQ from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/AccordionFAQ";
import QuotesGenerator from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/QuotesGenerator.js";
import ShoppingList from '../pages/authorized/AdminDashboard/LearnReact/ReactExamples/ShoppingList';
import GitHubUserSearch from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/GitHubUserSearch";
import VideoPlayer from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/VideoPlayer";
import BMIcalculator from "../pages/authorized/AdminDashboard/LearnReact/ReactExamples/BMIcalculator";


export const ReactExamplesData = [
    {
        id: 1,
        title: 'Accordion with styled-components (ex: Accordion)',
        description: 'fetch data, styled-components,current button index',
        content: [
            {
                example: <Accordion />
            },
        ]
    },
    {
        id: 2,
        title: 'Accordion with props (ex: Accordion2)',
        description: 'active button, icon rotate, count div height, check console.log, transition, div ref= , dangerouslySetInnerHTML=, content.current.scrollHeight ',
        content: [
            {
                example: 
                    <Accordion2 
                        title='What is your return policy?' 
                        content='kazkoks tekstas labai ilgas ,nors gali buti ir dr ilgesnis'
                    />
            },
            {
                example: 
                    <Accordion2 
                        title='What is your return policy?' 
                        content='kazkoks tekstas labai ilgas ,nors gali buti ir dr ilgesnis. kazkoks tekstas labai ilgas ,nors gali buti ir dr ilgesnis'
                    />
            },
            {
                example: 
                    <Accordion2 
                        title='What is your return policy?' 
                        content='
                        <p>kazkoks tekstas labai ilgas, </p>
                        <p>nors gali buti ir dr ilgesnis. kazkoks tekstas labai ilgas ,</p>
                        <p>nors gali buti ir dr ilgesnis,/p>
                        '
                    />
            },
        ]
    },
    {
        id: 3,
        title: 'Accordion with styled-components (ex: CCTAccordion)',
        description: 'old version, fetch data , styled-components, CCT Frontend Internship Program',
        content: [
            {
                example: <CCTAccordion />
            },
        ]
    },
    {
        id: 4,
        title: 'QR code (ex: QrCode)',
        description: 'npm i qrcode, https://www.youtube.com/watch?v=njeaCPYuDIg',
        content: [
            {
                example: <QrCode />
            },
        ]
    },
    {
        id: 5,
        title: 'Api. JSON. Display books list (ex: BooksList, AddNewPost, SecondList)',
        description: 'Axios get, axios post, Api, Json, list, async, old comonent, map, https://www.better.dev/fetch-data-from-an-api-with-react, Game of Thrones Books',
        content: [
            {
                example: <BooksList />
            },
            {
                example: <AddNewPost />
            },
            {
                example: <SecondList />
            },
            {
                example: <UsersArray />
            },
            {
                example: <StudentsList />
            },
        ]
    },
    {
        id: 6,
        title: 'Crypto List .Api (ex: CryptoConverter)',
        description: 'Crypto , search, fetch data from api, filter data',
        content: [
            {
                example: <CryptoConverter />
            },
        ]
    },
    {
        id: 7,
        title: 'CurrencyConverter (ex: CurrencyConverter)',
        description: 'xxx',
        content: [
            {
                example: <CurrencyConverter />
            },
        ]
    },
    {
        id: 8,
        title: 'Pagination (ex: ReactPagination)',
        description: 'npm install react-paginate, import react-paginate',
        content: [
            {
                example: <ReactPagination />
            },
        ]
    },
    {
        id: 9,
        title: 'ChangeButtonColor (ex: ChangeButtonColor)',
        description: 'change color, react class, old component',
        content: [
            {
                example: <ChangeButtonColor />
            },
        ]
    },
    {
        id: 10,
        title: 'ReactTalker (ex: ReactTalker)',
        description: 'onclick alert, old component',
        content: [
            {
                example: <ReactTalker />
            },
        ]
    },
    {
        id: 11,
        title: 'FirestoreArrayEx (ex: FirestoreArrayEx)',
        description: 'Firestore , Array , CRUD ',
        content: [
            {
                example: <FirestoreArrayEx />
            },
        ]
    },
    {
        id: 12,
        title: 'CounterComp (ex: CounterComp)',
        description: 'counter clicks',
        content: [
            {
                example: <CounterComp />
            },
        ]
    },
    {
        id: 13,
        title: 'QuizNavBar (ex: QuizNavBar)',
        description: 'QuizNavBar',
        content: [
            {
                example: <QuizNavBar />
            },
        ]
    },
    {
        id: 14,
        title: 'UdemyTutorials (ex: UdemyTutorials)',
        description: 'divStyle, ',
        content: [
            {
                example: <UdemyTutorials />
            },
        ]
    },
    {
        id: 15,
        title: 'AutomagnatasTable (ex: AutomagnatasTable)',
        description: 'AutomagnatasTable',
        content: [
            {
                example: <AutomagnatasTable />
            },
        ]
    },
    {
        id: 16,
        title: 'JustTestA (ex: JustTestA)',
        description: 'change on click, reset on click',
        content: [
            {
                example: <JustTestA />
            },
        ]
    },
    {
        id: 17,
        title: 'Images List',
        description: 'props, images, images from object',
        content: [
            {
                example: <ImagesList />
            },
        ]
    },
    {
        id: 18,
        title: 'Select Examples',
        description: 'select buttons, dropdown',
        content: [
            {
                example: <SelectExamples />
            },
        ]
    },
    {
        id: 19,
        title: 'Navbar array',
        description: 'array, loop',
        content: [
            {
                example: <NavbarArray />
            },
        ]
    },
    {
        id: 20,
        title: 'Checkboxes',
        description: 'checkbox',
        content: [
            {
                example: <Checkboxes />
            },
        ]
    },
    {
        id: 21,
        title: 'Greetings',
        description: 'Greetings',
        content: [
            {
                example: <Greetings />
            },
        ]
    },
    {
        id: 22,
        title: 'Form examples',
        description: 'input, submit',
        content: [
            {
                example: <FormExapmles />
            },
        ]
    },
    {
        id: 23,
        title: 'RtdbCrud',
        description: 'firebase database',
        content: [
            {
                example: <RtdbCrud />
            },
        ]
    },
    {
        id: 24,
        title: 'Images',
        description: 'firestore, Add foto and update foto, Image Slider',
        content: [
            {
                example: <ImagesExamples />
            },
        ]
    },
    {
        id: 25,
        title: 'Compress and Download Image',
        description: 'youtube: Fullstack Techies, npm i browser-image-compression, npm i semantic-ui-css, https://www.npmjs.com/package/browser-image-compression, https://react.semantic-ui.com/usage',
        content: [
            {
                example: <CompressImage />
            },
        ]
    },
    {
        id: 26,
        title: 'Accordion FAQ',
        description: 'https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/',
        content: [
            {
                example: <AccordionFAQ />
            },
        ]
    },
    {
        id: 27,
        title: 'Quotes Generator',
        description: 'https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/',
        content: [
            {
                example: <QuotesGenerator />
            },
        ]
    },
    {
        id: 28,
        title: 'Shopping List',
        description: 'https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/',
        content: [
            {
                example: <ShoppingList />
            },
        ]
    },
    {
        id: 29,
        title: 'GitHub User Search',
        description: 'https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/',
        content: [
            {
                example: <GitHubUserSearch />
            },
        ]
    },
    {
        id: 30,
        title: 'Video Player',
        description: 'https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/',
        content: [
            {
                example: <VideoPlayer />
            },
        ]
    },
    {
        id: 31,
        title: 'BMI calculator (The Body Mass Index)',
        description: 'useMemo() , https://www.freecodecamp.org/news/react-projects-for-beginners-easy-ideas-with-code/',
        content: [
            {
                example: <BMIcalculator />
            },
        ]
    },
];