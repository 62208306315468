import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '../../../../context/UserAuthContext';
import { protectedUser } from '../../../../server/adminData';
import './usefulLinks.scss';
import Dashboard from '../../../../components/Dashboard';
import Content from '../../../../components/Dashboard/Content';
import AddLink from './AddLink';
import LinksCollection from './LinksCollection';
import EditLinks from './EditLinks';

const UsefulLinks = () => {
    const { user, loading, logoutUser } = useUserContext();
    const [click, setClick] = useState(false);

    if (user.email !== protectedUser) { 
        return <Navigate to='/users' />;
    }

    const toggle = () => {
        setClick(!click);
    };

    return (
        <>
            {
                loading ?  
                    (<p>Loading...</p>) : 
                        (
                            <Dashboard 
                                user={user}
                                logoutUser={logoutUser}
                            >
                                <Content path='useful-links' />

                                <button type='button' className='usefulLinks-btn' onClick={toggle}>{click ? 'Links list' : 'Edit'}</button>
                                {
                                    click 
                                        ? <EditLinks /> 
                                            : (
                                                <>
                                                    <AddLink />
                                                    <LinksCollection />
                                                </>
                                            )
                                }
                            </Dashboard>
                        )
            }
        </>
    );
};

export default UsefulLinks;