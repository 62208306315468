import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSFunctionsDefoultParameters() {
    return (
        <RowWithTitle title='JS Default Parameters'>
            <div className='js-coursesContainer'>
            <div>
                <h3>Default Parameters</h3>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> One of the features added in ES6 is the ability to use default parameters. Default parameters allow parameters to have a predetermined value in case there is no argument passed into the function or if the argument is undefined when called.</li>
                    
                </ul>
                <pre  className='courses-code-container'>
                    function greeting (name = &#39;stranger&#39;) &#123;
                         <br />
                    console.log(`Hello, $&#123;name&#125;!`)
                    <br />
                    &#125;
                     <br />
                    
                    greeting(&#39;Nick&#39;) // Output: Hello, Nick!
                    <br />
                    greeting() // Output: Hello, stranger!
                </pre>
                <ul>
                    <li><MdIcons.MdOutlineTaskAlt/> In the example above, we used the = operator to assign the parameter name a default value of &#39;stranger&#39;. This is useful to have in case we ever want to include a non-personalized default greeting!</li>
                    <li><MdIcons.MdOutlineTaskAlt/> When the code calls greeting(&#39;Nick&#39;) the value of the argument is passed in and, &#39;Nick&#39;, will override the default parameter of &#39;stranger&#39; to log &#39;Hello, Nick!&#39; to the console.</li>
                    <li><MdIcons.MdOutlineTaskAlt/> When there isn&#39;t an argument passed into greeting(), the default value of &#39;stranger&#39; is used, and &#39;Hello, stranger!&#39; is logged to the console.</li>
                    
                </ul>
                
                <span>By using a default parameter, we account for situations when an argument isn&#39;t passed into a function that is expecting an argument.</span>
              
            </div>
            </div>
        </RowWithTitle>
    );
}

export default JSFunctionsDefoultParameters;