export const EnglishLessonIrregularVerbs = [
    {
        id: 1,
        infinitiveWord: 'be',
        infinitivePronunciation: 'bi:',
        pastSimpleWord: 'was, were',
        pastSimplePronunciation: 'waz, we:(r)',
        pastParticipleWord: 'been',
        pastParticiplePronunciation: 'bi:n'
    },
    {
        id: 2,
        infinitiveWord: 'become',
        infinitivePronunciation: 'bi`kam',
        pastSimpleWord: 'became',
        pastSimplePronunciation: 'be`keim',
        pastParticipleWord: 'become',
        pastParticiplePronunciation: 'bi`kam'
    },
    {
        id: 3,
        infinitiveWord: 'begin',
        infinitivePronunciation: 'bi`gin',
        pastSimpleWord: 'began',
        pastSimplePronunciation: 'be`gaen',
        pastParticipleWord: 'begun',
        pastParticiplePronunciation: 'bi`gan'
    },
    {
        id: 4,
        infinitiveWord: 'break',
        infinitivePronunciation: 'breik',
        pastSimpleWord: 'broke',
        pastSimplePronunciation: 'brauk',
        pastParticipleWord: 'broken',
        pastParticiplePronunciation: 'brauken'
    },
    {
        id: 5,
        infinitiveWord: 'build',
        infinitivePronunciation: 'bild',
        pastSimpleWord: 'built',
        pastSimplePronunciation: 'bilt',
        pastParticipleWord: 'built',
        pastParticiplePronunciation: 'bilt'
    },
    {
        id: 6,
        infinitiveWord: 'buy',
        infinitivePronunciation: 'bai',
        pastSimpleWord: 'bought',
        pastSimplePronunciation: 'bou:t',
        pastParticipleWord: 'bought',
        pastParticiplePronunciation: 'bou:t'
    },
    {
        id: 7,
        infinitiveWord: 'can',
        infinitivePronunciation: 'kaen',
        pastSimpleWord: 'could',
        pastSimplePronunciation: 'kud',
        pastParticipleWord: 'could',
        pastParticiplePronunciation: 'kud'
    },
];