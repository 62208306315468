import React, { useEffect, useState } from 'react';
import './reactNavigation.scss';
import { ReactNavigationData } from '../../../../../data/reactNavigationData';

const ReactNavigation = () => {
    const [data ,setData] = useState([]);
    const [showTutorials , setShowTutorials] = useState(0);

    const toggle = (id) => {
        setShowTutorials(id);
    };

    useEffect(() => {
        setData(ReactNavigationData);
    }, []);


    return (
        <div className='react-navigation-container'>
            <div className='react-navigation-cards-box'>
                {
                    ReactNavigationData.map((card) => (
                        <div 
                            key={card.id} 
                            className='react-navigation-card'
                            onClick={() => toggle(card.id)}
                        >
                            <h3 className='react-navigation-card-h3'>{card.title}</h3>
                            <span className='react-navigation-card-span'>{card.description}</span>
                        </div>
                        
                    ))
                }
            </div>
            
            {
                showTutorials ? (data[showTutorials - 1].showComponent)  : <p className='react-navigation-p'>Please select tutorial...</p>
            }
           
        </div>
    );
};

export default ReactNavigation;