import React from 'react';
import * as MdIcons from 'react-icons/md';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

function JSLesson1() {
    const jsLessonA = Math.random();
    
    console.log(Math.random());
    return (
        <RowWithTitle title='JS Review'>
            <div className='js-coursesContainer'>
            <ul>
                <li><MdIcons.MdOutlineTaskAlt/> Data is printed, or logged, to the console, a panel that displays messages, with console.log().</li>
                <li><MdIcons.MdOutlineTaskAlt/> We can write single-line comments with // and multi-line comments between /* and */.</li>
                <li><MdIcons.MdOutlineTaskAlt/> There are 7 fundamental data types in JavaScript: strings, numbers, booleans, null, undefined, symbol, and object.</li>
                <li><MdIcons.MdOutlineTaskAlt/> Numbers are any number without quotes: 23.8879</li>
                <li><MdIcons.MdOutlineTaskAlt/> Strings are characters wrapped in single or double quotes: Sample String </li>
                <li><MdIcons.MdOutlineTaskAlt/> Objects, including instances of data types, can have properties, stored information. The properties are denoted with a . after the name of the object, for example: Hello.length.</li>
                <li><MdIcons.MdOutlineTaskAlt/> Objects, including instances of data types, can have methods which perform actions. Methods are called by appending the object or instance with a period, the method name, and parentheses. For example: hello.toUpperCase().</li>
                <li><MdIcons.MdOutlineTaskAlt/> We can access properties and methods by using the ., dot operator.</li>
                <li><MdIcons.MdOutlineTaskAlt/> Built-in objects, including Math, are collections of methods and properties that JavaScript provides.</li>
            </ul>
            <pre  className='courses-code-container'>
            console.log(Math.random());
            </pre>
            <div className='courses-show-task'>
                <MdIcons.MdOutlineQuestionAnswer/> : {jsLessonA}
            </div>
            </div>
        </RowWithTitle>
    );
}

export default JSLesson1;