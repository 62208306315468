import React from 'react';
import RowWithTitle from '../../../../../../components/Dashboard/Content/RowWithTitle';

const EngLinks = () => {
    return (
        <RowWithTitle title='Learn English Links'>
            <p style={{ margin: '0', padding: '0' }}>
                <a href='https://www.youtube.com/channel/UCXOqyy-d79z4fkXEVYyqKEQ/videos' target='blank' style={{ color: 'white', textDecoration: 'none' }}>Anglų kalbos kursai Edukus</a>
            </p>
            <br />
            <p style={{ margin: '0', padding: '0' }}>
                <a href='https://www.learn-english-online.org/' target='blank' style={{ color: 'white', textDecoration: 'none' }}>learn-english-online.org</a>
            </p>
            
        </RowWithTitle>
    );
};

export default EngLinks;