import React from 'react';
import Frame from '../../../components/Frame';
import './home.scss';
import { BsMouse } from 'react-icons/bs';
import Section from '../../../components/Frame/Section';
import Contact from './Contact';
import GithubPortfolio from './GithubPorfolio';
import Education from './Education';

const Home = () => {
    return (
        <Frame>
            <a href='#footer' className='scroll-down'>
                <hr />
                <h5>scroll down</h5>
                <BsMouse className='scroll' />
                <hr />
            </a>
            <Section sectionId='gitportfolio' title='Github portfolio' >
                <GithubPortfolio />
            </Section>
            <Section sectionId='education' title='Education' >
                <Education />
            </Section>
            <Section sectionId='contact' title='Contact' >
                <Contact />
            </Section>
        </Frame>
    );
};

export default Home;