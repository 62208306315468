import React from 'react';
import RowWithTitle from '../../../../../../../../components/Dashboard/Content/RowWithTitle';

const JSArrayFilter = () => {
    return (
        <RowWithTitle title='JS Array .filter()'>
            <div className='js-coursesContainer'>
                <span>
                    The filter() method creates a new array filled with elements that pass a test provided by a function.
                    <br />
                    The filter() method does not execute the function for empty elements.
                    <br />
                    The filter() method does not change the original array.
                    <br />
                </span>
                <pre  className='courses-code-container'>
                    <p>
                    &#60;input type=&#62;number&#62; id=&#62;ageToCheck&#62; value=&#62;30&#62;
                    <br />
                     &#60;button onclick=&#39;myFunction()&#39;&#62;Test &#60;/button&#62;
                    </p>
                   
                  
                    const ages = [32, 33, 12, 40];
                    <br />

                    function checkAge(age) &#123;
                    <br />
                    return age &#62; document.getElementById(&#39;ageToCheck&#39;).value;
                    <br />
                    &#125;
                    <br />
                    <br />

                    function myFunction() &#123;
                    <br />
                    document.getElementById(&#39;demo&#39;).innerHTML = ages.filter(checkAge);
                    <br />
                    &#125;
                     <br />
                    Result: 32,33,40
                </pre>
             </div>

        </RowWithTitle>
    );
};

export default JSArrayFilter;