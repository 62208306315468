import React, { useEffect, useState } from 'react';
import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { FireDB } from '../../../../../../server/firebase-config';

const AddSentence = () => {
    const [sentences, setSentences] = useState([]);
    const [sentenceId, setSentenceId] = useState(0);
    const [sentencesLength, setSentencesLength] = useState(0);
    const [engSentence, setEngSentence] = useState('');
    const [ltSentence, setLtSentence] = useState('');
    const [urlAddress, setUrlAddress] = useState('');

    const sentencesCollectionRef = collection(FireDB, 'gruminEnglishSentences');

    useEffect(() => {
        const fetchSentencesData = query(sentencesCollectionRef, orderBy('date', 'asc'));

        const showSentencesArray = onSnapshot(fetchSentencesData, (snapshot) => {
            setSentences(snapshot.docs.map((doc) => ({ ... doc.data(), id: doc.id})));
        });
        return showSentencesArray;
    }, []);

    useEffect(() => {
        if (sentences.length > 0){
            setSentencesLength(sentences.length);
            const lastSentence = sentences.pop();
            setSentenceId(parseInt(lastSentence.sentenceId) + 1);
        } else if(sentences.length === 0){
            const firstSentence = 1;
            setSentenceId(firstSentence);
        } else {
            setSentenceId(0);
        }
    },[sentences]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const newSentence = { sentenceId, engSentence, ltSentence, urlAddress, date: serverTimestamp()};
        await addDoc(sentencesCollectionRef, newSentence);
        setEngSentence('');
        setLtSentence('');
        setUrlAddress('');
    };

    return (
        <form className='addWord-form-container' onSubmit={handleSubmit}>
            <h3>Total Sentences : {sentencesLength}</h3>
            <input 
                type='text' 
                className='addWord-input' 
                name='sentenceId'
                value={sentenceId}
                onChange={(event) => setSentenceId(event.target.value)}
                hidden
            />
            <input 
                type='text' 
                className='addWord-input' 
                placeholder='ENG sentence' 
                name='engSentence'
                value={engSentence}
                onChange={(event) => setEngSentence(event.target.value)}
            />
            <input 
                type='text' 
                className='addWord-input' 
                placeholder='LT sentence' 
                name='ltSentence'
                value={ltSentence}
                onChange={(event) => setLtSentence(event.target.value)}
            />
            <input 
                type='text' 
                className='addWord-input' 
                placeholder='Url address' 
                name='urlAddress'
                value={urlAddress}
                onChange={(event) => setUrlAddress(event.target.value)}
            />
            <button type='submit' className='addWord-button'>Add sentence</button>
        </form>
    );
};

export default AddSentence;