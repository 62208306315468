import Img from '../images/img5-x.jpg';
import ImgNgoo from '../images/ngoo-web.jpg';

export const ImagesUrl = {
        monkey: {
            url: 'https://content.codecademy.com/courses/React/react_photo-monkeyselfie.jpg',
            title: 'monkey x',
            style: 'monkey-img'
        },
        dev: {
            url: Img,
            title: 'dev xx',
            style: 'monkey-img'
        },
        ngoo: {
            url: ImgNgoo,
            title: 'ngoo web',
            style: 'monkey-img'
        },

    };

    
