import React, { useState } from 'react';
import styled from 'styled-components';
import './listC.scss';
import { Data } from './ListCData';

const BottomGreenLine = styled.div`
    height: 100px;
`;

const ListC = () => {
    const [clicked, setClicked] = useState(false);
  
    const toggle = index => {
        if(clicked === index) {
            return(
                setClicked(null)
            );
        }
        setClicked(index);
    };

    return (
        <div className='list-container'>
            <ul className='Container'>
                {Data.map((item, index) => {
                    return (
                        <li className='WrapContainer' key={index}>
                            <div className='WrapDiv'>
                                <BottomGreenLine />
                                <div className='Wrap'
                                    onClick={() => toggle(index)} 
                                    key={index}
                                >
                                    <span>{item.number}</span>
                                    <h1>{item.title}</h1>
                                </div>
                            </div>
                                {clicked === index ? (
                                    <div className='Dropdown'>
                                        {item.content.map((ans) => (
                                            <div key={ans.toString()}>
                                                <p className='DropdownLine'>
                                                    {ans.text} <input type='checkbox' /> <a href={`mailto:${ans.email}`}>{ans.email}</a>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null }
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ListC;