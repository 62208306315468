import styled from 'styled-components';

export const SectionContainer = styled.div`
  margin:20px auto;
  max-width:1000px;
  height: 100%;
  padding:20px;
  background: #ccc;
  color:#fff;
  border-radius: 5px;
  text-align: center;
`;
export const LogoImg = styled.img`
    height: 50px;
    width: 50px;
    background: #ff0000;
    animation: App-logo-spin infinite 20s linear;
   
    
    @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
`;

export const SectionTitle = styled.h2`
    margin:0 0 10px;
    color: #000; 
    text-align:left;
    border-bottom: 1px solid #c1c1c1;
`;

export const ContentPre = styled.pre`
  margin:0 auto;
  background: #c1c1c1;
  max-width: 600px;
  padding: 20px;
  text-align:left;
  color: #000;
  font-size:16px;
  border:1px solid  #414349;
  border-radius: 5px;
`;

export const Content = styled.div`
  padding:10px;
  color:#000;
  width:100%;

`;