import React, { useState } from 'react';

function SubmitCheckbox() {
    const [name, setName] = useState('');
    const [group, setGroup] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [user, setUser] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('my test : ' + name + ' ' +  group + ' ' + isChecked);
        setUser('my test : ' + name + ' ' +  group + ' ' + isChecked);
    };

    return (
        <div style={{ backgroundColor: 'gray' }}>
            <form onSubmit={handleSubmit}>
                <p>
                    <input type='text' placeholder='Enter name' onChange={(event) => setName(event.target.value)} />
                    <select onChange={(event) => setGroup(event.target.value)}>
                        <option>Grupės</option>
                        <option>Vadybininkas</option>
                        <option>Sandėlininkas</option>
                        <option>Šaltkavis</option>
                        <option>Pardavėjas</option>
                    </select>
                    <input type='checkbox'  onChange={(event) => setIsChecked(event.target.checked)}/>
                    <button type='submit'>Submit</button>
                </p>
            </form>
            {user}
            <hr />
            <h5>youtube : Code Step by Step</h5>
            <h5>React tutorialfor beginners #17 Handle form | chechbox | inout field | select</h5>
        </div>
        
    );
}

export default SubmitCheckbox;
